<template>
    <div v-if="value">
        <eod-stockmovement-quantity label="Aantal" :readonly="readonly" :value="value.quantity" @input="updateQuantity"></eod-stockmovement-quantity>
        <div class="mt-4" v-if="value && value.quantity > 0">
            <eod-objects-treeview-input label="Geproduceerd door" return-object :value="value.products" @input="updateProducts" multiple :classes="['COMPANY','PROJECT','DEVICE','DEVICE_FOLDER']" :selectable="['DEVICE']"></eod-objects-treeview-input>
        </div>
    </div>
</template>
<script>
import eodObjectsTreeviewInput from '../eod-objects-treeview-input.vue';
import eodStockmovementQuantity from '../eod-stockmovement-quantity.vue';
export default {
    name: 'eod-article-stock-movement',
    components:{
        eodObjectsTreeviewInput,
        eodStockmovementQuantity
    },
    props:{
        cell: Object,
        value: Object,
        readonly: Boolean
    },
    methods:{
        updateQuantity(quantity){
            const val = this.value
            val.quantity = quantity;
            this.updateVal(val);
        },
        updateProducts(products){
            const val = this.value;
            val.products = products;

            this.updateVal(val);
        },
        updateVal(val){
            this.$emit('input', val);

            this.$nextTick(() => {
                this.$forceUpdate();
            });
        }
    }
}
</script>