<template>
    <div>
        <v-row
            v-if="(value.content.variables && value.content.variables.length > 0) || (value.content.variants && value.content.variants.length > 0)"
            class="mb-4">
            <v-col>
                <v-card v-if="value.content.variables && value.content.variables.length > 0" class="mb-4">
                    <v-subheader>Variabelen</v-subheader>
                    <v-list dense>
                        <template v-for="(variable, index) in value.content.variables">
                            <v-divider v-if="index > 0" :key="'divider_' + variable.value"></v-divider>
                            <v-list-item two-line :key="variable.value" @click="$emit('editVariable', variable)">
                                <v-list-item-content>
                                    <v-list-item-title>{{ value.content.variables[index].text }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="value.content.variables[index].product">{{
                                        value.content.variables[index].product.name
                                    }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>Kiezen bij start formulier</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon><v-icon>mdi-pencil</v-icon></v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>
            </v-col>
            <v-col>
                <v-card v-if="value.content.variants && value.content.variants.length > 0" class="mb-4">
                    <v-subheader>Varianten</v-subheader>
                    <v-list dense>
                        <template v-for="(variant, index) in value.content.variants">
                            <v-divider v-if="index > 0" :key="'divider_' + variant.value"></v-divider>
                            <v-list-item two-line :key="variant.value" @click="$emit('editVariant', variant)">
                                <v-list-item-content>
                                    <v-list-item-title>{{ value.content.variants[index].text }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="value.content.variants[index].frequency">{{
                                        settings.resolveFrequency(value.content.variants[index].frequency).text
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon><v-icon>mdi-pencil</v-icon></v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-bind="cols[0]">
                <v-card v-if="value" id="editor">
                    <v-card-title>
                        <v-tabs :key="tabsrefresh" v-model="activePage"
                            :color="(getActivePage() != null && value.content.pages[getActivePage()].statusCode) ? value.content.pages[getActivePage()].statusCode.color : 'primary'">
                            <v-tabs-slider
                                :color="(getActivePage() != null && value.content.pages[getActivePage()].statusCode) ? value.content.pages[getActivePage()].statusCode.color : 'primary'"></v-tabs-slider>
                            <draggable v-model="value.content.pages" group="pages"
                                class="v-slide-group__content v-tabs-bar__content" @update="tabUpdate">
                                <v-tab @click="showPageSettings" v-for="(page, index) in value.content.pages"
                                    :key="index">
                                    <template v-if="page.statusCode">
                                        <v-icon small left>{{ page.statusCode.icon }}</v-icon>
                                    </template>
                                    {{ page.name }}
                                </v-tab>
                            </draggable>
                            <v-btn text class="align-self-center mr-4" @click="addPage">
                                <v-icon left>mdi-plus</v-icon>
                                {{ stepName }}
                            </v-btn>
                            <template v-if="showImport || showExport">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="align-self-center" icon v-bind="attrs" v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-if="showExport" @click="$emit('export')">
                                            <v-list-item-icon><v-icon>mdi-export</v-icon></v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Exporteren</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="showImport" @click="$emit('import')">
                                            <v-list-item-icon><v-icon>mdi-import</v-icon></v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Importeren</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                            </template>
                        </v-tabs>
                    </v-card-title>
                    <v-card-text>
                        <v-tabs-items v-model="activePage">
                            <v-tab-item v-for="(page, index) in value.content.pages" :key="index"
                                transition="fade-transition" reverse-transition="fade-transition">
                                <v-list>
                                    <draggable :ref="'component_page_' + index + '_items'"
                                        v-model="value.content.pages[index].cells" group="form"
                                        style="min-height:100px;" @add="evt => componentAdded(evt, index)">
                                        <component :ref="'component_' + cell.id"
                                            v-for="(cell, cellIndex) in value.content.pages[index].cells" :key="cell.id"
                                            v-model="value.content.pages[index].cells[cellIndex]" :page="page"
                                            :is="getComponentByType(value.content.pages[index].cells[cellIndex].typeId).viewComponent"
                                            @delete="deleteItem(cellIndex)" :template="value" v-bind="componentProps"
                                            v-on="componentEvents" @showSettings="showSettings(cellIndex)"></component>
                                    </draggable>
                                </v-list>
                            </v-tab-item>
                        </v-tabs-items>
                        <eod-dialog v-if="isComponentDialogVisible" v-model="isComponentDialogVisible" @input="dialogVisibilityChanged" title="Toestel type toevoegen" icon="mdi-cube-outline" iconColor="primary" okText="Toevoegen" @okAction="addMeasurementTypes">
                            <div class="pt-4">
                            <component v-model="value.content.pages[getActivePage()].cells[getActiveCell()]" :is="getComponentByType(value.content.pages[getActivePage()].cells[getActiveCell()].typeId).configurationComponent"></component>
                        </div>
                        </eod-dialog>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col v-bind="cols[1]">
                <v-card ref="settingsPanel" scrollable>
                    <v-card-title>
                        <v-tabs v-model="tab">
                            <v-tab key="components">Componenten</v-tab>
                            <v-tab v-if="getActivePage() != null" key="settings">{{ stepName }}</v-tab>
                            <v-tab key="rules">Condities</v-tab>
                        </v-tabs>
                    </v-card-title>
                    <v-card-text style="max-height:calc(100vh - 260px);overflow-y:auto;">

                        <v-tabs-items v-model="tab">
                            <v-tab-item key="components" transition="fade-transition"
                                reverse-transition="fade-transition">
                                <draggable :value="getVisibleComponents()"
                                    :group="{ name: 'form', pull: 'clone', put: false }" :sort="false" :clone="clone">
                                    <v-card class="mb-2" v-for="component in getVisibleComponents()" :key="component.id"
                                        flat outlined>
                                        <v-card-subtitle v-html="component.name"></v-card-subtitle>
                                    </v-card>
                                </draggable>
                            </v-tab-item>
                            <v-tab-item v-if="getActivePage() != null" key="settings" transition="fade-transition"
                                reverse-transition="fade-transition">
                                <template v-if="value">
                                    <component v-on="stepEvents" :is="stepComponent"
                                        v-model="value.content.pages[activePage]"></component>
                                    <v-btn v-if="value.content.pages[activePage].removeable !== false" rounded
                                        @click="deleteStep(activePage)" color="error"><v-icon
                                            left>mdi-trash-can-outline</v-icon>Verwijderen</v-btn>
                                </template>
                            </v-tab-item>
                            <v-tab-item key="rules" transition="fade-transition" reverse-transition="fade-transition">
                                <component :is="rulesComponent" v-model="value" :stepName="stepName"
                                    :measurementTypes="getMeasurementItems()" :activePage="activePage"></component>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import draggable from 'vuedraggable';
import { v4 as uuidv4 } from 'uuid';
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodDialog from './eod-dialog.vue';

export default {
    name: 'eod-form-editor',
    components: {
        draggable,
        eodObjectAutocomplete,
        eodDialog
    },
    props: {
        value: Object,
        components: Array,
        stepComponent: {
            type: Function,
            default: () => import('./forms/configuration/step')
        },
        rulesComponent: {
            type: Function,
            default: () => import('./forms/configuration/rules')
        },
        addStep: Function,
        stepEvents: {
            type: Object
        },
        componentEvents: {
            type: Object
        },
        componentProps: {
            type: Object
        },
        stepName: {
            type: String,
            default: 'Stap'
        },
        cols: {
            type: Array,
            default: () => {
                return [{ cols: 12, sm: 8, lg: 8, xl: 9 }, { cols: 12, sm: 4, lg: 4, xl: 3 }];
            }
        },
        showImport: {
            type: Boolean,
            default: false
        },
        showExport: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        tab: 'components',
        activePage: null,
        activeTemplate: null,
        tabsrefresh: 0,
        measurementTypes: {},
        isComponentDialogVisible: false,
        settings: require('@/eodvuecomponents/src/classes/settings.js'),
    }),
    mounted() {
        this.scrollablePanel();
        this.loadTemplateMeasurements().then(() => {
            this.$nextTick(() => {
                // Sortable bugfix
                if (this.getActivePage() != null) {
                    if (this.value.content.pages[this.getActivePage()].cells.length > 0) {
                        this.$refs['component_page_' + this.getActivePage() + '_items'][0].updatePosition(0, 0);
                    }
                }
            });
        });
    },
    methods: {
        dialogVisibilityChanged(val){
            if(!val && this.getActiveCell() != null){
                const val = this.value;
                val.content.pages[this.getActivePage()].cells.splice(this.getActiveCell(), 1);
                this.$emit('input', val);
                this.clearActive();
            }
        },
        getUniqueVariableName(variables, variableName, index){
            if(!index){
                index = 0;
            }
            for (let i = 0; i < variables.length; i++) {
                const variable = variables[i];
                if((index == 0 && variable.text == variableName) || variable.text == variableName + ' ('+index+')'){
                    index++;
                    return this.getUniqueVariableName(variables, variableName, index);
                }
            }

            if(index > 0){
                return variableName + ' ('+index+')';
            }

            return variableName;
        },
        addMeasurementTypes(){
            this.isComponentDialogVisible = false;
            
            const cell = this.value.content.pages[this.getActivePage()].cells[this.getActiveCell()];
            const measurementTypeIds = cell.settings.measurementTypeIds;
            if(cell.settings.productType && measurementTypeIds){
                const val = this.value;

                const variableId = uuidv4();

                // Create variable
                if(!val.content.variables){
                    val.content.variables = [];
                }

                let variableName = this.getUniqueVariableName(val.content.variables, cell.settings.productType.name);

                val.content.variables.push({
                    "text": variableName,
                    "product": null,
                    "value": variableId
                });

                val.content.pages[this.getActivePage()].cells.splice(this.getActiveCell(), 1);

                for (let i = 0; i < measurementTypeIds.length; i++) {
                    const id = measurementTypeIds[i];
                    const newCell = this.clone(this.getComponentByType('measurementType'));
                    newCell.settings.measurementTypeId = id;
                    newCell.settings.product = {
                        "type": "dynamic",
                        "variable": variableId
                    };
                    val.content.pages[this.getActivePage()].cells.splice(this.getActiveCell(), 0, newCell);
                }

                this.$emit('input', val);
                this.clearActive();
            }


        },
        getMeasurementItems(){
            let items = [];

            const ids = [];

            if(this.value){
                const template = this.value;
                const measurementTypes = this.$helper.getTemplateMeasurements(template);
                for (let i = 0; i < measurementTypes.length; i++) {
                    const measurementType = measurementTypes[i];
                    if(!this.measurementTypes[measurementType.id]){
                        ids.push(measurementType.id);
                    }else{
                        items.push(this.measurementTypes[measurementType.id]);
                    }
                }

                if(ids[0]){
                    this.$eod.get('measurementTypes', ['id', 'name', 'description'], {
                        whereIn: {
                            column: 'id',
                            array: ids
                        }}).then(response => {
                            const newItems = response.data.data.measurementTypes.edges;
                            for (let i = 0; i < newItems.length; i++) {
                                const newItem = newItems[i];
                                items.push(newItem);
                                this.measurementTypes[newItem.id] = newItem;
                            }

                            for (let i = 0; i < ids.length; i++) {
                                const id = ids[i];
                                if(!this.measurementTypes[id]){
                                    this.measurementTypes[id] = {};
                                }
                            }

                            this.$forceUpdate();
                        });
                    
                }
            }            
            return items;
        },
        getVisibleComponents() {
            let components = [];
            if (this.components) {
                for (let i = 0; i < this.components.length; i++) {
                    const component = this.components[i];
                    if (typeof component.visible == 'function' && this.getActivePage() != null) {
                        if (component.visible(this.value.content.pages[this.getActivePage()], this.$eod)) {
                            components.push(component);
                        }
                    } else if (component.visible !== false) {
                        components.push(component);
                    }
                }
            }

            return components;
        },
        refreshTabs() {
            this.tabsrefresh++;
            this.$forceUpdate();
        },
        deleteStep(index) {
            this.value.content.pages.splice(index, 1);
            this.activePage = 0;
            this.$forceUpdate();
        },
        showPageSettings() {
            this.clearActive();
            this.tab = 1;
        },
        loadTemplateMeasurements() {
            const template = this.value;
            let measurements = this.$helper.getTemplateMeasurements(template);

            let ids = [];
            for (let i = 0; i < measurements.length; i++) {
                ids.push(measurements[i].id);
            }

            return this.$eod.get('measurementTypes', ['id', 'name', 'description'], {
                whereIn: {
                    column: 'id',
                    array: ids
                }
            }).then(result => {
                //this.measurementItems = result.data.data.measurementTypes.edges;
            });
        },
        scrollablePanel() {
            let panel = this.$refs.settingsPanel.$el;
            panel.style.transition = "all 0.1s";
            let originalTop = 140; //panel.getBoundingClientRect()

            let editor = null;

            setInterval(() => {

                if (!editor) {
                    editor = document.getElementById('editor');
                    return false;
                }

                originalTop = (editor.getBoundingClientRect().top - document.body.getBoundingClientRect().top) - 80;

                let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                if (scrollTop > originalTop) {
                    panel.style.marginTop = (scrollTop - originalTop) + 'px';
                } else {
                    panel.style.marginTop = 0;
                }
            }, 100);
        },
        reloadActiveItem(cellId, evt) {
            this.loadTemplateMeasurements();
            this.$refs['component_' + cellId][0].reload(evt);
        },
        updateActiveItem(cellId, evt) {
            this.$refs['component_' + cellId][0].update(evt);
        },
        setActivePage(index) {
            this.activePage = index;
        },
        getActivePage() {
            return this.activePage;
        },
        getActiveCell() {
            let index = null;

            if (!this.value) {
                return index;
            }

            if (!this.value.content.pages[this.activePage]) {
                return index;
            }

            let activePage = this.value.content.pages[this.activePage];

            for (let i = 0; i < activePage.cells.length; i++) {
                const cell = activePage.cells[i];
                if (cell.active) {
                    return i;
                }
            }

            return index;
        },
        tabUpdate(evt) {
            let tabNumber = this.activePage // The active tab number before udpate
            let oldIndex = evt.oldIndex; // Old index number of tab we are moving
            let newIndex = evt.newIndex; // New index number of tab we are moving
            let tabActive = null; // The new tab which can be set as active tab

            if (tabNumber === oldIndex) {
                tabActive = newIndex;
            } else if (tabNumber === newIndex && tabNumber < oldIndex) {
                tabActive = tabNumber + 1;
            } else if (tabNumber === newIndex && tabNumber > oldIndex) {
                tabActive = tabNumber - 1;
            } else if (tabNumber < oldIndex) {
                tabActive = tabNumber + 1;
            } else if (tabNumber > oldIndex) {
                tabActive = tabNumber - 1;
            }
            this.activePage = tabActive;
        },
        clone(component) {
            let data = Object.assign({}, component);
            return {
                id: uuidv4(),
                typeId: data.id,
                name: data.name,
                settings: data.settings ? Object.assign({}, data.settings) : {}
            }
        },
        componentAdded(evt, pageIndex) {
            const component = this.getComponentByType(this.value.content.pages[pageIndex].cells[evt.newIndex].typeId);
            if(component.viewComponent){
                this.showSettings(evt.newIndex);
            }else{
                this.showDialog(evt.newIndex);
            }            
        },
        showDialog(cellIndex){
            this.setActive(cellIndex).then(() => {
                this.isComponentDialogVisible = true;
            });
        },
        getComponentByType(typeId) {
            for (let i = 0; i < this.components.length; i++) {
                const component = this.components[i];
                if (component.id == typeId) {
                    return Object.assign({}, component);
                }
            }

            return null;
        },
        deleteItem(cellIndex) {
            this.value.content.pages[this.activePage].cells.splice(cellIndex, 1);
        },
        showSettings(cellIndex) {
            this.setActive(cellIndex).then(() => {
                this.tab = 1;

                if (this.$refs['settingsTab'] && this.$refs['settingsTab'].init) {
                    this.$refs['settingsTab'].init();
                }

                this.$forceUpdate();
            });
        },
        setActive(cellIndex) {
            return this.clearActive().then(() => {
                this.value.content.pages[this.activePage].cells[cellIndex].active = true;
            });
        },
        clearActive() {
            return new Promise((resolve, reject) => {
                let activePage = this.value.content.pages[this.activePage];

                for (let i = 0; i < activePage.cells.length; i++) {
                    this.value.content.pages[this.activePage].cells[i].active = false;
                }
                this.$forceUpdate();
                resolve();
            });
        },
        addPage() {
            const page = {
                id: uuidv4(),
                name: this.stepName + ' ' + (this.value.content.pages.length + 1),
                removeable: true,
                cells: []
            }

            if(this.addStep){
                this.addStep(page);
            } else {
                this.value.content.pages.push(page);
                this.$emit('addedStep', page);
                this.$forceUpdate();
            }
        },

    }
}
</script>