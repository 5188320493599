<template>
    <v-menu :close-on-content-click="false" v-model="isMenuVisible" :disabled="disabled">
        <template v-slot:activator="{on, attrs}">
            <div v-if="!multiple" class="d-flex" :class="filter?'eod-input-filter':''">
                <v-chip v-if="filter && label" label>{{label}}</v-chip>
                <v-text-field :hide-details="hideDetails" :clearable="clearable" :dark="filter" append-icon="mdi-chevron-down" :background-color="filter?'secondary':''" :rules="rules" :required="required" :prepend-inner-icon="resolvedItems[0]?TreeObject.getIcon(resolvedItems[0].class):null" :value="resolvedItems[0]?resolvedItems[0].name:''" readonly @click:clear="cleared" dense outlined :label="filter?null:label" v-on="on" v-bind="attrs"></v-text-field>
            </div>
            <div v-else v-on="on" v-bind="attrs" class="v-input v-input--hide-details v-input--is-readonly v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                <div class="v-input__control">
                <div class="v-input__slot">
                <fieldset aria-hidden="true"><legend style="width: 0px;"><span class="notranslate">​</span></legend></fieldset>
                <div class="v-text-field__slot">
                <v-label class="objects-treeview-label" :class="{'v-label--active':(isMenuVisible || resolvedItems[0])}" aria-hidden="true" v-if="label">{{ label }}</v-label>
                <div class="chips">
                <v-chip small clearable style="margin:4px 5px 4px 0;" v-for="item in resolvedItems" :key="item.id"><v-icon small left>{{ TreeObject.getIcon(item.class) }}</v-icon>{{ item.name }}</v-chip>
            </div>
            </div>
        </div>
        </div>
            </div>
        </template>
        <v-dialog v-model="isMenuVisible" v-if="$isMobile && isMenuVisible" fullscreen>
            <eod-card :loading="isLoading" tile>
                <v-card-title class="text-body-1">
                    <eod-text-field autofocus v-if="$isMobile" label="Zoeken" @input="updateSearch"></eod-text-field>
                    <eod-search v-else v-model="searchFilters" flat topbar :readonly="searchReadonly" :config="searchbarConfig" @update="updateSearch"></eod-search>
                </v-card-title>
                <v-card-text style="height:calc(100vh - 170px);overflow-y: auto;">
                <eod-objects-treeview ref="treeview" :open="open" hide-extra :multiple="multiple" :selected="resolvedItems" :selectable="selectable" :search="$helper.filtersToVariables(searchFilters)" readonly v-bind="$props" @selectionChanged="selectionChanged" @selected="itemSelected">
                    <template v-slot:item-extra="{item}">
                        <div v-if="hideType"></div>
                    </template>
                </eod-objects-treeview>
            </v-card-text>
            <slot name="append-item"></slot>
            <v-card-actions class="grey lighten-3 py-3 px-3">
                <v-spacer></v-spacer>
                <v-btn text rounded @click="isMenuVisible = false">Annuleren</v-btn>
            </v-card-actions>
            </eod-card>            
        </v-dialog>
        <eod-card :loading="isLoading" v-if="!$isMobile">
            <v-card-title class="text-body-1">
                <eod-text-field v-if="$isMobile" label="Zoeken" @input="updateSearch"></eod-text-field>
                <eod-search v-else v-model="searchFilters" flat topbar :readonly="searchReadonly" :config="searchbarConfig" @update="updateSearch"></eod-search>
            </v-card-title>
            <v-card-text style="height:300px;overflow-y: auto;">
            <eod-objects-treeview ref="treeview" :open="open" hide-extra :multiple="multiple" :selected="resolvedItems" :selectable="selectable" :search="$helper.filtersToVariables(searchFilters)" readonly v-bind="$props" @selectionChanged="selectionChanged" @selected="itemSelected">
                <template v-slot:item-extra="{item}">
                    <div v-if="hideType"></div>
                </template>
            </eod-objects-treeview>
        </v-card-text>
        <slot name="append-item"></slot>
        </eod-card>
    </v-menu>
</template>
<style lang="scss" scoped>
    .objects-treeview-label{
        position:absolute !important;
    }
</style>
<style lang="scss">
    .eod-input-filter{
        fieldset{
            border:none !important;
        }

        .v-input {
            border-top-left-radius:0 !important;
            border-bottom-left-radius:0 !important;
        }

        .v-chip {
            border-top-right-radius: 0 !important;border-bottom-right-radius: 0 !important;
        }
    }
</style>
<script>
    import eodObjectsTreeview from './eod-objects-treeview';
    import eodSearch from './eod-search';
    import TreeObject from './../models/treeObject';
    import Product from './../models/product';

    export default {
        name: 'eod-objects-treeview-input',
        components: {
            eodObjectsTreeview,
            eodSearch,
        },
        props:{
            label: String,
            disabled: Boolean,
            returnObject: Boolean,
            clearable: Boolean,
            value: String|Object,
            required: Boolean,
            multiple: Boolean,
            rules: Array,
            open: Array,
            exclude: Array,
            classes: Array,
            filters: Array,
            search: Array,
            searchReadonly: Boolean,
            hideType: {
                type:Boolean,
                default: false
            },
            hideDetails: {
                type:Boolean,
                default: true
            },
            searchConfig: {
                type: Array,
                default: () => (Product.searchConfig)
            },
            filter: Boolean,
            objectModel: {
                type: String,
                default: 'Product'
            },
            selectable: {
                type: Array,
                default: () => (['DEVICE', 'DEVICE_FOLDER', 'ARTICLE', 'ARTICLE_FOLDER', 'TOOL', 'TOOL_FOLDER', 'PROJECT'])
            },
        },
        data: () => ({
            searchbarConfig: null,
            searchFilters: [],
            resolvedItems: [],
            isMenuVisible: false,
            searchTimeout: null,
            TreeObject:TreeObject,
            isLoading: false
        }),
        created(){
            if(this.search){
                this.searchFilters = this.search;
            }

            const config =  this.searchConfig;
            if(this.filters){
                for (let i = 0; i < this.filters.length; i++) {
                    const filter = this.filters[i];
                    config.push(filter);
                }
            }
            
            this.searchbarConfig = config;

            this.resolveItem();
        },
        watch: {
            value(newVal, oldVal){
                if(!newVal){
                    this.resolvedItems = [];
                }else if(newVal != oldVal){
                    this.resolveItem();
                }
            },
            isMenuVisible(newVal){
                if(newVal){
                    if(this.search){
                        this.searchFilters = this.search;
                    } else {
                        this.searchFilters = [];
                    }
                }
            },
            search(newVal, oldVal){
                this.searchFilters = newVal;
            },
        },
        methods:{
            cleared(){
                this.resolvedItems = null;
                this.$emit('input', null);
            },
            updateSearch(val){

                clearTimeout(this.searchTimeout);

                this.isLoading = true;

                this.searchTimeout = setTimeout(() => {
                    if(this.$isMobile){
                        const nameSearchConfig = this.$helper.getArrayItemByProperty(this.searchbarConfig, 'filter', 'tree_objects_view.name');
                        for (let i = 0; i < this.searchFilters.length; i++) {
                            const filter = this.searchFilters[i];
                            if(filter.config.filter == 'tree_objects_view.name'){
                                this.searchFilters.splice(i, 1);
                                break;
                            }
                        }

                        if(val && val != ''){
                            this.searchFilters.push({
                                config: nameSearchConfig,
                                result: {
                                    name: val,
                                    id: val,
                                    search_title: val
                                }
                            })
                        }
                    }
                    
                    this.$nextTick(() => {
                        if(this.$refs.treeview){
                            this.$refs.treeview.fetchItems();
                        }
                    });

                    this.isLoading = false;
                }, 1000);

                
            },
            itemSelected(item){
                if(!this.multiple){
                    this.resolvedItems = [item];

                    if(this.returnObject){
                        this.$emit('input', item);
                    }else{
                        this.$emit('input', item.id);
                    }
                    this.isMenuVisible = false;
                }
            },
            selectionChanged(selection){
                if(this.multiple){
                    this.resolvedItems = selection;
                    if(this.returnObject){
                        this.$emit('input', selection);
                    }else{
                        this.$emit('input', selection.map(item => item.id));
                    }
                    
                }
            },
            resolveItem(){
                if(this.value){
                    if(this.multiple){
                        const ids = (Array.isArray(this.value) && this.returnObject)?this.value.map(item => item.id):this.value;

                        if(ids[0]){
                            this.$eod.get('resolveTreeObjects', ['id', 'name', 'module', 'class', 'parentIds', 'childIds'], {
                            whereIn:[
                                {
                                    column:'tree_objects_view.id',
                                    array: this.returnObject?this.value.map(item => item.id):this.value
                                }
                            ]
                        })
                            .then(response => {
                                if(response && response.data.data && response.data.data.resolveTreeObjects){
                                    this.resolvedItems = response.data.data.resolveTreeObjects.edges;
                                }
                            });
                        }
                        
                    }else{
                        let itemId = this.value;
                        if(this.returnObject){
                            itemId = this.value.id;
                        }

                        if(itemId){
                            this.$eod.getById('treeObject', itemId, ['id', 'name', 'module', 'class'])
                            .then(response => {
                                if(response && response.data.data && response.data.data.treeObject){
                                    this.resolvedItems = [response.data.data.treeObject];
                                }
                            });
                        }

                        
                    }

                    
                }
            }
        }
    }
</script>