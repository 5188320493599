<template>
    <div class="d-flex">
        <v-select dense outlined style="width:300px;" :disabled="readonly" :value="value>0?'+':'-'" @change="changeType" :items="[{value:'+', text:'Geproduceerd'}, {value:'-', text:'Verbruikt'}]"></v-select>
        <eod-text-field :label="label" type="number" :value="Math.abs(value)" @input="updateQuantity" :disabled="readonly"></eod-text-field>
    </div>
</template>
<script>
export default {
    name: 'eod-stockmovement-quantity',
    props:{
        value: {
            type: Number|String,
            default: 0,
        },
        readonly:{
            type: Boolean,
            default: false
        },
        label: String
    },
    methods:{
        updateQuantity(quantity){
            let val = this.value
            if(val > 0){
                val = quantity;
            } else {
                val = quantity * -1;
            }

            this.$emit('input', parseFloat(val));
        },
        changeType(type){
            let val = this.value;
            if(type == '+'){
                val = Math.abs(val);
            } else {
                val = Math.abs(val)*-1;
            }

            this.$emit('input', parseFloat(val));
        },
    }
}
</script>