const { Article, ArticleType } = require('../../eodvuecomponents');

module.exports = {
    tabs:[
        {
              path: 'articles',
              text: 'Artikelen',
              counter: (eod) => {
                  return eod.count('countArticles').then(response => {
                      if (response.data.data && response.data.data.countArticles) {
                          return response.data.data.countArticles.totalCount;
                      } else {
                          return 0;
                      }
                  });
              }
          },
          {
              path: 'articles.types',
              text: 'Types',
          }
        ],
    routes: [
    {
        path: '/articles',
        name: 'articles',
        meta:  {
            permissions:['products.articles.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/articles/settings',
        name: 'articles.settings',
        meta:  {
            permissions:['products.articles.create']
        },
        component: () => import('./views/settings.vue')
    },
    {
        path: '/articles/types',
        name: 'articles.types',
        meta:{
            permissions:['products.articles.read']
        },
        component: () => import('./types/index.vue')
    },
    {
        path: '/articles/types/settings',
        name: 'articles.types.settings',
        component: () => import('./types/settings.vue')
    },
    {
        path: '/articles/types/detail/:id?',
        name: 'articleType',
        component: () => import('./types/detail.vue')
    },
    ],
    search: Article.searchConfig,
    index: {
        route: 'articles',
        settingsRoute: 'articles.settings',
        endpoint: 'articles',
        fields: ['id', 'name', 'description', 'children{id}', 'createdAt', 'updatedAt'],
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: 'Type',
                value: 'articleType.name',
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: '',
                width: 50,
                value: 'createdAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            },
            {
                width: 120,
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-actions.vue')
            }
        ],
        tabs: [],
    },
    itemObject: Article,
    types: {
        search: [{
                'icon': 'mdi-format-text',
                'label': 'Naam',
                'type': 'property',
                'filter': 'name'
            },
        ],
        itemObject: ArticleType,
        index: {
            route: 'articles.types',
            settingsRoute: 'articles.types.settings',
            endpoint: 'articleTypes',
            fields: ['id', 'name', 'isReadonly'],
            headers: [
                {
                    text: 'Naam',
                    value: 'name',
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
                },
                {
                    text: '',
                    width: 50,
                    value: 'createdAt',
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                    extra: {
                        icon: 'mdi-alarm-plus',
                        collapsed: true,
                        prepend: 'Aangemaakt op'
                    }
                }, {
                    text: '',
                    width: 50,
                    value: 'updatedAt',
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                    extra: {
                        icon: 'mdi-alarm',
                        collapsed: true,
                        prepend: 'Gewijzigd op'
                    }
                }, {
                    width: 120,
                    sortable: false,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-actions.vue')
                }
            ],
        },
        detail: {
            route: 'articleType',
            endpoint: 'articleType',
            objectName: 'ArticleType',
            fields: ['id', 'name', 'description', 'isReadonly', 'customFields{customFieldId, module, itemId, value}'],
            tabs: []
        },
    }
}