import axios from 'axios';
import moment from 'moment';
import {
    rrulestr
} from 'rrule';
import {
    eodErrorsDialog,
    vuetify,
    helper,
    Template,
    Task,
    Availability,
    User,
    MeasurementType,
    getEnv
} from '../..';
import Vue from 'vue';
const _ = require('lodash');

export default {
    get mobile_url() {
        return getEnv('VUE_APP_MOBILE_URL');
    },
    get backend_url() {
        const api_url = getEnv('VUE_APP_API_URL');
        if(api_url.includes('//')){
            return getEnv('VUE_APP_API_URL');
        }
        return location.protocol + '//' + getEnv('VUE_APP_API_URL');
    },
    get api_url() {
        return this.backend_url + '/private/' + this.getRealm();
    },
    get graphql_url() {
        if(getEnv('VUE_APP_GRAPHQL_URL')){
            return location.protocol + '//' + getEnv('VUE_APP_GRAPHQL_URL');
        }else{
            return this.api_url + '/graphql';
        }
    },
    user: null,
    token: null,
    setToken(token){
        this.token = token;
        axios.defaults.headers.common = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    },
    loginUser(token) {
        return new Promise((resolve, reject) => {
            this.setToken(token);

            this.user = User.fromJWT(token, this.getRealm());
            this.loadUser(this.user.id).then(() => {
                resolve(this.user);
            })
            
        });
    },
    loadUser(){
        return this.getById('user', this.user.id, ['id groups{id name} configuration'])
                .then(response => {
                    if (response.data.data && response.data.data.user) {
                        this.user.groups = response.data.data.user.groups;
                        this.user.configuration = response.data.data.user.configuration;
                    }
                    
                    return this.loadUserOrganization();
                });
    },
    loadUserGroups() {
        this.getById('user', this.user.id, ['id groups{id name}'])
            .then(response => {
                if (response.data.data && response.data.data.user) {
                    this.user.groups = response.data.data.user.groups;
                }
            });
    },
    setUserConfiguration(configName, value){
        const user = this.getUser();
        const configuration = user.configuration??{};
        configuration[configName] = value;

        return this.save('User', {
            id: user.id,
            configuration: configuration,
        }).then(() => {
            return this.loadUser();
        });
    },
    getUser() {
        return this.user;
    },
    getToken(){
        return this.token;
    },
    setToken(token) {
        this.token = token;
        axios.defaults.headers.common = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
        this.user = User.fromJWT(token, this.getRealm());
    },
    loadUserOrganization() {
        let organization = null;
        return this.getById('resolveOrganization', this.user.organization.id, ['id name description configuration{version}']).then(response => {
            organization = response.data.data.resolveOrganization;
            if (!organization.configuration) {
                organization.configuration = {
                    settings: [],
                    version: '0.0'
                };
            }

            return organization;
        }).then(() => {
            return this.loadOrganizationSettings();
        }).then((settings) => {
            organization.configuration.settings = Object.values(settings);
            this.user.organization = organization;
            return this.user.organization;
        });
    },
    getOrganization() {
        return this.user.organization;
    },
    setOrganization(organization) {
        this.user.organization = organization;
    },
    ORGANIZATION_SETTINGS: {},
    loadOrganizationSettings(){
        return this.get('organizationSettings', ['key', 'value','label','isDefault'], {
            orderBy: {
                column: 'key',
                type: 'asc'
              },
        }).then(response => {
            if(response.data.data && response.data.data.organizationSettings){
                for (let i = 0; i < response.data.data.organizationSettings.edges.length; i++) {
                    const setting = response.data.data.organizationSettings.edges[i];
                    this.ORGANIZATION_SETTINGS[setting.key] = setting;
                }

                return this.ORGANIZATION_SETTINGS;
            }

            return [];
        })
    },
    getOrganizationSetting(key) {
        return this.ORGANIZATION_SETTINGS[key];
    },
    saveOrganizationSetting(key, value) {
        return this.update('OrganizationSetting', {
            key: key,
            value: value,
            organizationId: this.getUser().organizationId
        }, {
            returnfields:['key']
        });
    },
    uploadImage(options) {
        return axios.post(this.api_url + '/storageObjects/image', options);
    },
    _LANGUAGES: null,
    getLanguages() {
        if(!this._LANGUAGES){
            let languages = [];
            const languagesSetting = this.getOrganizationSetting('languages');

            if(languagesSetting && languagesSetting.value){
                languages = JSON.parse(languagesSetting.value);
            }

            languages.sort(function (x, y) {
                return (x.isDefault === y.isDefault) ? 0 : x.isDefault ? -1 : 1;
            });

            this._LANGUAGES = languages;
        }

        return this._LANGUAGES;
    },
    _DEFAULT_LANGUAGE: null,
    getDefaultLanguage(){
        if(!this._DEFAULT_LANGUAGE){
            const languages = this.getLanguages();
            for (let i = 0; i < languages.length; i++) {
                const language = languages[i];
                if (language.isDefault) {
                    this._DEFAULT_LANGUAGE = language;
                    break;
                }
            }
        }
        return this._DEFAULT_LANGUAGE;
    },
    getRealm() {
        if (getEnv('VUE_APP_KEYCLOAK_REALM')) {
            return getEnv('VUE_APP_KEYCLOAK_REALM');
        } else {
            var hostname = window.location.hostname;
            var hostparts = hostname.split('.');
            return hostparts[0];
        }
    },
    getImageUrl(image) {
        return this.api_url + '/eodstorage/' + image;
    },
    getFormUrl(taskid) {
        return 'https://' + this.getRealm() + '.' + this.mobile_url + '/form/' + taskid;
    },
    getCancelRequest() {
        return axios.CancelToken.source();
    },
    graphql(query, variables, options) {
        let data = {
            query: query
        }

        if (variables) {
            data.variables = variables;
        }

        if (!options) {
            options = {};
        }

        return axios.post(this.graphql_url, data, {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            decompress: false,
            ...options
        }).then(response => {

            if (response.data.errors) {
                this.throwErrors(response.data.errors);
            }

            return response;
        }).catch(err => {
            if(!(err.code && err.code == 'ERR_CANCELED')){
                if(err.response){
                    this.throwErrors(err.response.data.errors);
                }else{
                    this.throwErrors([err]);
                }
                throw err;
            }

            

            //return {data:{data:null}};
        });
    },
    throwErrors(errors){
        let error_env = ['staging', 'test', 'development', 'dev'];
            if (error_env.includes(getEnv('NODE_ENV'))) {
                var ComponentClass = Vue.extend(eodErrorsDialog);
                var instance = new ComponentClass({
                    vuetify,
                    propsData: {
                        title: 'Fout!',
                        value: true,
                        errors: errors
                    }
                });

                const mountNode = window.document.createElement('div');
                window.document.getElementById('app').appendChild(mountNode);
                instance.$mount(mountNode);
            }
    },
    stats(modulename) {
        let query = 'query stat' + modulename + '($input: StatInput){stat' + modulename + '(input: $input){stat}}';
        return this.graphql(query);
    },
    count(modulename, variables, options) {
        let query = 'query ' + modulename + '($input: CountInput){' + modulename + '(input: $input){totalCount}}';

        if (variables) {
            variables = {
                input: variables
            }
        }

        return this.graphql(query, variables, options);
    },
    getRaw(endpoint) {
        return axios.get(this.api_url + endpoint);
    },
    post(endpoint, data) {
        return axios.post(this.api_url + endpoint, data, {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
        });
    },
    dashboard(endpoint, data, options){

        if(!options){
            options = {};
        }

        return axios.post(this.api_url + '/dashboard/'+endpoint, data, {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            decompress: false,
            ...options
        }).then(response => {
            return response;
        });
    },
    get(modulename, fields, variables, options) {
        let query = 'query list' + (modulename.charAt(0).toUpperCase() + modulename.slice(1)) + '($input: QueryInput){' + modulename + '(input: $input){totalCount pageInfo{hasNextPage} edges{' + fields.join("\n") + '}}}';

        if (!variables) {
            variables = {
                input: {
                    orderBy: {
                        column: "name",
                        type: "asc"
                    }
                }
            }
        } else {
            variables = {
                input: variables
            }
        }

        return this.graphql(query, variables, options);
    },
    checkExisting(modulename, fields, variables, options) {
        const uppercaseModule = (modulename.charAt(0).toUpperCase() + modulename.slice(1));
        let query = 'query listCheckExisting' + uppercaseModule + '($input: QueryInput){checkExisting' + uppercaseModule + '(input: $input){ edges {' + fields.join("\n") + '}}}';

        variables = {
            input: variables
        }

        return this.graphql(query, variables, options);
    },
    rotateImage(image_url) {
        return axios.post(this.api_url + '/images/rotate', {
            image: image_url
        });
    },
    generateResponseReportTemplate(responseId, options) {
        let params = '';
        if(options){
            params = '?'+helper.serialize({options:options});
        }
        return axios.get(this.api_url + '/report/response/template/' + responseId+params);
    },
    generateCustomTaskReport(taskId, template, oauthToken, folderId) {
        return axios.post(this.api_url + '/report/custom/task', {
            taskId: taskId,
            template: template,
            DriveOauthToken: oauthToken,
            DriveFolderId: folderId
        });
    },
    import(settings, file) {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('endpoint', settings.endpoint);
        formData.append('objectname', settings.objectName);

        return axios.post(this.api_url + '/import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    exportJobTypeForm(templateId) {
        return axios.post(this.api_url + '/export/jobType/form', {
            templateId: templateId
        });
    },
    exportJobTypeConfig(template) {
        return axios.post(this.api_url + '/export/jobType/config', {
            template: template
        });
    },
    importJobTypeForm(file) {
        let formData = new FormData();
        formData.append('file', file);

        return axios.post(this.api_url + '/import/jobType/form', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    importJobTypeConfig(file) {
        let formData = new FormData();
        formData.append('file', file);

        return axios.post(this.api_url + '/import/jobType/config', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    getDashboardFilters(content) {
        if (content.cells) {
            let queryIds = [];
            for (let i = 0; i < content.cells.length; i++) {
                const cell = content.cells[i];
                if (cell.settings && cell.settings.queries && cell.settings.queries[0]) {
                    queryIds.push(cell.settings.queries[0].id);
                }
            }

            return this.get('templates', ['id', 'type', 'content'], {
                whereIn: {
                    column: 'id',
                    array: queryIds
                }
            }).then(response => {
                let dashboardfilters = [];
                if (response.data.data.templates) {
                    let templates = response.data.data.templates.edges;
                    for (let i = 0; i < templates.length; i++) {
                        const template = templates[i];
                        if (template.type == 'QUERY') {
                            let queryfilters = helper.getQueryFilters(template.content);

                            for (let j = 0; j < queryfilters.length; j++) {
                                const filter = queryfilters[j];
                                const existing = helper.getFilterByKey(dashboardfilters, filter.key);
                                if(!existing){
                                    dashboardfilters.push(filter);
                                }
                            }
                        }
                    }
                }

                return dashboardfilters;
            });
        }

        return [];
    },
    importUsers(file, options) {
        let formData = new FormData();
        formData.append('file', file);

        if (options) {
            formData.append('options', JSON.stringify(options));
        }

        return axios.post(this.api_url + '/import/users', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    importResponses(settings, file) {
        let formData = new FormData();
        formData.append('file', file);

        return axios.post(this.api_url + '/import/responses', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    export(objectname, fields, variables) {
        let query = 'query ' + objectname + '($input: QueryInput){' + objectname + '(input: $input){totalCount edges{' + fields.join("\n") + '}}}';

        if (!variables) {
            variables = {
                input: {
                    orderBy: {
                        column: "name",
                        type: "asc"
                    }
                }
            }
        } else {
            variables = {
                input: variables
            }
        }

        let data = {
            objectname: objectname,
            query: query,
            variables: variables
        }

        return axios.post(this.api_url + '/export', data);
    },
    exportCustom(objectname, variables) {
        if (!variables) {
            variables = {
                input: {
                    orderBy: {
                        column: "name",
                        type: "asc"
                    }
                }
            }
        } else {
            variables = {
                input: variables
            }
        }

        let data = {
            objectname: objectname,
            variables: variables
        }

        return axios.post(this.api_url + '/export/custom', data);
    },
    exportCustomPreview(objectname, variables) {
        if (!variables) {
            variables = {
                input: {
                    orderBy: {
                        column: "name",
                        type: "asc"
                    }
                }
            }
        } else {
            variables = {
                input: variables
            }
        }

        let data = {
            objectname: objectname,
            variables: variables
        }

        return axios.post(this.api_url + '/export/custom/preview', data);
    },
    exportCustomSearchConfig(objectname) {
        let data = {
            objectname: objectname,
        }

        return axios.post(this.api_url + '/export/custom/search-config', data);
    },
    exportUsers(variables) {

        if (variables) {
            variables = {
                input: variables
            }
        }

        let data = {
            variables: variables
        }

        return axios.post(this.api_url + '/export/users', data);
    },
    cloudBackup(path, data) {
        return axios.post(this.api_url + '/backup/cloud/' + path, data);
    },
    exportBackup() {
        return axios.post(this.api_url + '/backup/export');
    },
    importBackup(file) {
        let formData = new FormData();
        formData.append('backup', file);

        return axios.post(this.api_url + '/backup/import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    uploadStorageObject(data) {

        const formData = new FormData();

        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                formData.append(key, data[key]);
            }
        }

        return axios.post(this.api_url + '/storageObjects/upload', formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    deleteStorageObject(itemId) {
        return axios.delete(this.api_url + '/storageObjects', {
            data: {
                id: itemId
            }
        });
    },
    createStorageObject(itemId, tags) {
        return axios.post(this.api_url + '/storageObjects', {
            id: itemId,
            tags: JSON.stringify(tags)
        });
    },
    getTranslation(text, to) {
        let query = 'query singleTranslation($text: String!, $to: String!){translation(text: $text, to: $to){text}}';

        const variables = {
            text: text,
            to: to,
        }

        return this.graphql(query, variables);
    },
    getById(objectname, id, fields, variables) {
        let query = 'query single' + (objectname.charAt(0).toUpperCase() + objectname.slice(1)) + '($id: ID!){ '+objectname+'(id: $id){' + fields.join(" ") + '}}';

        variables = {
            id: id
        }

        return this.graphql(query, variables);
    },
    getMe(fields, variables) {
        let query = 'query Me{ me{' + fields.join(" ") + '}}';

        return this.graphql(query, variables);
    },
    getRelated(objectname, id) {
        let query = 'query related' + objectname + 'Objects {' +
            'related' + objectname + 'Objects' + '(id: "' + id + '") {' +
            'id name type' +
            '}' +
            '}';

        return this.graphql(query);
    },
    getRoles(id) {
        let organizationId = id ? id : this.getUser().organizationId;

        return this.getById('organization', organizationId, ['availableRealmRoles{id name}']).then(result => {

            let roles = result.data.data.organization.availableRealmRoles;
            let settings = require('./settings');
            let ignore_roles = [];
            ignore_roles = ignore_roles.concat(settings.roles.exception, settings.roles.eod);

            let return_roles = [];

            for (let i = 0; i < roles.length; i++) {
                const role = roles[i];
                if (!ignore_roles.includes(role.name)) {
                    return_roles.push(role);
                }
            }

            return return_roles.sort((a, b) => (a.name > b.name) ? 1 : -1);
        });
    },
    distinct(modulename, fields, variables, options) {
        let query = 'query distinct' + (modulename.charAt(0).toUpperCase() + modulename.slice(1)) + '($input: QueryInput){distinct' + (modulename.charAt(0).toUpperCase() + modulename.slice(1)) + '(input: $input){distinct}}';

        if (!variables) {
            variables = {};
        }

        variables.fields = fields;

        variables = {
            input: variables
        }

        return this.graphql(query, variables, options);
    },
    update(objectname, data, options) {
        let methodname = 'update' + objectname;
        let inputname = 'input';
        let inputtype = objectname + 'Input';
        let returnfields = ['id'];

        if (options) {
            if (options.methodname) {
                methodname = options.methodname;
            }
            if (options.inputname) {
                inputname = options.inputname;
            }
            if (options.inputtype) {
                inputtype = options.inputtype;
            }
            if (options.returnfields) {
                returnfields = options.returnfields;
            }
        }

        let query = 'mutation ' + methodname + '($input: ' + inputtype + ') {' +
            methodname + '(' + inputname + ': $input) {' +
            returnfields.join(' ') +
            '}' +
            '}';

        let variables = {
            input: data
        }

        return this.graphql(query, variables);
    },
    updateMultiple(objectname, data, options) {
        return this.update(objectname, data, {
            methodname: 'update' + objectname + 's',
            inputname: 'listInput',
            inputtype: '[' + objectname + 'Input]',
            ...options
        })
    },
    requestRoutes(fields, data, options) {
        let query = 'mutation requestOptimizeRoutes($input: TimeInput) {requestOptimizeRoutes(input: $input) { edges{' +
            fields.join(' ') +
            '}}' +
            '}';

        let variables = {
            input: data
        }

        return this.graphql(query, variables, options);
    },
    create(objectname, data, options) {

        let methodname = 'create' + objectname;
        let inputname = 'input';
        let inputtype = objectname + 'Input';
        let returnfields = ['id'];

        if (options) {
            if (options.methodname) {
                methodname = options.methodname;
            }
            if (options.inputname) {
                inputname = options.inputname;
            }
            if (options.inputtype) {
                inputtype = options.inputtype;
            }
            if (options.returnfields) {
                returnfields = options.returnfields;
            }
        }

        let query = 'mutation ' + methodname + '($input: ' + inputtype + ') {' +
            methodname + '(' + inputname + ': $input) {' +
            returnfields.join(' ') +
            '}' +
            '}';

        let variables = {
            input: data
        }

        return this.graphql(query, variables);
    },
    sendResponse(response, status_id, response_status, response_id) {
        return axios.post(this.api_url + '/report/save', {
            response: response,
            status: status_id,
            response_id: response_id,
            response_status: response_status,
            version: process.env.VUE_APP_VERSION
        });
    },
    sendMessage(user, data) {
        return axios.post(this.api_url + '/message/send', {
            data: data,
            user: user,
        });
    },
    createMultiple(objectname, data, options) {
        return this.create(objectname, data, {
            methodname: 'create' + objectname + 's',
            inputname: 'listInput',
            inputtype: '[' + objectname + 'Input]',
            ...options
        })
    },
    async saveMultiple(objectname, data, removeOrganization, options) {

        if (!removeOrganization) {
            // Always add organizationId
            let user = this.getUser();
            for (let i = 0; i < data.length; i++) {
                data[i].organizationId = user.organizationId;
            }
        }

        let to_update = [];
        let to_create = [];

        for (let i = 0; i < data.length; i++) {
            if (data[i].id) {
                to_update.push(data[i]);
            } else {
                to_create.push(data[i]);
            }
        }

        let updated = null;
        let created = null;
        // Check if update or create
        if (to_update.length > 0) {
            updated = await this.updateMultiple(objectname, to_update, options);
        }

        if (to_create.length > 0) {
            created = await this.createMultiple(objectname, to_create, options);
        }

        return {
            updated: updated,
            created: created
        };
    },
    save(objectname, data, options) {

        if (!options) {
            options = {};
        }

        //data.isActive = true;

        // Check if update or create
        if (data.id) {
            return this.update(objectname, data, options.update);
        } else {
            return this.create(objectname, data, options.create);
        }
    },
    async prepareCreateWorkflowData(data) {

        let workflowData = {};

        if (data.comments && data.comments[0]) {
            workflowData.description = data.comments[0].content;
        }else if(data.comment){
            workflowData.description = data.comment;
        }

        const defaultTaskTypeSetting = await this.getOrganizationSetting('tasks.taskType.default.portal');
        if (defaultTaskTypeSetting) {
            workflowData.taskTypeId = defaultTaskTypeSetting.value;
        }

        if (data.storageObjects) {
            workflowData.storageObjects = data.storageObjects;
        } else if (data.images) {
            workflowData.storageObjects = data.images;
        }

        if (workflowData.storageObjects) {
            for (let i = 0; i < workflowData.storageObjects.length; i++) {
                workflowData.storageObjects[i].needsCopy = true;
            }
        }

        return workflowData;
    },
    delete(objectname, id, options) {

        const methodname = 'delete' + objectname;

        let inputtype = 'ID!';
        let inputname = 'id';
        if(options){
            if(options.inputtype){
                inputtype = options.inputtype;
            }
            if(options.inputname){
                inputname = options.inputname;
            }
        }

        let query = 'mutation ' + methodname + '($input: ' + inputtype + ') {' + methodname + '(' + inputname + ': $input) }';

        let variables = {
            input: id
        }

        return this.graphql(query, variables);
    },
    getQueryGrouping(from, to) {
        return new Promise(async resolve => {
            let grouping = "1m";
            let diff = to.diff(from, 'm');

            let setting_key = 'dashboard.interval.default';
            if (diff <= 1440) { // 24u
                setting_key = 'dashboard.interval.day';
            } else if (diff <= 10080) { // 7 days
                setting_key = 'dashboard.interval.week';
            } else if (diff <= 44640) {
                setting_key = 'dashboard.interval.month';
            } else if (diff <= 525949) {
                setting_key = 'dashboard.interval.year';
            }

            const intervalSetting = await this.getOrganizationSetting(setting_key);
            if (intervalSetting) {
                grouping = intervalSetting.value;
            }

            resolve({
                diff: diff,
                grouping: grouping,
            });
        });
        
    },
    sendMail(addresses, type, options){
        if(!options){
            options = null;
        }
        
        return axios.post(this.api_url + '/mail', {
            addresses: addresses,
            type: type,
            options: options
        });
    },
    queryMeasurements(templateIndex, template, filters, options) {
        let query = 'query queryMeasurements($input: QueryMeasurementsInput){ queryMeasurements (input: $input) }';

        return this.graphql(query, {
            input: {
                unique: ''+templateIndex,
                template: template,
                filters: filters,
                options: options,
            }
        }).then(response => {
            if (response.data.data.queryMeasurements) {
                return response.data.data.queryMeasurements;
            }

            return null;
        }).catch(e => {
            console.error('error query measurements', e);
            return null;
        });
    },
    rejectProposal(job) {
        return this.getById('task', job.id, ['id', 'availability{endDateTime endTimeUnspecified startDateTime recurrence}'])
            .then(response => {
                if (response.data.data && response.data.data.task) {
                    return response.data.data.task.availability;
                }

                return null;
            }).then(async availabilities => {

                if (!availabilities) {
                    const availabilitySetting = await this.getOrganizationSetting('projects.default.availability');
                    if (availabilitySetting && availabilitySetting.value) {
                        availabilities = JSON.parse(availabilitySetting.value);
                    }
                }

                if (availabilities) {
                    let changed = false;

                    for (let i = 0; i < availabilities.length; i++) {
                        const availability = new Availability(availabilities[i]);
                        const before = availability.contains(job.dueBeforeDate);
                        const after = availability.contains(job.dueAfterDate);
                        if (before) {
                            // Add exception for the job
                            availability.recurringAdvanced.exceptionDates.push(before);
                            changed = true;
                        } else if (after && before != after) {
                            availability.recurringAdvanced.exceptionDates.push(after);
                            changed = true;
                        }

                        availabilities[i] = availability.getSaveData();
                    }

                    if (changed) {
                        return this.save('Task', {
                            id: job.id,
                            availability: availabilities
                        });
                    }
                }

                return null;
            });
    },
    getDynamicFilters(content) {
        let query = '';
        if (content.dynamic_filters) {
            for (let i = 0; i < content.dynamic_filters.length; i++) {
                const filter = content.dynamic_filters[i];
                if (filter.field.value) {
                    if (filter.field.key == 'measurementType') {
                        query += '[[dynamic_filter:' + filter.field.value + ']] ';
                    }
                }
            }
        }


        return query;
    },
    getDateByPeriodicityCode(periodicityCode, date) {
        let current_date = moment(date).format("YYYYMMDD\THHmmss") + 'Z';
        const rule = rrulestr('DTSTART:' + current_date + "\n" + periodicityCode + "COUNT=2");
        const dates = rule.all();
        if (dates[1]) {
            return moment(dates[1]);
        }

        return null;
    },
    getMeasurementTypeUnitFields(measurementType) {
        let fields = {};
        if (measurementType.unit && measurementType.unit.fields) {
            for (let i = 0; i < measurementType.unit.list.fields.length; i++) {
                const field = measurementType.unit.list.fields[i];
                fields[field.name] = measurementType.unit.fields[i].name;
            }
        }

        return fields;
    },
    getMeasurementTypeItems(measurementType) {
        let itemsByThreshold = {};
        let listItems = [];
        if(measurementType){
            if (measurementType.valueConfigListItems && measurementType.valueConfigListItems.length > 0) {
                listItems = measurementType.valueConfigListItems;
            } else if (measurementType.valueConfigList && measurementType.valueConfigList.listItems && measurementType.valueConfigList.listItems.length > 0) {
                listItems = measurementType.valueConfigList.listItems;
            }
        }
        
        for (let i = 0; i < listItems.length; i++) {
            const listItem = listItems[i];
            itemsByThreshold[listItem.threshold] = listItem;
        }

        return itemsByThreshold;
    },
    getProductVariableById(template, variableId) {
        if (template.content.variables) {
            for (let i = 0; i < template.content.variables.length; i++) {
                const variable = template.content.variables[i];
                if (variable.value == variableId) {
                    return variable;
                }
            }
        }

        return null;
    },
    saveAlarmRule(template) {
        const temp = new Template(template);
        const data = temp.getSaveData();

        return this.save('Template', data);
    },
    async generateMeasurementTypeQuery(measurementType, defaults) {
        let queryTemplate = this.getTemplateByType(measurementType.templates, 'QUERY');

        if (!queryTemplate || (queryTemplate && queryTemplate.content && queryTemplate.content.rawQuery)) {
            let newQueryTemplate = {
                type: 'QUERY',
                object: 'MEASUREMENT_TYPE',
                access: 'PUBLIC',
                isSystem: true,
                isActive: true,
                name: measurementType.name,

                content: {
                    source: 'responses',
                    measurementTypes: [{
                        aggregation: 'last',
                        object: {
                            id: measurementType.id,
                            name: measurementType.name,
                            description: measurementType.description
                        }
                    }],
                    dynamic_filters: [{
                        field: {
                            endpoint: 'jobTypes',
                            key: 'job_type_id',
                            title: 'Jobtype',
                        },
                        value: 'Jobtype',
                        isHidden: true
                    }, {
                        field: {
                            endpoint: 'resolveProjects',
                            key: 'project_id',
                            title: 'Project',
                        },
                        value: 'Project',
                        isHidden: true
                    },
                    {
                        field: {
                            endpoint: 'resolveUsers',
                            key: 'owner_id',
                            title: 'Eigenaar',
                        },
                        value: 'Eigenaar'
                    },
                    {
                        field: {
                            endpoint: 'resolveProducts',
                            key: 'product_id',
                            title: 'Toestel',
                        },
                        value: 'Toestel'
                    }
                    ],
                    filters: [],
                    group: {
                        key: 'time',
                        title: 'Tijd',
                        value: '1h'
                    }
                }
            }

            if (defaults.query) {
                newQueryTemplate = helper.mergeObjects(newQueryTemplate, defaults.query);
            }

            if (!measurementType.templates) {
                measurementType.templates = [];
            }

            if(queryTemplate){
                newQueryTemplate.id = queryTemplate.id;
            }

            measurementType.templates.push(newQueryTemplate);
            const saveData = await measurementType.getSaveData();
            await this.save('MeasurementType', saveData);

            queryTemplate = this.getTemplateByType(measurementType.templates, 'QUERY');
        }

        return queryTemplate;
    },
    async getProjectDashboardTabs(projectId){

        const user = this.getUser();
        const configuration = user.configuration??{};
        let tabs = [];

        const getTabParams = (tab) => {
            return {
                job_type_id: tab.jobTypes && tab.jobTypes[0]?tab.jobTypes[0].id:null,
                product_id:  tab.products && tab.products[0]?tab.products[0].id:null,
                product_type_id: tab.productTypes && tab.productTypes[0]?tab.productTypes[0].id:null
            }
        }

        const templateTabs =  await this.get('templates', ['id', 'name', 'access', 'jobTypes{id}', 'projects{id}', 'products{id}', 'productTypes{id}'], {
            whereInRelated:[{
                 column:'project_id',
                 relation: 'projects_templates',
                 array: [projectId]
             }],
             where:[
                 {
                     column: 'type',
                     operator:'=',
                     value: 'DASHBOARD'
                 }
             ]
        }).then(response => {
            const tabs = [];
            if(response.data.data && response.data.data.templates.edges.length > 0){
                for (let j = 0; j < response.data.data.templates.edges.length; j++) {
                    const dashboard = response.data.data.templates.edges[j];
                    tabs.push({
                        ...dashboard,
                        type: 'dashboard',
                        to: {
                            name:'dashboard',
                            params:{id:dashboard.id},
                            query:{
                                ...getTabParams(dashboard),
                                project_id:projectId,
                            }
                        }
                    });
                }
            }

            return tabs;
        });

        tabs = tabs.concat(templateTabs);

        if(user.can('jobtypes.read')){
            const jobTypeTabs = await this.get('resolveJobTypesThroughTasks', ['id', 'name'], {
                where:[{
                    column: 'projectId',
                    operator: '=',
                    value: projectId
                }]
            }).then(response => {
                const tabs = [];
                if(response){
                    if(response.data.data && response.data.data.resolveJobTypesThroughTasks.edges.length > 0){
                        for (let j = 0; j < response.data.data.resolveJobTypesThroughTasks.edges.length; j++) {
                            const jobtype = response.data.data.resolveJobTypesThroughTasks.edges[j];
                            tabs.push({
                                ...jobtype,
                                type: 'jobtype',
                                to: {
                                    name:'dashboard.jobtype',
                                    params:{id:jobtype.id},
                                    query:{
                                        job_type_id: jobtype.id,
                                        project_id:projectId,
                                    }
                                }
                            });
                        }
                    }
                }

                return tabs;
            });

            tabs = tabs.concat(jobTypeTabs);
        }

        if(user.can('products.devices.read')){

            // Get first product of project
            const product = await this.get('products', ['id'], {
                where:[{
                    column: 'projectId',
                    operator: '=',
                    value: projectId
                }],
                orderBy: {
                    column: "name",
                    type: "asc"
                },
                limit: 1
            }).then(response => {
                if(response && response.data.data && response.data.data.products && response.data.data.products.edges[0]){
                    return response.data.data.products.edges[0];
                }

                return null;
            });

            if(product){
                tabs.push({
                    ...product,
                    name: 'Assets',
                    type: 'product',
                    to: {
                        name:'dashboard.product',
                        params:{id:product.id},
                        query:{
                            ...getTabParams(product),
                            project_id:projectId,
                        }
                    }
                });
            }
        }

        if(configuration.projects && configuration.projects[projectId] && configuration.projects[projectId].dashboardTabs){
            const sortArray = configuration.projects[projectId].dashboardTabs;
            tabs.sort(function(a, b){
                return (sortArray.indexOf(a.id) == -1?100:(sortArray.indexOf(a.id) - sortArray.indexOf(b.id)));
            });
        }

        return tabs;
    },
    generateFormTemplateDashboard(item, cellTypes, defaults) {
        const template = helper.getTemplateByType(item.templates, 'FORM');

        let measurementTypeIds = [];
        for (let pageIndex = 0; pageIndex < template.content.pages.length; pageIndex++) {
            const page = template.content.pages[pageIndex];
            for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                const cell = page.cells[cellIndex];
                if (cell.typeId == 'measurementType' && !measurementTypeIds.includes(cell.settings.measurementTypeId)) {
                    measurementTypeIds.push(cell.settings.measurementTypeId);
                }
            }
        }

        return this.generateDashboardForMeasurements(measurementTypeIds, cellTypes, defaults, template);
    },
    async generateDashboardForMeasurements(measurementTypeIds, cellTypes, defaults, template){

        const measurementTypes = await this.get('measurementTypes', ['id', 'name', 'isWritable', 'description', 'templates{id type object content}', 'valueConfigList{id name itemType listItems{id name color}}', 'valueConfigListItems{id name color}', 'unit{id name color fields{name}}', 'dashboardConfig', 'formConfig{category type enabled required photo photoRequired comment commentRequired default defaultValue values}'], {
            whereIn: {
                column: 'id',
                array: measurementTypeIds
            }
        })
            .then(async response => {
                return response.data.data.measurementTypes.edges;
            });

        let graphs = {
            graph_tile: {},
            graph_profile: {
                groupAll: true
            },
            graph_line: {
                groupByUnit: false,
                groupByMeasurementType: true
            },
            graph_vbar: {
                groupByUnit: false,
                groupByMeasurementType: true
            },
            graph_liquid: {},
        }

        for (let i = 0; i < measurementTypes.length; i++) {
            const measurementType = new MeasurementType(measurementTypes[i]);

            let queryTemplate = await this.generateMeasurementTypeQuery(measurementType, defaults);

            // Filling all supported graph types with their measurementTypes
            for (const graphType in graphs) {
                if (Object.hasOwnProperty.call(graphs, graphType)) {
                    if (measurementType.dashboardConfig && measurementType.dashboardConfig.graphs.includes(graphType)) {
                        const config = graphs[graphType];

                        let unique = config.groupAll ? 'all' : ((config.groupByUnit && measurementType.unit) ? measurementType.unit.id : measurementType.id);

                        if (!graphs[graphType].cells) {
                            graphs[graphType].cells = {};
                        }

                        // Get products linked to measurementType in form
                        const productIds = [];
                        const variableIds = [];
                        if(template){
                            for (let pageIndex = 0; pageIndex < template.content.pages.length; pageIndex++) {
                                const page = template.content.pages[pageIndex];
                                for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                                    const cell = page.cells[cellIndex];
                                    if (cell.typeId == 'measurementType' && cell.settings.measurementTypeId == measurementType.id) {
                                        if (cell.settings.product) {
                                            if (cell.settings.product.id) {
                                                productIds.push(cell.settings.product.id);
                                            } else if (cell.settings.product.variable) {
                                                variableIds.push(cell.settings.product.variable);
                                                // const variable = this.getProductVariableById(template, cell.settings.product.variable);
                                                // if (variable.product && variable.product.id) {
                                                //     productIds.push(variable.product.id);
                                                // }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        

                        if(config.groupAll || config.groupByMeasurementType){
                            if (!graphs[graphType].cells[unique]) {
                                graphs[graphType].cells[unique] = [];
                            }

                            graphs[graphType].cells[unique].push({
                                measurementType: measurementType,
                                queryTemplate: queryTemplate
                            });
                        } else {
                            if(variableIds.length > 0){
                                for (let variableIndex = 0; variableIndex < variableIds.length; variableIndex++) {
                                    const variableId = variableIds[variableIndex];
                                    const cellId = unique + '_' + variableId;
                                    if (!graphs[graphType].cells[cellId]) {
                                        graphs[graphType].cells[cellId] = [];
                                    }

                                    graphs[graphType].cells[cellId].push({
                                        variable_id: variableId,
                                        measurementType: measurementType,
                                        queryTemplate: queryTemplate
                                    });
                                }
                            }else if (productIds.length > 0) {
                                for (let productIndex = 0; productIndex < productIds.length; productIndex++) {
                                    const productId = productIds[productIndex];

                                    const cellId = unique + '_' + productId;
                                    if (!graphs[graphType].cells[cellId]) {
                                        graphs[graphType].cells[cellId] = [];
                                    }

                                    graphs[graphType].cells[cellId].push({
                                        product_id: productId,
                                        measurementType: measurementType,
                                        queryTemplate: queryTemplate
                                    });
                                }
                            }
                        }

                    }
                }
            }
        }

        let newTemplate = {
            type: 'DASHBOARD',
            object: 'JOB_TYPE',
            access: 'PROTECTED',
            description: '',
            content: {
                style: {
                    color1: '#031859',
                    color2: '#F23D3D'
                },
                cells: []
            },
            ...defaults.template
        }

        if (defaults.dashboard) {
            newTemplate = helper.mergeObjects(newTemplate, defaults.dashboard);
        }

        const getCellTypeById = function (id) {
            for (let cellTypeIndex = 0; cellTypeIndex < cellTypes.length; cellTypeIndex++) {
                const cellType = cellTypes[cellTypeIndex];
                if (cellType.id == id) {
                    return cellType;
                }
            }

            return null;
        }

        const getNewCell = function (cellType, defaults, xPos, yPos, cardIndex) {
            let newCell = {};
            newCell.typeId = cellType.id;
            newCell.w = cellType.w;
            newCell.h = cellType.h;
            newCell.i = cardIndex;
            newCell.x = xPos;
            newCell.y = yPos;
            newCell.title = '';
            newCell.active = false;
            newCell.settings = (cellType.settings ? Object.assign({}, cellType.settings) : {});

            newCell = helper.mergeObjects(newCell, defaults);

            return newCell;
        }

        let cardIndex = 0;
        let xPos = 0;
        let yPos = 0;

        const maxCols = 12;
        let maxH = 0;

        for (const graphType in graphs) {
            if (Object.hasOwnProperty.call(graphs, graphType)) {
                const config = graphs[graphType];
                const cellType = getCellTypeById(graphType);

                if (config.cells) {
                    for (const unique in config.cells) {
                        if (Object.hasOwnProperty.call(config.cells, unique)) {
                            const cells = config.cells[unique];

                            const measurementType = cells[0].measurementType;

                            if ((xPos + cellType.w) > maxCols) {
                                xPos = 0;
                                yPos = yPos + maxH;
                            }

                            let queries = [];
                            let colors = [];

                            const baseColor = measurementType.unit ? measurementType.unit.color : null;

                            for (let i = 0; i < cells.length; i++) {
                                const cell = cells[i];

                                let graph_defaults = {};
                                if (defaults && defaults.cellTypes && defaults.cellTypes[cellType.id]) {
                                    graph_defaults = defaults.cellTypes[cellType.id];
                                }

                                const color = baseColor ? helper.increaseBrightness(baseColor, i * 25) : null;
                                const properties = helper.mergeObjects({
                                    id: cell.queryTemplate.id,
                                    name: cell.measurementType.name,
                                    measurement_type_id: cell.measurementType.id,
                                    isWritable: cell.measurementType.isWritable,
                                    product_id: cell.product_id?cell.product_id:null,
                                    settings: {
                                        color: color ? color : ''
                                    }
                                }, graph_defaults);

                                if(graphType == 'graph_tile' && cell.variable_id){
                                    properties.filters = [
                                        {
                                            field: {
                                                endpoint: 'resolveProducts',
                                                key: 'product_id',
                                                title: 'Toestel'
                                            },
                                            operator: '=',
                                            value: {
                                                isVariable: true,
                                                id: cell.variable_id
                                            }
                                        }
                                    ]
                                }

                                queries.push(properties);

                                if (color) {
                                    colors.push(color);
                                }
                            }

                            const cellName = config.groupAll ? null : (config.groupByUnit ? (measurementType.unit ? measurementType.unit.name : measurementType.name) : measurementType.name + (measurementType.unit && measurementType.unit.fields && measurementType.unit.fields[2]?' ('+measurementType.unit.fields[2].name+')':''));
                            const cellData = {
                                title: cellName,
                                settings: {
                                    queries: queries,
                                    options: {
                                        color: colors
                                    }
                                }
                            };

                            if(graphType == 'graph_tile'){
                                const cell = cells[0];
                                cellData.settings.request = {
                                    isWritable: measurementType.isWritable,
                                    project_id: {isVariable:true, id: 'project_id'},
                                    job_type_id: {isVariable:true, id: 'job_type_id'},
                                    product_id: cell.variable_id?{isVariable:true, id: cell.variable_id}:(cell.product_id?{id: cell.product_id}:null),
                                    owner_id: {isVariable:true, id: 'owner_id'},
                                }
                            }

                            const newCell = getNewCell(cellType, cellData, xPos, yPos, cardIndex);

                            newTemplate.content.cells.push(newCell);

                            if (cellType.h > maxH) {
                                maxH = cellType.h;
                            }

                            xPos = xPos + cellType.w;

                            cardIndex++;
                        }
                    }
                }
            }
        }

        return newTemplate;
    },
    generateJobTypeDashboard(jobTypeId, cellTypes, defaults) {

        return this.getById('jobType', jobTypeId, ['id', 'name', 'templates{id name type content}'])
            .then(response => {
                const jobType = response.data.data.jobType;
                defaults.template = {
                    name: jobType.name
                }
                return this.generateFormTemplateDashboard(jobType, cellTypes, defaults);
            });
    },
    getTemplateByType(templates, type) {
        if (templates) {
            for (let i = 0; i < templates.length; i++) {
                const template = templates[i];
                if (template.type == type) {
                    return template;
                }
            }
        }
        return null;
    },
    getDefaultJobFilters() {
        const me = this.getUser();

        let filters = [];

        const proposedMaxDate = moment().subtract(2, 'weeks');

        let proposedJobsFilter = {
            "config": {
                "icon": "mdi-calendar",
                "type": "property",
                "label": "Planningsdatum",
                "filter": "dueRequestDate",
                "operator": ">",
                "selected": false,
            },
            "result": {
                "id": proposedMaxDate.startOf('day').toISOString(),
                "selected": false,
                "search_title": '> ' + proposedMaxDate.format('YYYY-MM-DD')
            }
        };

        filters.push({
            value: "proposed_tasks",
            name: 'Voorgestelde taken',
            content: {
                "filter": [proposedJobsFilter]
            }
        });

        filters.push({
            value: null,
            name: 'Alle taken',
            content: {
                filter: []
            }
        });

        let myJobsFilter = {
            "config": {
                "icon": "mdi-account-outline",
                "type": "relation",
                "label": "Verantwoordelijke",
                "filter": "responsibleUsers",
                "selected": false,
                "settings": {
                    "title": "{firstName} {lastName} ({username})",
                    "fields": ["firstName", "lastName", "username"],
                    "module": "searchUsers",
                    "options": {
                        "enabled": true
                    }
                }
            },
            "result": {
                "id": me.id,
                "lastName": me.lastName,
                "selected": false,
                "username": me.username,
                "firstName": me.firstName,
                "search_title": me.getFullName()
            }
        };

        filters.push({
            value: "my_tasks",
            name: 'Mijn taken',
            content: {
                "filter": [myJobsFilter]
            }
        });

        let my_tasks_and_team = [myJobsFilter];

        let team_filter = [];
        if (me.groups) {
            for (let i = 0; i < me.groups.length; i++) {
                const group = me.groups[i];
                const team_filter_content = {
                    "config": {
                        "icon": "mdi-account-group-outline",
                        "type": "module",
                        "label": "Team",
                        "filter": "responsibleGroupId",
                        "selected": false,
                        "settings": {
                            "title": "{name}",
                            "fields": [
                                "name"
                            ],
                            "module": "groups"
                        }
                    },
                    "result": {
                        "id": group.id,
                        "name": group.name,
                        "selected": false,
                        "search_title": group.name
                    }
                };

                team_filter.push(team_filter_content);
                my_tasks_and_team.push(team_filter_content);
            }

            filters.push({
                value: "my_team",
                name: 'Mijn team',
                content: {
                    "filter": team_filter
                }
            });

            // Filter doesn't work, filters jobs assigned to self 'AND' his team instead of 'OR' his team
            /*filters.push({
                value: "my_tasks_and_team",
                name: 'Mijn taken en taken van mijn team(s)',
                content: {
                    "filter": my_tasks_and_team
                }
            });*/
        }

        return filters;
    },
    duplicate(objectname, id, fields){

        if(!fields){
            fields = ['id'];
        }

        const methodname = 'duplicate' + objectname;
        const inputtype = 'ID!';
        const inputname = 'id';

        let query = 'mutation ' + methodname + '($input: ' + inputtype + ') {' + methodname + '(' + inputname + ': $input){' + fields.join(" ") + '} }';

        let variables = {
            input: id
        }

        return this.graphql(query, variables);
    },
    duplicateResponses(workflow, fromJobId, toJobId) {
        return this.get('responses', ['id reportedAt comments{organizationId userId content images createdAt} timetrackings{organizationId userId startedAt comment duration isInvoicable workTypeId} metrics{cellId productId value field comment message images measurementTypeId}'], {
            where: [{
                column: 'jobId',
                operator: '=',
                value: fromJobId
            }]
        }).then(response => {
            if (response.data.data.responses && response.data.data.responses.edges) {
                return response.data.data.responses.edges;
            }

            return [];
        }).then(async responses => {
            for (let i = 0; i < responses.length; i++) {
                const existing_response = responses[i];
                let response = {
                    isActive: true,
                    taskId: workflow.id,
                    jobId: toJobId,
                    userId: this.getUser().id,
                    statusCode: 'in_progress',
                    isProcessed: false,
                    metrics: existing_response.metrics,
                    source: helper.getSystemInfo(),
                    timetrackings: existing_response.timetrackings,
                    comments: existing_response.comments,
                    reportedAt: existing_response.reportedAt,
                    products: [],
                    productTypes: [],
                };

                await this.save('Response', response);
            }

            return true;
        });
    },
    duplicateTemplate(endpoint, itemId) {
        return this.getById(endpoint, itemId, ['templates{name description type object content}'])
            .then(async response => {
                if (response.data.data[endpoint]) {
                    const item = response.data.data[endpoint];
                    if (item.templates && item.templates[0]) {
                        return helper.getTemplateByType(item.templates, 'FORM');
                    }
                }
            });
    },
    async rejectWorkflow(taskId){
        const result = await this.update('RejectTask', {
            id: taskId
        }, {
            methodname: 'rejectTask',
        });

        if (result.data.data && result.data.data.rejectTask) {
            const workflow = result.data.data.rejectTask;
            return workflow;
        }

        return null;
    },
    async submitWorkflow(taskId, options) {

        const result = await this.update('SubmitTask', {
            id: taskId,
            ...options
        }, {
            methodname: 'submitTask',
            returnfields: ['id', 'stepType', 'estimatedDuration', 'dueBeforeDate','dueAfterDate', 'name', 'category', 'jobType{id}']
        });

        if (result.data.data && result.data.data.submitTask) {
            const newJob = result.data.data.submitTask;

            return newJob;
        }

        return null;
    },
    async saveWorkflow(data, options) {

        const result = await this.update('SaveTask', {
            data: data,
        }, {
            methodname: 'saveTask',
            returnfields: ['id', 'stepType', 'estimatedDuration', 'dueBeforeDate','dueAfterDate', 'name', 'category', 'jobType{id}'],
            ...options,
        });

        if (result.data.data && result.data.data.saveTask) {
            return result.data.data.saveTask;
        }

        return null;
    },
    clearCache(){
        return axios.post(this.graphql_url, {
            clearCache: true
        });
    },
    workflowReturnToPlan(workflowId) {
        return this.getById('task', workflowId, ['id', 'name', 'stepId', 'templates{id type content}', 'company{id}', 'project{id}', 'service{id}', 'taskType{id}'])
            .then(async response => {
                const workflow = new Task(response.data.data.task);

                if (!workflow.templates || (workflow.templates && !workflow.templates[0])) {
                    return false;
                }

                const template = helper.getTemplateByType(workflow.templates, 'WORKFLOW');
                const activePage = template.getPageInfo(workflow.stepId);

                for (let i = activePage.pageIndex; i > 0; i--) {
                    const page = template.content.pages[i];
                    for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                        const cell = page.cells[cellIndex];
                        if (cell.settings.type && cell.settings.type == 'plan') {

                            const newData = {
                                id: cell.jobId,
                                statusCode: 'to_do',
                                isJobActive: true
                            };

                            //If smart planning module disabled, delete users and time
                            const optimize = this.getOrganizationSetting('planning.optimizeWaypoints').value;
                            if(optimize != 1){
                                newData.responsibleUsers = [];
                                newData.dueAfterDate = null;
                                newData.dueBeforeDate = null;
                            }
                            
                            this.save('Task', newData);

                            template.setCellValue(cell.id, 'active', true);
                            template.setCellValue(cell.id, 'finished', false);

                            workflow.stepId = page.id;
                            workflow.stepName = page.name;
                            workflow.dueBeforeDate = null;
                            workflow.dueAfterDate = null;
                            workflow.statusCode = 'in_progress';

                            await workflow.save();

                            return {
                                cell: cell
                            }
                        } else {

                            if (cell.jobId) {
                                await this.delete('Task', cell.jobId);
                                template.setCellValue(cell.id, 'jobId', null);
                            }

                            template.setCellValue(cell.id, 'active', false);
                            template.setCellValue(cell.id, 'finished', false);
                        }
                    }
                }

                return null;


            });
    },
};