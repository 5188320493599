<template>
    <eod-dialog :inset="false" hide-actions icon="mdi-package-variant" okText="Sluiten" iconColor="primary" title="Asset" :value="value" @input="val => $emit('input', val)">
        <template v-slot:headerActions>
                <v-btn @click="$emit('input', false)" icon><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <eod-products-list :value="products"></eod-products-list>
            <template v-if="productTypes && productTypes.length > 0">
                <div class="px-4 primary lighten-5 py-2">Assettypes</div>
                <eod-productTypes-list :value="productTypes"></eod-productTypes-list>
            </template>
    </eod-dialog>
</template>
<script>
import eodProductsList from './eod-products-list';
import eodProductTypesList from './eod-productTypes-list';
import eodDialog from './eod-dialog.vue';

export default {
    name: 'eod-products-dialog',
    components:{
        eodProductsList,
        eodProductTypesList,
        eodDialog
    },
    props:{
        value: Boolean,
        products: Array,
        productTypes: Array,
    }
}
</script>