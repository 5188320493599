<template>
    <div>
        <v-row>
            <v-col cols="12" :md="!isAdvanced?12:8">
                <eod-card :title="compact?null:'Dienst'" :outlined="outlined">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <eod-text-field :value="value.name" @input="val => updateProperty('name', val)"
                                    :rules="nameRules" label="Naam*" required outlined hide-details></eod-text-field>
                            </v-col>
                            <v-col>
                                <eod-text-field :value="value.description" @input="val => updateProperty('description', val)"
                                    label="Omschrijving" outlined hide-details></eod-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <eod-object-autocomplete :rules="objectRules"
                                    :fields="['id', 'name', 'periodicityCode']" required return-object
                                    endpoint="serviceTypes" label="Type dienst*" :value="value.serviceTypeId"
                                    @input="serviceTypeChanged"></eod-object-autocomplete>
                            </v-col>
                            <v-col v-if="!hideCompany">
                                <eod-object-autocomplete :rules="objectRules" required endpoint="companies"
                                    label="Bedrijf*" :value="value.companyId"
                                    @input="val => updateProperty('companyId', val)"></eod-object-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <eod-text-field type="number" label="Afstand (in km)" required :value="value.distance" @input="val => updateProperty('distance', val)"></eod-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!isAdvanced" @click="isAdvanced = true" text small>Geavanceerd <v-icon right small>mdi-chevron-down</v-icon></v-btn>
                </v-row>
                    </v-card-text>
                </eod-card>
                <eod-card class="mt-4" title="Periode" :outlined="outlined">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-menu ref="startDateMenu" v-model="datepickers.startDate" transition="scale-transition"
                                    offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <eod-text-field :value="value.startDateFormatted"
                                            @input="val => updateProperty('startDateFormatted', val)" label="Begindatum"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined
                                            hide-details></eod-text-field>
                                    </template>
                                    <v-date-picker :value="value.startDateFormatted"
                                        @input="(val) => { updateProperty('startDateFormatted', val); datepickers.startDate = false; }"
                                        no-title scrollable></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu ref="endDateMenu" v-model="datepickers.endDate" transition="scale-transition"
                                    offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <eod-text-field :value="value.endDateFormatted"
                                            @input="val => updateProperty('endDateFormatted', val)" label="Einddatum"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined
                                            hide-details></eod-text-field>
                                    </template>
                                    <v-date-picker :value="value.endDateFormatted"
                                        @input="(val) => { updateProperty('endDateFormatted', val); datepickers.endDate = false; }"
                                        no-title scrollable></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <eod-autocomplete :items="settings.periodicity" :value="value.periodicityCode"
                                    @input="val => updateProperty('periodicityCode', val)" label="Herhaling"></eod-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </eod-card>
            </v-col>
            <v-col v-if="isAdvanced">
                <eod-card class="mb-4" :outlined="outlined" title="Tags">
                    <v-card-text>
                        <eod-tags deletable addable :value="value.tags"
                            @input="val => updateProperty('tags', val)"></eod-tags>
                    </v-card-text>
                </eod-card>
                <eod-card :outlined="outlined" title="Notities">
                    <v-card-text>
                        <v-textarea rows="6" outlined hide-details :value="value.notes"
                            @input="val => updateProperty('notes', val)"></v-textarea>
                    </v-card-text>
                </eod-card>
                <eod-card class="mt-4" title="Integraties IDs">
                            <v-card-text>
                                <eod-integration-ids v-if="value.id" :itemId="value.id" endpoint="services"></eod-integration-ids>
                                <v-alert v-else type="info" text>Gelieve eerst te bewaren</v-alert>
                            </v-card-text>
                        </eod-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import eodTags from './eod-tags.vue';
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodIntegrationIds from './eod-integration-ids.vue';

export default {
    name: 'eod-service-detail',
    components: {
        eodTags,
        eodObjectAutocomplete,
        eodIntegrationIds
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        compact: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        hideCompany: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            item: {},
            isAdvanced: false,
            nameRules: [
                v => !!v || 'Naam is verplicht',
            ],
            objectRules: [
                v => !!v || 'Item selecteren is verplicht',
            ],
            serviceTypes: [],
            datepickers: {
                startDate: false,
                endDate: false,
            },
            settings: require('./../classes/settings'),
        }
    },
    mounted() {
        if (this.value) {
            this.init();
        }
    },
    created(){
        this.isAdvanced = !this.compact;
    },
    watch: {
        value(newVal) {
            if (newVal) {
                this.init();
            }
        }
    },
    methods: {
        async init() {
            let item = this.value;

            if (!item.id) {
                let periodicityCode = null;

                const setting = this.$eod.getOrganizationSetting('services.periodicity.default');
                if (setting) {
                    periodicityCode = setting.value;
                }

                const defaultServiceTypeSetting = this.$eod.getOrganizationSetting('services.types.default');
                if (defaultServiceTypeSetting && defaultServiceTypeSetting.value) {

                    item.serviceTypeId = defaultServiceTypeSetting.value;

                    const defaultServiceType = await this.$eod.getById('serviceType', defaultServiceTypeSetting.value, ['id', 'name', 'periodicityCode'])
                        .then(response => {
                            return response.data.data.serviceType;
                        });

                    if (defaultServiceType.periodicityCode) {
                        periodicityCode = defaultServiceType.periodicityCode;
                    }
                }

                item.periodicityCode = periodicityCode;

                let now = Date.now();
                item.startDateFormatted = this.$moment(now).format('YYYY-MM-DD');

                if (periodicityCode) {
                    const newDate = this.$eod.getDateByPeriodicityCode(periodicityCode, now);
                    if (newDate) {
                        item.endDateFormatted = newDate.format('YYYY-MM-DD');
                    }
                }

                this.$emit('input', item);

                this.$nextTick(() => {
                    this.$forceUpdate();
                });

            }
        },
        serviceTypeChanged(serviceType) {
            this.updateProperty('serviceTypeId', serviceType.id);
        },
        updateProperty(propertyName, propertyValue) {
            let item = this.value;
            if (item[propertyName] !== propertyValue) {
                item[propertyName] = propertyValue;
                this.$emit('input', item);
            }

        },
    }
}
</script>