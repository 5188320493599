<template>
    <div>
        <header>
            <slot name="header">
                <v-toolbar flat color="transparent">
                    <v-btn icon @click="cancelItem">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>

                    <v-toolbar-title v-if="template">{{ template.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-alert dense type="warning" v-if="showSaveAlert" class="mr-3">Er zijn onopgeslagen
                        wijzigingen</v-alert>
                    <v-select @change="languageChanged" v-if="languages" v-model="language" :items="languages" label="Taal" dense solo rounded class="mr-3"
                        style="max-width:300px;" hide-details></v-select>
                    <form method="post" :action="$eod.api_url + '/report/download'" v-if="template" class="mr-3">
                        <input v-if="language" type="hidden" name="lang" :value="language" />
                        <input type="hidden" name="template" :value="JSON.stringify(template)" />
                        <input type="hidden" name="bearerToken" :value="$keycloak.token" />
                        <v-btn type="submit" color="secondary" rounded>
                            <v-icon class="mr-2">mdi-download</v-icon>Download
                        </v-btn>
                    </form>
                    <v-btn color="secondary" rounded @click="$emit('save')">
                        <v-icon class="mr-2">mdi-content-save</v-icon>Opslaan
                    </v-btn>
                    <template v-slot:extension v-if="tabs.length > 0">
                        <div class="v-toolbar-extension-content">
                            <v-tabs v-model="main_tab" background-color="transparent" show-arrows
                                @change="mainTabChanged">
                                <v-tab v-for="tabitem in tabs" :key="tabitem.tab">
                                    <span>{{ tabitem.text }}</span>
                                </v-tab>
                            </v-tabs>
                        </div>
                    </template>
                </v-toolbar>
            </slot>
        </header>
        <v-container fluid>
            <main>
                <v-tabs-items v-model="main_tab">
                    <v-tab-item key="editor" transition="fade-transition" reverse-transition="fade-transition">
                        <v-row class="flex-nowrap">
                            <v-col sm="8">
                                <v-card v-if="template" class="report-editor" :key="editorUpdateKey">
                                    <v-card-text>
                                        <v-tabs v-model="activePage">
                                            <draggable v-model="template.content.pages" group="pages"
                                                class="v-slide-group__content v-tabs-bar__content" @update="tabUpdate">
                                                <v-tab @click="showPageSettings"
                                                    v-for="(page, index) in template.content.pages" :key="index">
                                                    {{ page.name }}
                                                </v-tab>
                                            </draggable>
                                            <v-btn text class="align-self-center mr-4" @click="addPage">
                                                <v-icon left>mdi-plus</v-icon>
                                                Sectie
                                            </v-btn>
                                        </v-tabs>
                                        <v-tabs-items v-model="activePage">
                                            <v-tab-item v-for="(page, index) in template.content.pages" :key="index"
                                                transition="fade-transition" reverse-transition="fade-transition">
                                                <v-list>
                                                    <draggable v-model="template.content.pages[index].cells"
                                                        group="form" style="min-height:100px;" @add="componentAdded">
                                                        <component
                                                            :ref="'component_' + activeTemplate + '_' + index + '_' + cellIndex"
                                                            v-for="(cell, cellIndex) in template.content.pages[index].cells"
                                                            :key="cell.id"
                                                            v-model="template.content.pages[index].cells[cellIndex]"
                                                            :is="getComponentByType(template.content.pages[index].cells[cellIndex].typeId).viewComponent"
                                                            @delete="deleteItem(cellIndex)"
                                                            @showSettings="showSettings(cellIndex)"></component>
                                                    </draggable>
                                                </v-list>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col sm="4">
                                <v-card ref="settingsPanel" id="settingsPanel">
                                    <v-card-title>
                                        <v-tabs v-model="tab">
                                            <v-tab key="components">Componenten</v-tab>
                                            <v-tab v-if="getActiveCell() != null || getActivePage() != null"
                                                key="settings">Instellingen</v-tab>
                                        </v-tabs>
                                    </v-card-title>
                                    <div class="scroll">
                                        <v-card-text>
                                            <v-tabs-items v-model="tab">
                                                <v-tab-item key="components" transition="fade-transition"
                                                    reverse-transition="fade-transition">
                                                    <draggable v-model="components"
                                                        :group="{ name: 'form', pull: 'clone', put: false }"
                                                        :sort="false" :clone="clone">

                                                        <v-card class="mb-2" v-for="component in components"
                                                            :key="component.id" flat outlined>
                                                            <v-card-subtitle v-html="component.name"></v-card-subtitle>
                                                        </v-card>

                                                    </draggable>
                                                </v-tab-item>
                                                <v-tab-item v-if="getActiveCell() != null" key="settings"
                                                    transition="fade-transition" reverse-transition="fade-transition">
                                                    <component :response="item" :template="template" ref="settingsTab"
                                                        @change="reloadActiveItem" @update="updateActiveItem"
                                                        v-if="template.content.pages[activePage].cells[getActiveCell()]"
                                                        v-model="template.content.pages[activePage].cells[getActiveCell()]"
                                                        :is="getComponentByType(template.content.pages[activePage].cells[getActiveCell()].typeId).configurationComponent">
                                                    </component>
                                                </v-tab-item>
                                                <v-tab-item v-if="getActiveCell() == null && getActivePage() != null"
                                                    key="settings" transition="fade-transition"
                                                    reverse-transition="fade-transition">
                                                    <template v-if="template">
                                                        <v-text-field dense outlined label="Titel"
                                                            v-model="template.content.pages[activePage].name"></v-text-field>
                                                        <v-textarea dense outlined label="Tekst"
                                                            v-model="template.content.pages[activePage].description"></v-textarea>
                                                        <v-btn @click="deleteStep(activePage)" color="error"><v-icon
                                                                left>mdi-trash-can-outline</v-icon>Verwijderen</v-btn>
                                                    </template>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-card-text>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item key="preview" transition="fade-transition" reverse-transition="fade-transition">
                        <v-card v-if="template">
                            <v-card-text style="height:calc(100vh - 270px);position:relative;">
                                <form ref="preview_form" target="preview_frame" method="post" :action="$eod.api_url + '/report/preview#toolbar=0'">
                                    <input v-if="language" type="hidden" name="lang" :value="language" />
                                    <input type="hidden" name="template" :value="JSON.stringify(template)" />
                                    <input type="hidden" name="bearerToken" :value="$keycloak.token" />
                                </form>
                                <iframe v-show="!previewLoading" ref="preview_frame" name="preview_frame" @load="previewLoading = false" frameborder="0" style="width:100%;height:100%;"></iframe>
                                    <div v-if="previewLoading" class="d-flex justify-content-center align-items-center">
                                <eod-loader :value="true" text="Bezig met genereren..."></eod-loader> 
                            </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

            </main>
        </v-container>

    </div>
</template>
<style lang="scss" scoped>
.v-alert {
    margin-bottom: 0;
}

#settingsPanel {
    max-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 70px;

    .scroll {
        flex-grow: 1;
        overflow-y: auto;
    }
}
</style>
<script>
import Draggable from 'vuedraggable';
import { v4 as uuidv4 } from 'uuid';
import eodLoader from './eod-loader.vue';

export default {
    components: {
        Draggable,
        eodLoader
    },
    props: {
        showRangePicker: {
            type: Boolean,
            default: false
        },
        rangePickerRangesFrom: {
            type: Array,
            default: () => ([])
        },
        rangePickerRangesUntil: {
            type: Array,
            default: () => ([])
        },
        components: Array,
        value: Object,
        config: Object,
    },
    async mounted() {
        await this.$translator.loadTranslations();
        this.template = this.value;
    },
    watch: {
        templates: {
            deep: true,
            handler() {
                if (this.isLoaded && !this.showSaveAlert) {
                    this.showSaveAlert = true;
                }
            }
        }
    },
    data: () => ({
        template: null,
        tab: 'components',
        main_tab: 'editor',
        tabs: [
            {
                text: 'Inhoud',
                tab: 'editor'
            },
            {
                text: 'Voorbeeld',
                tab: 'preview',
            }
        ],
        previewLoading: false,
        item: null,
        activePage: 0,
        activeTemplate: null,
        measurementItems: [],
        templates: [],
        isLoaded: false,
        showSaveAlert: false,
        language: 'nl',
        languages: null,
        editorUpdateKey: 0
    }),
    created() {
        this.languages = this.$eod.getLanguages();
        this.language = this.$eod.getDefaultLanguage().value;
    },
    methods: {
        mainTabChanged() {
            this.renderPreview();
        },
        async languageChanged(lang) {
            await this.$translator.loadTranslations(true);
            this.$translator.setLocale(lang);
            this.editorUpdateKey++;
            this.renderPreview();
        },
        renderPreview() {
            if (this.tabs[this.main_tab].tab == 'preview') {
                setTimeout(() => {
                    this.$refs.preview_form.submit();
                    this.previewLoading = true;
                }, 200);
            }
        },
        deleteStep(index) {
            this.template.content.pages.splice(index, 1);
            this.activePage = 0;
            this.$emit('input', this.template);
            this.$forceUpdate();
        },
        showPageSettings() {
            this.clearActive();
            this.tab = 1;
        },
        scrollablePanel() {
            let panel = this.$refs.settingsPanel.$el;
            panel.style.transition = "all 0.1s";
            let originalTop = 140; //panel.getBoundingClientRect()

            setInterval(() => {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                if (scrollTop > originalTop) {
                    panel.style.marginTop = (scrollTop - originalTop) + 'px';
                } else {
                    panel.style.marginTop = 0;
                }
            }, 100);
        },
        reloadActiveItem(evt) {
            this.$refs['component_' + this.activeTemplate + '_' + this.activePage + '_' + this.getActiveCell()][0].reload(evt);
        },
        updateActiveItem(evt) {
            this.$refs['component_' + this.activeTemplate + '_' + this.activePage + '_' + this.getActiveCell()][0].update(evt);
        },
        getActivePage() {
            return this.activePage;
        },
        getActiveCell() {

            if (!this.template) {
                return null;
            }

            if (!(this.template.content.pages && this.template.content.pages[this.activePage])) {
                return null;
            }

            let activePage = this.template.content.pages[this.activePage];

            for (let i = 0; i < activePage.cells.length; i++) {
                const cell = activePage.cells[i];
                if (cell.active) {
                    return i;
                }
            }

            return null;
        },
        tabUpdate(evt) {
            let tabNumber = this.activePage // The active tab number before udpate
            let oldIndex = evt.oldIndex; // Old index number of tab we are moving
            let newIndex = evt.newIndex; // New index number of tab we are moving
            let tabActive = null; // The new tab which can be set as active tab

            if (tabNumber === oldIndex) {
                tabActive = newIndex;
            } else if (tabNumber === newIndex && tabNumber < oldIndex) {
                tabActive = tabNumber + 1;
            } else if (tabNumber === newIndex && tabNumber > oldIndex) {
                tabActive = tabNumber - 1;
            } else if (tabNumber < oldIndex) {
                tabActive = tabNumber + 1;
            } else if (tabNumber > oldIndex) {
                tabActive = tabNumber - 1;
            }
            this.activePage = tabActive;
        },
        clone(component) {
            return {
                typeId: component.id,
                name: component.name,
                settings: component.settings ? component.name : {}
            }
        },
        componentAdded(evt) {
            this.showSettings(evt.newIndex);
        },
        getComponentByType(typeId) {
            for (let i = 0; i < this.components.length; i++) {
                const component = this.components[i];
                if (component.id == typeId) {
                    return component;
                }
            }

            return null;
        },
        deleteItem(cellIndex) {
            this.template.content.pages[this.activePage].cells.splice(cellIndex, 1);
            this.$emit('input', this.template);
        },
        showSettings(cellIndex) {
            this.setActive(cellIndex);
            this.tab = 1;

            this.$forceUpdate();

            this.$nextTick(() => {
                if (this.$refs['settingsTab'] && this.$refs['settingsTab'].init) {
                    this.$refs['settingsTab'].init();
                }
            });

            
        },
        setActive(cellIndex) {
            this.clearActive();
            this.template.content.pages[this.activePage].cells[cellIndex].active = true;
        },
        clearActive() {
            let activePage = this.template.content.pages[this.activePage];

            for (let i = 0; i < activePage.cells.length; i++) {
                this.template.content.pages[this.activePage].cells[i].active = false;
            }
        },
        addPage() {
            this.template.content.pages.push({
                id: uuidv4(),
                type: 'page',
                name: 'Sectie',
                cells: []
            });
            this.$emit('input', this.template);
        },
        cancelItem() {
            this.$router.go(-1);
        }
    }
}
</script>