<template>
  <v-app :style="appStyle">
    <v-app-bar app dense color="white" clipped-left clipped-right>

      <v-btn icon @click.stop="drawer = !drawer">
        <v-img src="./assets/img/logo-eye-red.svg" width="40"></v-img>
      </v-btn>
      <v-toolbar-title class="d-sm-flex align-items-center d-none">
        <img alt="Eye On Data Logo" src="./assets/img/logo-name-blue.svg" width="140" />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="searchbar.show" class="px-4 w-100">
        <eod-search ref="searchbar" v-model="searchbar.filters" flat topbar
          @update="value => $root.$emit('searchUpdated', value)" :config="searchbar.config.search" :readonly="searchbar.readonly"
          :objectType="searchbar.config.objectType"
          :templateType="searchbar.config.searchTemplateType ? searchbar.config.searchTemplateType : 'FILTER'"
          :prependFilterItems="searchbar.prependFilterItems" :defaultFilter="searchbar.defaultFilter"></eod-search>
      </div>
      <v-spacer></v-spacer>
      <eod-time-selector v-if="timeSelector.show" ref="timeSelector" v-model="selectedTime"
        :single-date="timeSelector.singleDate" :arrows="timeSelector.showArrows" :hide-clear="timeSelector.hideClear"
        @input="val => $root.$emit('timeSelectorChanged', val)"></eod-time-selector>
        <v-spacer class="d-block d-sm-none"></v-spacer>
      <v-menu offset-y
        v-if="$eod.getUser().can('import') || $eod.getUser().can('export') || $eod.getUser().can('organization.settings.create') || $eod.getUser().can('organization.settings.read')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon size="28">mdi-cogs</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-if="$eod.getUser().can('import')" @click="showImport = true">
            <v-list-item-icon><v-icon>mdi-database-import</v-icon></v-list-item-icon>
            <v-list-item-title>Importeren</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$eod.getUser().can('export')" @click="showExport = true">
            <v-list-item-icon><v-icon>mdi-database-export</v-icon></v-list-item-icon>
            <v-list-item-title>Exporteren</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$eod.getUser().can('organization.settings.create')" @click="showExportBackup = true">
            <v-list-item-icon><v-icon>mdi-database-export</v-icon></v-list-item-icon>
            <v-list-item-title>Backup</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$eod.getUser().can('organization.settings.create')" @click="showMessage = true">
            <v-list-item-icon><v-icon>mdi-email-edit-outline</v-icon></v-list-item-icon>
            <v-list-item-title>Bericht versturen</v-list-item-title>
          </v-list-item>
          <v-list-item @click="clearCache">
            <v-list-item-icon><v-icon>mdi-cached</v-icon></v-list-item-icon>
            <v-list-item-title>Cache legen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon @click="showHelp = !showHelp">
        <v-icon size="28">mdi-help-circle-outline</v-icon>
      </v-btn>
      <v-menu offset-y bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-chip class="pr-6" v-bind="attrs" v-on="on" color="primary">
            <span class="d-block d-sm-none">{{ $eod.getUser().getInitials().toUpperCase() }}</span>
            <span  class="d-none d-sm-block">{{ $eod.getUser().getFullName() }}</span>
            <v-icon small right>mdi-chevron-down</v-icon>
          </v-chip>
        </template>
        <v-card>
          <v-list dense>
            <v-list-item v-bind="link_attributes(item)" @click="item.click ? item.click() : null"
              v-for="(item, i) in profile_menu" :key="i">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn @click="reload" icon><v-icon>mdi-refresh</v-icon></v-btn><v-spacer></v-spacer>
            <div>v. {{ version }}</div>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer class="main-navigation-drawer" app v-model="drawer" fixed dark expand-on-hover clipped
      mini-variant>
      <v-list dense flat>
        <template v-for="item in menu">
          <v-list-item v-if="item.heading" :key="item.heading">
            <v-list-item-content>
              <v-list-item-subtitle>{{ item.heading }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <template v-else-if="item.children">
            <v-list-item :key="item.text" :to="item.link">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon @click="item.model = !item.model" style="transition:.2s all;"
                  :style="item.model ? 'transform:rotate(180deg);' : ''">mdi-chevron-down</v-icon>
              </v-list-item-action>
            </v-list-item>
            <div v-if="item.model" :key="'children_' + item.text" style="background-color:var(--v-primary-lighten2);">
              <v-list-item v-bind="link_attributes(child)" v-for="(child, i) in item.children" :key="i">
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-tooltip bottom v-if="child.tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-content v-bind="attrs" v-on="on">
                      <v-list-item-title>
                        {{ child.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span>{{ child.tooltip }}</span>
                </v-tooltip>
                <v-list-item-content v-else>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>

          <v-list-item v-bind="link_attributes(item)" v-else :key="item.text">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view :key="$route.fullPath"></router-view>

      <v-footer color="white" elevation="2" fixed padless style="z-index:4;">
        <div class="w-100">
          <div class="w-100 py-1 text-center">
            {{ new Date().getFullYear() }} — <span>powered by</span> <a href="https://www.eyeondata.be/" target="_blank"
              class="text-primary decoration-none"><strong>Eye on Data</strong></a>
          </div>

          <eod-events-drawer-collapse v-if="$eod.getUser().can('events.menu')" />
        </div>

      </v-footer>
    </v-main>

    <v-card v-if="showHelp" id="help" class="d-flex">
      <v-toolbar dense color="primary" dark>
        <v-btn depressed icon @click="showHelp = false"><v-icon>mdi-close</v-icon></v-btn>
        <v-btn depressed icon target="_blank" href="https://docs.eyeondata.eu"><v-icon>mdi-launch</v-icon></v-btn>

      </v-toolbar>
      <div class="flex-grow-1 d-flex">
        <iframe src="https://docs.eyeondata.eu"></iframe>
      </div>
    </v-card>

    <v-dialog v-model="updating" persistent max-width="400">
      <v-card>
        <v-progress-linear color="success" indeterminate></v-progress-linear>
        <v-card-title class="headline">
          <v-avatar color="success" size="40" class="elevation-3 mr-4">
            <v-icon dark>mdi-history</v-icon>
          </v-avatar>
          Updaten
        </v-card-title>
        <v-card-text>
          Eye on Data is aan het updaten...
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="restoring" persistent max-width="400">
      <v-card>
        <v-progress-linear color="success" indeterminate></v-progress-linear>
        <v-card-title class="headline">
          <v-avatar color="success" size="40" class="elevation-3 mr-4">
            <v-icon dark>mdi-backup-restore</v-icon>
          </v-avatar>
          Backup terugzetten
        </v-card-title>
        <v-card-text>
          Eye on Data is bezig met het terugplaatsen van een backup...
        </v-card-text>
      </v-card>
    </v-dialog>

    <eod-message-dialog v-if="showMessage" v-model="showMessage"></eod-message-dialog>
    <eod-export-dialog v-if="showExport" v-model="showExport" :filters="searchbar.filters" :time-filters="selectedTime"
      :endpoint="(searchbar.config && searchbar.config.itemObject) ? searchbar.config.itemObject.moduleName : null"></eod-export-dialog>
    <eod-import-dialog v-if="showImport" v-model="showImport"></eod-import-dialog>
    <eod-export-backup-dialog v-if="showExportBackup" v-model="showExportBackup"></eod-export-backup-dialog>

    <v-snackbar right top v-model="isCacheSnackbarVisible" rounded="pill" timeout="5000" color="success"><v-icon left>mdi-cached</v-icon>Cache succesvol geleegd!</v-snackbar>
  </v-app>
</template>
<style lang="scss">
@import './eodvuecomponents/src/assets/scss/app.scss';
</style>
<script>
import { eodExportDialog, eodImportDialog, eodTimeSelector, eodExportBackupDialog, eodSearch, eodEventsDrawerCollapse } from '@/eodvuecomponents';
import eodMessageDialog from './components/messageDialog';

export default {
  name: 'App',
  components: {
    'eod-message-dialog': eodMessageDialog,
    'eod-export-dialog': eodExportDialog,
    'eod-import-dialog': eodImportDialog,
    'eod-time-selector': eodTimeSelector,
    'eod-export-backup-dialog': eodExportBackupDialog,
    'eod-search': eodSearch,
    eodEventsDrawerCollapse
  },
  data: () => ({
    drawer: null,
    selectedTime: null,
    showExportBackup: null,
    updating: false,
    restoring: false,
    restoringInterval: null,
    isCacheSnackbarVisible:false,
    timeSelector: {
      show: false,
      singleDate: false,
      showArrows: false,
      hideClear: false
    },
    searchbar: {
      show: false,
      readonly: false,
      filters: [],
      defaultFilter: null,
      prependFilterItems: [],
      config: {}
    },
    profile_menu: [],
    menu: [],
    showHelp: false,
    showMessage: false,
    showExport: false,
    showImport: false,
    version: process.env.VUE_APP_VERSION,
    appStyle: ''
  }),
  async mounted() {

    this.getAppStyle();

    let organization = this.$eod.getOrganization();

    const user = this.$eod.getUser();

    let tasksStartPage = 'tasks.routes';
    if (user.can('tasks.workflows.menu')) {
      tasksStartPage = 'tasks';
    } else if (user.can('tasks.jobs.menu')) {
      tasksStartPage = 'tasks.jobs';
    } else if (user.can('tasks.planning.menu')) {
      tasksStartPage = 'tasks.planning';
    }

    let dataStartPage = 'queries';
    if(!user.can('queries.menu')){
      dataStartPage = 'exports';
    }

    this.menu = [
      { heading: organization.description },
      { icon: 'mdi-view-dashboard-outline', text: 'Dashboards', link: { name: 'dashboards' }, enabled: user.can('dashboard.menu') },
      {
        icon: 'mdi-clipboard-outline', model: false, text: 'Taken', link: { name: tasksStartPage }, enabled: (user.can('tasks.jobs.menu') || user.can('tasks.workflows.menu') || user.can('tasks.planning.menu') || user.can('routes.menu')),
        children: [
          { icon: 'mdi-form-select', text: 'Jobtypes', link: { name: 'jobTypes' }, enabled: user.can('jobtypes.menu') },
          { icon: 'mdi-sitemap', text: 'Workflows', tooltip: 'Workflow definities', link: { name: 'taskWorkflows' }, enabled: user.can('tasktypes.menu') },
        ]
      },
      { icon: 'mdi-folder-open-outline', text: 'Projecten', link: { name: 'projects' }, enabled: user.can('projects.menu') },
      {
        icon: 'mdi-file-sign', model: false, text: 'Diensten', link: { name: 'services' }, enabled: user.can('services.menu'),
        children: [
          { icon: 'mdi-file-tree', text: 'Types', link: { name: 'serviceTypes' }, enabled: user.can('servicetypes.menu') },
        ]
      },
      { icon: 'mdi-office-building-outline', text: 'Bedrijven', link: { name: 'companies' }, enabled: user.can('companies.menu') },
      { icon: 'mdi-cube-outline', text: 'Assets', link: { name: 'products' }, enabled: user.can('products.devices.menu') },
      { icon: 'mdi-tools', text: 'Gereedschap', link: { name: 'tools' }, enabled: user.can('products.tools.menu') },
      { icon: 'mdi-package-variant', text: 'Artikelen', link: { name: 'articles' }, enabled: user.can('products.articles.menu') },
      { icon: 'mdi-warehouse', text: 'Depots', link: { name: 'depots' }, enabled: user.can('depots.menu') },
      { icon: 'mdi-truck-outline', text: 'Voertuigen', link: { name: 'vehicles' }, enabled: user.can('vehicles.menu') },
      { icon: 'mdi-waveform', text: 'Meetwaardes', link: { name: 'measurementTypes' }, enabled: user.can('measurementtypes.menu') },
      { icon: 'mdi-image-multiple-outline', text: 'Opslag', link: { name: 'storage' }, enabled: user.can('storageobjects.menu') },
      { icon: 'mdi-view-list', text: 'Lijsten', link: { name: 'lists' }, enabled: user.can('lists.menu') },
      { icon: 'mdi-database-outline', model: false, text: 'Data', link:{name:dataStartPage}, enabled: (user.can('queries.menu') || user.can('exports.menu')),
      children: [
        { icon: 'mdi-database-search-outline', text: 'Queries', link: { name: 'queries' }, enabled: user.can('queries.menu')},
        { icon: 'mdi-database-arrow-right-outline', text: 'Exporteren', link: { name: 'exports' }, enabled: user.can('exports.menu')},
      ] },
      { icon: 'mdi-bell-alert-outline', text: 'Alarmen', link: { name: 'alarms' }, enabled: user.can('alarms.menu') },
      { heading: 'Instellingen' , enabled: this.hasOrganizationRights(user)},
      { icon: 'mdi-file-tree-outline', text: 'Organisatie', link: { name: 'organization' }, enabled: this.hasOrganizationRights(user) }
    ];

    this.menu = this.getMenu(this.menu);

    this.profile_menu = [
      { icon: 'mdi-account', text: 'Mijn profiel', link: { name: 'profile' } },
      {
        icon: 'mdi-bell-outline', text: 'Notificaties', link: { name: 'profile', params: { tab: 'notifications' } }
      },
      {
        icon: 'mdi-logout', text: 'Afmelden', click: () => {
          localStorage.removeItem('eyeondata-user');
          this.$keycloak.logout();
        }
      },
    ];

    this.profile_menu = this.getMenu(this.profile_menu);
  },
  methods: {
    clearCache(){
      this.$eod.clearCache().then(() => {
        this.isCacheSnackbarVisible = true;
      });
    },
    hasOrganizationRights(user) {
      return (user.can('organization.general.menu') || user.can('organization.employees.menu') || user.can('organization.contacts.menu') || user.can('organization.responsibles.menu') || user.can('organization.teams.menu') || user.can('organization.integrations.menu') || user.can('organization.settings.menu'));
    },
    async getAppStyle() {
      const primary = await this.$eod.getOrganizationSetting('organisation.color.primary');
      const secondary = await this.$eod.getOrganizationSetting('organisation.color.secondary');

      let style = '';
      if (primary && primary.value) {
        style += '--v-primary-base:' + primary.value + ';';
      }

      if (secondary && secondary.value) {
        style += '--v-secondary-base:' + secondary.value + ';';
        style += '--v-secondary-lighten1:' + secondary.value + ';';
      }

      this.appStyle = style;
    },
    reset() {
      this.selectedTime = null;
      this.timeSelector.show = false;
      this.timeSelector.singleDate = false;
      this.timeSelector.hideClear = false;

      this.searchbar.show = false;
      this.searchbar.readonly = false;
      this.searchbar.defaultFilter = null;
      this.searchbar.filters = [];
      this.searchbar.config = {};
      this.searchbar.prependFilterItems = [];
    },
    async setRestoring(isRestoring) {
      this.restoring = isRestoring;

      if (isRestoring) {
        if (this.restoringInterval) {
          clearInterval(this.restoringInterval);
        }

        this.restoringInterval = setInterval(async () => {
          await this.$eod.loadUserOrganization().then(async () => {
            const setting = await this.$eod.getOrganizationSetting('backup.restore.running');
            if (!setting || (setting && setting.value != 1)) {
              await this.setRestoring(false);
            }
          });

        }, 10000);
      } else {
        clearInterval(this.restoringInterval);
      }
    },
    reload() {
      window.location.reload(true);
    },
    getMenu(menu) {
      let filteredMenu = [];

      for (let i = 0; i < menu.length; i++) {
        const menuItem = menu[i];
        if (menuItem.enabled != false) {
          if(menuItem.children){
            menuItem.children = this.getMenu(menuItem.children);
          }
          filteredMenu.push(menuItem);
        }
      }

      return filteredMenu;
    },
    link_component(item) {
      if (item.click) {
        return 'div';
      } else if (item.href) {
        return 'a';
      } else {
        return 'v-list-item';
      }
    },
    link_attributes(item) {
      if (item.click) {
        return {
          class: 'v-list-item v-list-item--link theme--light'
        }
      } else if (item.href) {
        return {
          href: item.href,
          class: 'v-list-item v-list-item--link theme--light'
        };
      } else {
        return {
          to: item.link
        };
      }
    }
  }
};
</script>
