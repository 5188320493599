<template>
    <v-form>
        <v-card-text>
        <v-tabs v-model="selectedtab">
                <v-tab>Algemeen</v-tab>
                <v-tab>Meetwaardes</v-tab>
            </v-tabs>
        </v-card-text>
            <v-card-text>
            <v-alert v-if="errors" color="error" text>
                <ul>
                    <li v-for="(error, index) in errors" :key="'error_'+index">{{ error }}</li>
                </ul>
                </v-alert>
                <v-tabs-items :value="selectedtab">
                    <v-tab-item transition="expand-transition" reverse-transition="expand-transition">
<eod-text-field
                                :value="value.name"
                                :rules="[val => $validator.required(val, 'Naam')]"
                                @input="val => updateProperty('name', val)"
                                label="Naam*"
                                required
                                outlined
                                hide-details
                            ></eod-text-field>
                            <eod-text-field
                                :value="value.description"
                                label="Omschrijving"
                                @input="val => updateProperty('description', val)"
                                outlined
                                hide-details
                                class="mt-4"
                            ></eod-text-field>
                            <eod-custom-fields-item class="mt-2" :value="value.customFields" @input="val => updateProperty('customFields', val)" endpoint="productTypes" :id="value.id""></eod-custom-fields-item>
                            </v-tab-item>
                            <v-tab-item transition="expand-transition" reverse-transition="expand-transition">
      <eod-measurement-type-indicative :value="value.measurementTypes" @input="val => updateProperty('measurementTypes', val)"></eod-measurement-type-indicative>
    </v-tab-item>
                            </v-tabs-items>
                        </v-card-text>
        </v-form>
</template>
<script lang="js">
import eodMeasurementTypeIndicative from './eod-measurementType-indicative.vue';
import eodCustomFieldsItem from './eod-custom-fields-item.vue';
export default {
    name: 'eod-product-type-detail',
    components:{
        eodMeasurementTypeIndicative,
        eodCustomFieldsItem
    },
    props:{
        value: Object
    },
    data: () => ({
        selectedtab: 0
    }),
    methods:{
        updateProperty(propertyName, value){
            const val = this.value;
            val[propertyName] = value;

            this.$emit('input', val);
        }
    }
}
</script>