<template>
    <v-dialog v-model="value" width="1200" max-width="100%" @click:outside="$emit('input', false)">
    <v-card :loading="isLoading">
        <v-tabs
        fixed-tabs
        background-color="primary"
        dark
        v-model="tab"
    >
    <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab key="task">
            <v-icon left>
                mdi-clipboard-outline
            </v-icon>
            Taak
        </v-tab>
        <v-tab key="media" v-if="showMedia">
            <v-icon left>
                mdi-image-multiple-outline
            </v-icon>
            Media
        </v-tab>
        <v-tab key="plan" v-if="showPlanning && task && task.statusCode">
            <v-icon left>
                mdi-calendar-clock
            </v-icon>
            Plannen
        </v-tab>
        <v-tab key="products" v-if="showProducts && task && task.statusCode">
            <v-icon left>
                mdi-package-variant
            </v-icon>
            Materialen
        </v-tab>
    </v-tabs>
        <v-card-text style="max-height: calc(100vh - 158px);" class="pt-4">
        <v-form ref="newTaskForm">
  <v-tabs-items v-model="tab">
      <v-tab-item key="task">
                  <eod-item-selector v-if="showSelector" class="mb-4" :show="showTaskTree" @showChanged="val => {showTaskTree = val}" :tree="['companies', 'projects', 'tasks']" @input="setTask" :value="selected_tasks" :rules="requiredRules">
                      <template v-slot:prepend-item>
                            <v-divider class="mb-2"></v-divider>
                            <v-list-item @click.stop="createTask">
                                <v-list-item-icon>
                                    <v-icon>mdi-plus</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Nieuwe taak</v-list-item-title>
                                    <v-list-item-subtitle>Maak een nieuwe taak aan</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                  </eod-item-selector>
                  <template v-if="task">
                <v-row class="mt-0">
                    <v-col cols="12">
                        <eod-item-treeview-selector v-model="companyProject" outlined :readonly="task.statusCode == 'done'" label="Project" :tree="['companies', 'projects']" :fields="[['id','name', 'description'], ['id','name', 'description', 'locations{lat lon postalCode}']]" :modules="settings.modules" @input="projectsChanged"></eod-item-treeview-selector>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" v-if="task.projectId">
                        <v-select
                        outlined
                        
                        hide-details
                        :items="services"
                        label="Dienst"
                        v-model="task.serviceId"
                        item-text="name"
                        item-value="id"
                        :rules="requiredRules"
                        :readonly="task.statusCode == 'done'"
                        @change="servicesChanged"
                        :loading="servicesLoading"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="task.serviceId">
                        <v-select
                        outlined
                        
                        hide-details
                        :items="taskTypes"
                        label="Type taak"
                        v-model="task.taskTypeId"
                        item-text="name"
                        item-value="id"
                        :rules="requiredRules"
                        :readonly="task.statusCode == 'done'"
                        :loading="taskTypesLoading"
                        @change="taskTypesChanged"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :md="task.id?8:12" :sm="task.id?8:12" cols="12">
                        <v-text-field
                            outlined
                            hide-details
                            color="primary"
                            v-model="task.name"
                            :rules="requiredRules"
                            label="Naam"
                            required
                            :readonly="task.statusCode == 'done'"
                            @keyup="taskNameChanged = true"
                        ></v-text-field>
                    </v-col>
                    <v-col md="4" sm="6" cols="12" v-if="task.id">
                        <v-select
                        outlined
                        hide-details
                        
                        :items="settings.statusCodes"
                        label="Status"
                        v-model="task.statusCode"
                        item-text="text"
                        item-value="value"
                        :readonly="task.statusCode == 'done' && !setFinished"
                        :rules="requiredRules"
                        @change="statusCodeChanged"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <vc-date-picker :minute-increment="15" :attributes="[{key: 'today', highlight: {color: 'orange', fillMode:'solid', class:'primary'},dates: new Date()}]" :value="task.dueRequestedDateFormatted" @input="dueRequestedDateChanged" mode="dateTime" is24hr :model-config="{type: 'string',mask: 'DD-MM-YYYY HH:mm'}">
                            <template v-slot="{ inputValue, inputEvents, updateValue }">
                                <v-text-field label="Planningsdatum" prepend-icon="mdi-calendar" :value="inputValue" @input="updateValue($event, {formatInput: false, hidePopover:false,debounce:1000})" @change="updateValue($event, {formatInput: true,hidePopover: false,})" v-on="inputEvents" dense outlined clearable hide-details></v-text-field>
                            </template>
                        </vc-date-picker>
                    </v-col>
                    <v-col>
                        <vc-date-picker :minute-increment="15" :attributes="[{key: 'today', highlight: {color: 'orange', fillMode:'solid', class:'primary'},dates: new Date()}]" :value="task.completedDateFormatted" @input="completedDateChanged" mode="dateTime" is24hr :model-config="{type: 'string',mask: 'DD-MM-YYYY HH:mm'}">
                            <template v-slot="{ inputValue, inputEvents, updateValue }">
                                <v-text-field label="Datum afgewerkt" prepend-icon="mdi-calendar" :value="inputValue" @input="updateValue($event, {formatInput: false, hidePopover:false,debounce:1000})" @change="updateValue($event, {formatInput: true,hidePopover: false,})" v-on="inputEvents" dense outlined clearable hide-details></v-text-field>
                            </template>
                        </vc-date-picker>
                  </v-col>
              </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            outlined
                            hide-details
                            
                            v-model="task.description"
                            label="Omschrijving"
                            :readonly="task.statusCode == 'done'"
                        ></v-textarea>
                    </v-col>
                </v-row>
                  </template>
      </v-tab-item>
      <v-tab-item key="media" v-if="showMedia">
          <eod-storage-library v-if="task && task.id" :tags="tags" :searchFilters="storageLibraryFilters" :filterable="false" :searchable="false" :colAttrs="{cols:6,sm:6,md:6,lg:4,xl:4}"></eod-storage-library>
          <p v-else>Gelieve op te slaan om media toe te voegen.</p>
      </v-tab-item>
      <v-tab-item key="plan" v-if="showPlanning">
        <v-card flat v-if="task">
            <v-card-text style="min-height:500px">
                <v-row class="mt-2">
                    <v-col>
                        <vc-date-picker :minute-increment="15" :attributes="[{key: 'today', highlight: {color: 'orange', fillMode:'solid', class:'primary'},dates: new Date()},{key: 'requested', highlight: {color: 'orange', fillMode:'solid', class:'success'},dates: task.dueRequestedDateFormatted}]" :value="task.dueAfterDateFormatted" @input="dueAfterDateChanged" mode="dateTime" is24hr :model-config="{type: 'string',mask: 'DD-MM-YYYY HH:mm'}">
                            <template v-slot="{ inputValue, inputEvents, updateValue }">
                                <v-text-field label="Van" prepend-icon="mdi-calendar" :value="inputValue" @input="updateValue($event, {formatInput: false, hidePopover:false,debounce:1000})" @change="updateValue($event, {formatInput: true,hidePopover: false,})" v-on="inputEvents" dense outlined clearable></v-text-field>
                            </template>
                        </vc-date-picker>
                    </v-col>
                    <v-col>
                        <vc-date-picker :minute-increment="15" :attributes="[{key: 'today', highlight: {color: 'orange', fillMode:'solid', class:'primary'},dates: new Date()}]" :value="task.dueBeforeDateFormatted" @input="dueBeforeDateChanged" mode="dateTime" is24hr :model-config="{type: 'string',mask: 'DD-MM-YYYY HH:mm'}">
                            <template v-slot="{ inputValue, inputEvents, updateValue }">
                                <v-text-field label="Tot" prepend-icon="mdi-calendar" :value="inputValue" @input="updateValue($event, {formatInput: false, hidePopover:false,debounce:1000})" @change="updateValue($event, {formatInput: true,hidePopover: false,})" v-on="inputEvents" dense outlined clearable></v-text-field>
                            </template>
                        </vc-date-picker>
                  </v-col>
              </v-row>
              <v-subheader>Gebruikers</v-subheader>
              <eod-contacts ref="contacts" :importShow="false" :exportShow="false" :roles="settings.roles.collaborators" v-model="task.responsibleUsers"></eod-contacts>
                            </v-card-text>
                        </v-card>
      </v-tab-item>
      <v-tab-item key="products" v-if="task && showProducts">
         <v-row>
                    <v-col cols=12 md=6>
                        <v-card>
                            <v-card-title>Materialen</v-card-title>
                            <v-card-text>
                                <eod-products :suggested="taskTypeProducts" showQuantity showStockLocation v-model="task.products"></eod-products>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols=12 md=6>
                        <v-card>
                            <v-card-title>Assettypes</v-card-title>
                            <v-card-text>
                                <eod-productTypes :suggested="taskTypeProductTypes" showQuantity showStockLocation v-model="task.productTypes"></eod-productTypes>
                            </v-card-text>
                        </v-card>
                        
                    </v-col>
                </v-row>
      </v-tab-item>
    </v-tabs-items>
        
</v-form>
</v-card-text>
        <v-card-actions class="grey lighten-3 py-3 px-3">
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols=12 sm=6 md="auto">
                    <v-btn text @click="$emit('input', false)" rounded block>Annuleren</v-btn>
                </v-col>
                <v-col v-if="showSave" cols=12 sm=6 md="auto">
                    <v-btn color="secondary" depressed rounded @click="saveTask" :loading="saving" block>
                        <v-icon left>mdi-content-save</v-icon> Opslaan
                    </v-btn>
                </v-col>
                <v-col v-if="showSaveToOverview" cols=12 sm=6 md="auto">
                    <v-btn color="secondary" depressed rounded @click="saveToOverview" :loading="saving" block>
                        <v-icon left>mdi-content-save</v-icon> Opslaan en sluiten
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<style lang="scss">
    .v-dialog {
        overflow-Y: visible;
    }
</style>
<script>
    import {Task} from '@/eodvuecomponents';
    import eodContacts from './eod-contacts.vue';
    import eodColumnActions from './columns/eod-column-actions.vue';
    import eodStorageLibrary from './eod-storage-library.vue';
    import eodItemSelector from './eod-item-selector.vue';
    import eodProducts from './eod-products.vue';
    import eodProductTypes from './eod-productTypes.vue';
    import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';
    import TaskType from '../models/taskType';
    import eodItemTreeviewSelector from './eod-item-treeview-selector.vue';

    export default {
        components: {
            'eod-item-selector': eodItemSelector,
            'eod-listitem-autocomplete': eodListItemAutocomplete,
            'eod-contacts': eodContacts,
            'eod-storage-library': eodStorageLibrary,
            'eod-actions-column': eodColumnActions,
            'eod-products': eodProducts,
            'eod-productTypes': eodProductTypes,
            'eod-item-treeview-selector': eodItemTreeviewSelector
        },
        props:{
            value: {
                type: Boolean,
                default: false
            },
            showSelector: {
                type: Boolean,
                default: false
            },
            showSave:{
                type: Boolean,
                default: true
            },
            showSaveToOverview:{
                type: Boolean,
                default: true
            },
            showPlanning:{
                type: Boolean,
                default: true
            },
            showMedia:{
                type: Boolean,
                default: true
            },
            showProducts:{
                type: Boolean,
                default: true
            },
            showDates:{
                type: Boolean,
                default: true
            }
        },
        data: () => ({
            tab:'task',
            showStartDate: false,
            showEndDate: false,
            isLoading:false,

            dueDateAfter: null,
            dueDateBefore: null,

            task: null,
            tags: {},
            taskTypes: [],
            taskTypesLoading: false,
            taskTypeProducts: [],
            taskTypeProductTypes: [],

            taskNameChanged: false,

            companyProject:[],

            services: [],
            servicesLoading: false,
            
            showTaskTree: false,

            planHeaders: [
                { text: 'Van', value: 'dueDateAfter'},
                { text: 'Tot', value: 'dueDateBefore'},
                { width: 110, sortable:false, value: 'actions'},
            ],

            expanded: [],
            to_delete: [],

            requiredRules: [
                v => !!v || 'Veld is verplicht',
            ],
            settings: require('./../classes/settings'),
            saving: false,

            setFinished:false,
            datepickers: {
                startDate: false,
                endDate: false,
            },

            taskFields:[
                'id', 'name', 'parent{id}', 'description', 'statusCode', 'dueBeforeDate', 'dueAfterDate', 'dueRequestedDate', 'completedDate', 'postalCode', 'company{id name}', 'taskType{id estimatedDuration}', 'service{id name}', 'project{id name locations{name streetAddress postalCode region country locality}}', 'products{id name description code quantity stockLocation{id}}', 'productTypes{id name description type url vendor quantity stockLocation{id}}', 'responsibleUsers{id firstName lastName assignedRealmRoles{id name}}'
            ]
        }),
        watch:{
            tab(val){
                if(val == 2){
                    if(this.$refs.contacts){
                        this.$refs.contacts.loadUsers();
                    }

                    this.$forceUpdate();
                }
            }
        },
        computed: {
            storageLibraryFilters(){
                let filters = [];
                if (this.task && this.task.id) {
                    filters.push({
                        field: 'taskId',
                        value: this.task.id,
                        name: this.task.name
                    });
                }
                return filters;
            },
            selected_tasks(){
                if (!this.task) {
                    return [];
                }else if (!this.task.id){
                    return [{id: null, name: 'Nieuwe taak', level:2}];
                }

                return [{id: this.task.id, name: this.task.name, level:2}];
            },
        },
        methods: {
            dueAfterDateChanged(val){
                if(this.task.dueBeforeDate && this.$moment(this.task.dueBeforeDate) > this.$moment(this.task.dueAfterDate)){
                    let diff = this.$moment(this.task.dueBeforeDate).diff(this.$moment(this.task.dueAfterDate), 'minutes');
                    this.task.dueBeforeDateFormatted = this.$moment(val, 'DD-MM-YYYY HH:mm').add(diff, 'minutes').format('YYYY-MM-DD HH:mm');
                }else if (!this.task.dueBeforeDate && this.task.taskType.estimatedDuration){
                    this.task.dueBeforeDateFormatted = this.$moment(val, 'DD-MM-YYYY HH:mm').add(this.task.taskType.estimatedDuration, 'milliseconds').format('YYYY-MM-DD HH:mm');
                }
                this.task.dueAfterDateFormatted = this.$moment(val, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            },
            dueBeforeDateChanged(val){
                this.task.dueBeforeDateFormatted = this.$moment(val, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            },
            dueRequestedDateChanged(val){
                this.task.dueRequestedDateFormatted = this.$moment(val, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            },
            completedDateChanged(val){
                this.task.completedDateFormatted = this.$moment(val, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            },
            statusCodeChanged(statusCode){
                if(statusCode == 'done'){
                    this.setFinished = true;
                }
            },
            projectsChanged(result){
                if(result){
                    this.task.companyId = result[0].id;
                    this.task.projectId = result[1].id;
                }else{
                    this.task.companyId = null;
                    this.task.projectId = null;
                }

                if(result[1].locations && result[1].locations[0]){
                    this.task.lat = result[1].locations[0].lat;
                    this.task.lon = result[1].locations[0].lon;
                    this.task.postalCode = result[1].locations[0].postalCode;
                }

                this.task.serviceId = null;
                this.task.taskTypeId = null;
                this.loadServices();
            },
            loadServices(){
                this.servicesLoading = true;
                this.$eod.get('services', ['id', 'name', 'description'], {
                    where:[
                        {
                            column: 'companyId',
                            operator: '=',
                            value:this.task.companyId,
                            chain: 'or'
                        },
                    ],
                    orderBy: {column: 'name',type: 'asc'}
                })
                .then(response => {
                    if (response.data.data.services.edges) {
                        this.services = response.data.data.services.edges;
                    }
                }).finally(() => {
                    this.$forceUpdate();
                    this.servicesLoading = false;
                });

            },
            servicesChanged(){
                this.task.taskTypeId = null;
                this.loadTaskTypes();
            },
            loadTaskTypes(){
                this.taskTypesLoading = true;
                this.$eod.get('taskTypes', ['id', 'name', 'description'], {
                    where:[
                        {
                            column: 'defaultCompanyId',
                            operator: '=',
                            value:this.task.companyId,
                            chain: 'or'
                        },
                        {
                            column: 'defaultProjectId',
                            operator: '=',
                            value:this.task.projectId,
                            chain: 'or'
                        },
                        {
                            column: 'defaultServiceId',
                            operator: '=',
                            value:this.task.serviceId,
                            chain: 'or'
                        },
                        {
                            column: 'defaultCompanyId',
                            operator: '=',
                            value:'isnull',
                            chain: 'or'
                        },
                        {
                            column: 'defaultProjectId',
                            operator: '=',
                            value:'isnull',
                            chain: 'or'
                        },
                        {
                            column: 'defaultServiceId',
                            operator: '=',
                            value:'isnull',
                            chain: 'or'
                        },
                    ],
                    orderBy: {column: 'name',type: 'asc'}
                })
                .then(response => {
                    if (response.data.data.taskTypes.edges) {
                        this.taskTypes = response.data.data.taskTypes.edges;
                    }
                }).finally(() => {
                    this.$forceUpdate();
                    this.taskTypesLoading = false;
                });
            },
            loadTaskTypeProducts(taskTypeId){
                let taskType = new TaskType({id:taskTypeId});
                taskType.loadProducts().then(products => {
                    this.taskTypeProducts = products;

                    return taskType.loadProductTypes().then(productTypes => {
                        this.taskTypeProductTypes = productTypes;
                    });
                });
            },
            taskTypesChanged(taskTypeId){

                this.loadTaskTypeProducts(taskTypeId);

                if(!this.taskNameChanged){

                    let taskType = null;
                    for (let i = 0; i < this.taskTypes.length; i++) {
                        if(this.taskTypes[i].id == this.task.taskTypeId){
                            taskType = this.taskTypes[i];
                            break;
                        }
                    }

                    if(taskType){
                        let append = '';
                        const externalId = this.$eod.getOrganizationSetting('tasks.external.id');
                        if(externalId && externalId.value && externalId != ''){
                            append = ' ['+externalId.value+']';
                        }
                        this.task.name = taskType.name + append;
                        this.$forceUpdate();
                    }
                }
            },
            createTask(data){
                if(!data){
                        data = {};
                        const externalId = this.$eod.getOrganizationSetting('tasks.external.id');
                        if(externalId && externalId.value && externalId.value != ''){
                            data.externalIds = [{
                                'name': 'dbfact',
                                'id': externalId.value
                            }];
                        }
                    }
                    this.task = new Task(data);
                    this.tab = 'task';
            },
            setTask(items){
                if (items && items[0]) {
                        if(items[0].id){
                            this.isLoading = true;
                            this.$eod.getById('task', items[0].id, this.taskFields)
                            .then(response => {
                                this.task = new Task(response.data.data.task);
                                this.taskNameChanged = true;

                                if(this.task.dueBeforeDate){
                                    this.dueDateBefore = this.$moment(this.task.dueBeforeDate).format('YYYY-MM-DD HH:mm');
                                }

                                if(this.task.dueAfterDate){
                                    this.dueDateAfter = this.$moment(this.task.dueAfterDate).format('YYYY-MM-DD HH:mm');
                                }

                                if(this.task.projectId){
                                    this.companyProject = [this.task.company, this.task.project];
                                    this.loadServices();
                                }

                                if(this.task.serviceId){
                                    this.loadTaskTypes();
                                }

                                this.loadTaskTypeProducts(this.task.taskType.id);

                                this.fillTags();
                            }).finally(() => {
                                this.isLoading = false;
                            })
                        }else{
                            this.task = new Task(items[0]);
                            this.fillTags();
                        }
                        
                    }else{
                        this.createTask();
                    }
            },
            fillTags(){
                this.tags = {
                    taskId: this.task.id,
                };

                if (this.task.taskType) {
                    this.tags.taskTypeId = this.task.taskType.id;
                }

                if (this.task.project) {
                    this.tags.projectId = this.task.project.id;
                }

                if (this.task.company) {
                    this.tags.companyId = this.task.company.id;
                }

                if (this.task.service) {
                    this.tags.serviceId = this.task.service.id;
                }
            },
            async saveToOverview(){
                this.saveTask().then((response) => {
                    if(response !== false){
                        this.task = null;
                        this.tab = 'task';
                        this.$emit('input', false);
                    }
                });
            },
            async saveTask(){
                let valid = this.$refs.newTaskForm.validate();

                if (!valid) {
                    return false;
                }

                if (!this.task) {
                    return false;
                }

                this.saving = true;

                let saveData = this.task;
                if(this.task.getSaveData){
                    saveData = await this.task.getSaveData();
                }

                return new Promise(async (resolve, reject) => {
                    // create or save Task (process)
                    if(!saveData.statusCode){
                        saveData.statusCode = 'backlog';
                    }else if (saveData.statusCode == 'backlog' && saveData.dueBeforeDate && saveData.dueAfterDate){
                        saveData.statusCode = 'to_do';
                    }else if (saveData.statusCode == 'to_do' && (!saveData.dueBeforeDate || !saveData.dueAfterDate)){
                        saveData.statusCode = 'backlog';
                    }

                    let parentData = Object.assign({}, saveData);
                    
                    if(this.task.parent && this.task.parent.id){
                        parentData.id = this.task.parent.id;
                    }
                    parentData.parentTaskId = null;
                    parentData.category = "TASK";
                    
                    await this.$eod.save('Task', parentData).then(async result => {
                        let taskId = null;
                        if (result.data.data.createTask) {
                            taskId = result.data.data.createTask.id;
                        }else{
                            taskId = result.data.data.updateTask.id;
                        }

                        //create job
                        saveData.parentTaskId = taskId;
                        saveData.category = "JOB";

                        await this.$eod.save('Task', saveData, {
                            update: {
                                returnfields: this.taskFields
                            },
                            create: {
                                returnfields: this.taskFields
                            }
                        }).then(result => {
                            if (result.data.data.createTask) {
                                this.task = new Task(result.data.data.createTask);
                                const externalId = this.$eod.getOrganizationSetting('tasks.external.id');
                                if(externalId && externalId.value && externalId.value != ''){
                                    let nextId = parseInt(externalId.value) + 1;
                                    this.$eod.saveOrganizationSetting('tasks.external.id', nextId);
                                }
                            }else{
                                this.task = new Task(result.data.data.updateTask);
                            }
                        });

                        if(this.setFinished){
                            // Check sharing trigger
                            if(this.$eod.getOrganizationSetting('share.ftp.hosts.0.host')){
                                this.$eodShare.upload('ftp', 'task', {
                                    'path': this.$eod.getOrganizationSetting('share.ftp.tasks.path').value,
                                    'taskId': this.task.id
                                });
                            }
                        }

                        this.fillTags();

                        if(this.$toaster){
                            this.$toaster.notify({
                                type: 'success',
                                title: 'Succes',
                                body: 'Item is succesvol opgeslaan!'
                            });
                        }
                        
                        this.$emit('saved', this.task);

                        resolve();
                        
                    }).catch((err) => {
                        if(this.$toaster){
                            this.$toaster.notify({
                                type: 'danger',
                                title: 'Fout',
                                body: 'Fout bij het opslaan van de taak!'
                            });
                        }
                        reject();
                    }).finally(() => {
                        this.saving = false;
                    });
                });
            }
        }
    }
</script>