export default class StorageObject {
    static moduleName = 'storageObjects';
    static searchConfig = [
        {
            'icon': 'mdi-clipboard-outline',
            'label': 'Taak',
            'type': 'module',
            'settings': {
                'module': 'tasks',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'taskId'
        },
        {
            'icon': 'mdi-clipboard-outline',
            'label': 'Taaktype',
            'type': 'module',
            'settings': {
                'module': 'tasktypes',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'taskTypeId'
        },
        {
            'icon': 'mdi-folder-open-outline',
            'label': 'Project',
            'type': 'module',
            'settings': {
                'module': 'projects',
                'fields': ['name', 'description'],
                'title': '{name} ({description})',
            },
            'filter': 'projectId'
        },
        {
            'icon': 'mdi-file-sign',
            'label': 'Dienst',
            'type': 'module',
            'settings': {
                'module': 'services',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'serviceId'
        },
        {
            'icon': 'mdi-office-building-outline',
            'label': 'Bedrijf',
            'type': 'module',
            'settings': {
                'module': 'companies',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'companyId'
        },
        {
            'icon': 'mdi-waveform',
            'label': 'Type meetwaarde',
            'type': 'module',
            'settings': {
                'module': 'measurementTypes',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'measurementTypeId'
        },
        {
            'icon': 'mdi-package-variant',
            'label': 'Asset',
            'type': 'module',
            'settings': {
                'module': 'products',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'productId'
        },
        {
            'icon': 'mdi-account-outline',
            'label': 'Werknemer',
            'type': 'module',
            'settings': {
                module: 'searchUsers',
                fields: ['firstName', 'lastName', 'username'],
                title: '{firstName} {lastName} ({username})',
                options: {
                    enabled: true
                }
            },
            'filter': 'userId'
        },
        {
            'icon': 'mdi-calendar',
            'label': 'Aanmaakdatum',
            'type': 'date-between',
            'filter': 'createdAt'
        },
    ];
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }

    static getSearchFilter(filterName) {
        for (let i = 0; i < this.searchConfig.length; i++) {
            const filter = this.searchConfig[i];
            if (filter.filter == filterName) {
                return filter;
            }
        }
        return null;
    }
}
