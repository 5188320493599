const { Product } = require('../../eodvuecomponents');

module.exports = {
    tabs:[
        {
              path: 'products',
              text: 'Assets',
              counter: (eod) => {
                  return eod.count('countTreeObjects', {
                    where: [
                        {
                            column:'class',
                            value: 'DEVICE'
                        }
                    ]
                  }).then(response => {
                      if (response.data.data && response.data.data.countTreeObjects) {
                          return response.data.data.countTreeObjects.totalCount;
                      } else {
                          return 0;
                      }
                  });
              }
          },
          {
              path: 'products.types',
              text: 'Types',
          }
        ],
    routes: [
    {
        path: '/devices',
        name: 'products',
        meta:  {
            permissions:['products.devices.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/devices/settings',
        name: 'products.settings',
        component: () => import('./views/settings.vue')
    },
    {
        path: '/devices/types',
        name: 'products.types',
        meta:{
            permissions:['products.devices.read']
        },
        component: () => import('./types/index.vue')
    },
    {
        path: '/devices/types/detail/:id?',
        name: 'products.type',
        component: () => import('./types/detail.vue')
    },
    {
        path: '/devices/types/settings',
        name: 'products.types.settings',
        component: () => import('./types/settings.vue')
    },
    ],
    search: Product.searchConfig,
    detail: {
        route: 'product',
        endpoint: 'product',
        objectName: 'Product',
    },
    index: {
        route: 'products',
        endpoint: 'products',
        settingsRoute:'products.settings',
        fields: ['id', 'name', 'statusCode{id name}', 'description', 'class', 'children{id}','productType{id name}', 'createdAt', 'updatedAt'],
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: 'Type',
                value: 'productType.name',
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: 'Status',
                value: 'statusCode.name',
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: '',
                width: 50,
                value: 'createdAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            },
            {
                width: 120,
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-actions.vue')
            }
        ],
        tabs: [],
    },
    export: {
        endpoint: 'products',
        fields: ['id', 'name', 'description', 'code', 'warrantyEndDate', 'installedDate', 'lastMaintenanceDate', 'createdAt', 'updatedAt']
    },
    import: {
        endpoint: 'Products',
        objectName: 'Product',
    },
    itemObject: Product,
    types: {
        search: [{
                'icon': 'mdi-format-text',
                'label': 'Naam',
                'type': 'property',
                'filter': 'name'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Type',
                'type': 'property',
                'filter': 'type'
            },
            {
                icon: 'mdi-calendar',
                label: 'Aanmaakdatum',
                type: 'date-between',
                filter: 'createdAt'
            },
        ],
        itemObject: require('@/eodvuecomponents/src/models/productType').default,
        index: {
            route: 'products.types',
            endpoint: 'productTypes',
            settingsRoute:'products.types.settings',
            fields: ['id', 'name', 'vendor', 'type', 'class'],
            headers: [
                {
                    text: 'Naam',
                    value: 'name',
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
                },
                {
                    text: 'Type',
                    value: 'type',
                    width: 250,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
                },
                {
                    text: 'Verkoper',
                    value: 'vendor',
                    width: 250,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
                },
                {
                    text: '',
                    width: 50,
                    value: 'createdAt',
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                    extra: {
                        icon: 'mdi-alarm-plus',
                        collapsed: true,
                        prepend: 'Aangemaakt op'
                    }
                }, {
                    text: '',
                    width: 50,
                    value: 'updatedAt',
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                    extra: {
                        icon: 'mdi-alarm',
                        collapsed: true,
                        prepend: 'Gewijzigd op'
                    }
                }, {
                    width: 120,
                    sortable: false,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-actions.vue')
                }
            ],
        },
        detail: {
            route: 'products.type',
            endpoint: 'productType',
            objectName: 'ProductType',
            fields: ['id', 'name', 'class','customFields{customFieldId, module, itemId, value}', 'description', 'unit{id}', 'vendor','url', 'type', 'suppliers{id name}', 'measurementTypes{id name indicativeValue unit{id name}}'],
            tabs: []
        },
        export: {
            endpoint: 'productTypes',
            fields: ['id', 'name', 'description', 'vendor', 'type','url']
        },
        import: {
            endpoint: 'ProductTypes',
            objectName: 'ProductType',
        },
    }
}