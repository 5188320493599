<template>
    <div class="eod-job-card__wrapper">
        <div class="eod-job-card__header-actions">
        <v-btn v-if="showDelete" color="transparent" depressed @click="$emit('delete', value)" fab small><v-icon class="white--text">mdi-trash-can-outline</v-icon></v-btn>
        </div>
    <v-card class="eod-job-card" :disabled="!getStatusCode(value).calendar_enabled || disabled" @click="$emit('click', value)">
        <div class="eod-job-card__title" :class="(showDelete?'pr-9':'')+' '+(extraClass?extraClass:'')+' white--text'" :style="'background-color:'+getBgColor()+';'">
            <strong>{{value.parent.name}}</strong>
            <div><v-icon dark left small>mdi-clock-outline</v-icon>{{$moment(value.dueAfterDate).format('HH:mm')}} - {{$moment(value.dueBeforeDate).format('HH:mm')}}</div>
        </div>
        <slot name="subheader"></slot>
        <div class="eod-job-card__content">
        <div class="mb-1" v-if="value.taskType">
          <v-icon left>mdi-clipboard-text-outline</v-icon>{{value.taskType.name}}
        </div>
        <div class="mb-1" v-if="value.project">
          <v-icon left>mdi-folder-open-outline</v-icon>{{value.project.name}}
        </div>
        <div class="mb-1" v-if="value.project.locations && value.project.locations[0]">
          <v-icon left>mdi-map-marker-radius</v-icon>{{value.project.locations[0].streetAddress}}, {{value.project.locations[0].postalCode}} {{value.project.locations[0].locality}}
        </div>
        <div class="mb-1 d-flex align-items-start" v-if="value.parent.description && value.parent.description != ''">
                  <v-icon left>mdi-text</v-icon>
                  <div class="text-caption">
                  <eod-rte :value="value.parent.description" disabled></eod-rte>
                </div>
            </div>
            <div class="d-inline-block grey lighten-4 rounded-xl px-4 py-1 mt-2" v-if="hasProducts() || hasMedia()">
                <v-btn @click.stop="showProductsDialog = true" class="mr-3" v-if="hasProducts()" icon><v-icon>mdi-package-variant</v-icon>{{value.products.length+value.productTypes.length}}</v-btn>
                <v-btn @click.stop="showTaskMediaDialog = true" class="mr-3" v-if="hasMedia()" icon><v-icon>mdi-image-multiple</v-icon>{{value.parent.storageObjects.length}}</v-btn>
            </div>
            
            <eod-products-dialog v-if="hasProducts()" v-model="showProductsDialog" :products="value.products" :productTypes="value.productTypes"></eod-products-dialog>
            <eod-media-dialog v-if="hasMedia()" v-model="showTaskMediaDialog" :media="value.parent.storageObjects"></eod-media-dialog>
        </div>
    </v-card>
    <div class="eod-job-card__actions elevation-1 align-items-center d-flex grey lighten-3 rounded-xl px-4 py-1">
            <v-chip class="mr-2"  small :color="getStatusCode(value).color" :dark="getStatusCode(value).textColor != 'dark'"><v-icon small v-html="getStatusCode(value).icon"></v-icon></v-chip>
          <v-spacer></v-spacer>
            <v-btn @click="showMediaDialog = true" class="mr-3" v-if="(value.project.storageObjects && value.project.storageObjects.length > 0)" icon><v-icon>mdi-image-multiple</v-icon>{{value.project.storageObjects.length}}</v-btn>
            <v-btn @click="showArticlesDialog = true" class="mr-3" v-if="(value.articles && value.articles.length > 0)" icon><v-icon>mdi-package-variant</v-icon>{{value.articles.length}}</v-btn>
            
            <v-btn @click="showContactsDialog = true" class="mr-3" v-if="value.project.users && value.project.users.length > 0" icon><v-icon>mdi-account-outline</v-icon>{{value.project.users.length}}</v-btn>
            <v-btn @click="showLocationsDialog = true" class="mr-3" v-if="value.project.locations && value.project.locations.length > 0" icon :color="showLocationAlert()?'accent':''"><v-icon>mdi-map-marker</v-icon>{{value.project.locations.length}}</v-btn>
          <v-btn icon @click="$emit('showInfo', value)"><v-icon>mdi-information</v-icon></v-btn>
          
        </div>
        
        <eod-contacts-dialog v-model="showContactsDialog" :contacts="value.project.users"></eod-contacts-dialog>
        <eod-locations-dialog v-model="showLocationsDialog" :locations="value.project.locations"></eod-locations-dialog>
        <eod-media-dialog v-model="showMediaDialog" favourite :media="value.project.storageObjects"></eod-media-dialog>
        <eod-dialog v-model="showArticlesDialog" :inset="false" icon="mdi-package-variant" iconColor="primary" title="Artikelen" hide-ok cancelText="Sluiten">
            <eod-card tile flat v-for="(article, articleIndex) in value.articles" :key="article.id" :class="{'grey lighten-5':(articleIndex+1)%2 == 0}">
                <v-card-text>
                <div class="d-flex align-items-center">{{article.name}}<v-spacer></v-spacer><strong class="text-h5 d-block px-4">{{ Math.abs(article.quantity) }}</strong></div>
            </v-card-text>
                <v-divider></v-divider>
            </eod-card>
        </eod-dialog>
    </div>
</template>
<style lang="scss">
.eod-job-card__wrapper{
    position: relative;

    .eod-job-card__header-actions{
        position: absolute;
        right: 1px;
        top: 1px;
        z-index: 5;
    }

    .eod-job-card{
        margin-bottom: 30px;
        border-radius: 10px;

        .eod-job-card__title{
            padding: .6rem 1rem;
        }

        .eod-job-card__content{
            padding: .8rem 1rem 2.4rem 1rem;
        }
    }

    .eod-job-card__actions{
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 100%;
    }
}


</style>
<script>
    import Task from './../models/task';
    import eodProductsDialog from './eod-products-dialog';
    import eodContactsDialog from './eod-contacts-dialog';
    import eodLocationsDialog from './eod-locations-dialog';
    import eodMediaDialog from './eod-media-dialog';
    import eodRte from './eod-rte.vue';

    export default {
        name:'eod-job-card',
        components:{
            eodProductsDialog,
            eodContactsDialog,
            eodLocationsDialog,
            eodMediaDialog,
            eodRte
        },
        props:{
            value: Object,
            extraClass: String,
            showDelete: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            showProductsDialog: false,
            showContactsDialog: false,
            showLocationsDialog: false,
            showMediaDialog: false,
            showTaskMediaDialog: false,
            showArticlesDialog: false,
        }),
        methods:{
            hasProducts(){
                return (this.value.products && this.value.products.length > 0) || (this.value.productTypes && this.value.productTypes.length > 0);
            },
            hasMedia(){
                return (this.value.parent.storageObjects && this.value.parent.storageObjects.length > 0);
            },
            getBgColor(){
                if (this.value && this.value.statusCode != 'done' && this.value.taskType) {
                    if (this.value.taskType.configuration && this.value.taskType.configuration.settings) {
                        const setting = this.$helper.getConfigurationSetting(this.value.taskType.configuration.settings, 'color');
                        if (setting) {
                            return setting.value;
                        }
                    }
                }

                return this.getStatusCode(this.value).color;
            },
            getStatusCode(job){
                return Task.getStatusCode(job.statusCode);
            },
            showLocationAlert(){
                for (let i = 0; i < this.value.project.locations.length; i++) {
                    const location = this.value.project.locations[i];
                    if(location.notes && location.notes != ''){
                        return true;
                    }
                }

                return false;
            }
        }
    }
</script>