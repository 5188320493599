<template>
    <v-dialog :value="value" @input="(val) => { $emit('input', val) }" max-width="1200">
        <v-stepper v-model="step">
            <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1">
                    Gegevens
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2">
                    Exporteren
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1" class="eod-export-dialog__content">
                    <div class="px-2 py-2">
                        <v-select v-model="selectedModule" @change="moduleChanged" return-object :items="modules" dense
                            outlined item-value="unique" item-text="endpoint" label="Wat wilt u exporteren?">
                            <template v-slot:selection="data">
                                <v-list dense class="py-0">
                                    <v-list-item class="px-0">
                                        <v-list-item-icon class="mr-2">
                                            <v-icon v-html="data.item.icon"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t(data.item.unique) }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </template>
                            <template v-slot:item="data">
                                <v-list dense class="py-0">
                                    <v-list-item class="px-0">
                                        <v-list-item-icon class="mr-2">
                                            <v-icon v-html="data.item.icon"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t(data.item.unique) }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </template>
                        </v-select>
                        <div>
                            <v-row class="align-items-center mb-2">
                                <v-col>
                                    <eod-search topbar flat
                                        v-if="selectedModule && selectedModule.model && selectedModule.model.searchConfig"
                                        v-model="selectedFilters" :config="selectedModule.model.searchConfig"
                                        @update="renderPreview"></eod-search>
                                    <eod-search topbar flat
                                        v-if="selectedModule && selectedModule.value && customSearchConfig"
                                        v-model="selectedFilters" :config="customSearchConfig"
                                        @update="renderCustomPreview"></eod-search>
                                </v-col>
                                <v-col cols="auto">
                                    <eod-time-selector v-model="selectedTimeFilter"
                                        @input="selectedModule.model ? renderPreview() : renderCustomPreview()"></eod-time-selector>
                                </v-col>
                            </v-row>
                            <v-progress-linear v-if="previewLoading" indeterminate></v-progress-linear>
                        </div>
                    </div>

                    <div class="py-2" style="overflow-y: auto;flex-grow: 1;" v-if="!previewLoading">
                                <v-data-table
                                    
                                    :items-per-page="-1" :hide-default-footer="true" fixed-header dense
                                    :items="previewItems" :headers="previewHeaders"></v-data-table>
                            </div>
                            <template v-if="!previewLoading">
                                <v-alert dense class="mb-1" type="info" text v-if="(previewItemsTotal > previewItems.length)">Enkel
                                    de
                                    eerste {{ previewItems.length }} rijen van de {{ previewItemsTotal }} worden
                                    weergegeven.</v-alert>
                                <v-alert dense class="mb-1" type="info" text v-if="(previewItemsTotal > 1500)">Enkel de eerste
                                    1500
                                    rijen worden geëxporteerd!</v-alert>
                            </template>
                    <v-divider></v-divider>
                    <div class="px-2 py-2 d-flex">
                        <v-btn text @click="$emit('input', false)">Annuleren</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="exportItems">
                            Volgende
                        </v-btn>
                    </div>
                </v-stepper-content>

                <v-stepper-content step="2" class="eod-export-dialog__content">
                    <div class="px-2 py-2 mb-4">
                        <template v-if="exportLoading">
                            <h2>Genereren</h2>
                            <p>Bezig met genereren...</p>
                        </template>
                        <template v-else-if="!exportError">
                            <h2>Klaar</h2>
                            <p>Exporteren gelukt!</p>
                        </template>
                        <template v-else>
                            <h2>Fout</h2>
                            <p>Fout bij het exporteren van de gegevens.</p>
                            <v-alert type="error" v-html="exportError"></v-alert>
                        </template>

                    </div>

                    <v-divider class="mt-auto"></v-divider>
                    <div class="px-2 py-2 d-flex">
                        <v-btn text @click="$emit('input', false)">
                            Sluiten
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-btn v-if="!exportError" :loading="exportLoading" :href="downloadUrl" color="primary"><v-icon
                                left>mdi-microsoft-excel</v-icon>Downloaden</v-btn>
                    </div>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-dialog>
</template>
<style lang="scss">
.eod-export-dialog__content {
    .v-stepper__wrapper {
        height: calc(100vh - 160px);
        max-height: 600px;
        display: flex;
        flex-direction: column;
        //overflow-y: auto;
    }

}
</style>
<script>
import eodSearch from './eod-search';
import eodTimeSelector from './eod-time-selector';
import _ from 'lodash';

export default {
    name: 'eod-export-dialog',
    components: {
        eodSearch,
        eodTimeSelector,
    },
    props: {
        value: Boolean,
        filters: Array,
        timeFilters: Object,
        endpoint: String
    },
    data: () => ({
        // General
        step: 1,

        // 1. Data
        settings: require('../classes/settings'),
        modules: [],
        selectedModule: null,
        selectedFilters: [],

        // 2. Preview
        previewLoading: false,
        previewItems: [],
        previewItemsTotal: 0,
        previewHeaders: [],
        customSearchConfig: null,
        selectedTimeFilter: null,
        timeFilterField: 'createdAt',

        // Export
        exportLoading: false,
        exportError: null,
        downloadUrl: null,

    }),
    async created() {

        const customExportSetting = this.$eod.getOrganizationSetting('organization.exports.custom.listId');
        if (customExportSetting && customExportSetting.value) {
            const listItems = await this.$eod.getById('list', customExportSetting.value, ['id', 'name', 'description', 'isActive', 'itemType', 'fields{name isActive}', 'listItems{id name description isActive low high stepSize threshold fields{name isActive}}'])
                .then(response => {
                    if (response.data.data.list) {
                        return response.data.data.list.listItems;
                    }

                    return null;
                });

            if (listItems && listItems.length > 0) {
                this.modules.push({ header: 'Op maat' });
                for (let i = 0; i < listItems.length; i++) {
                    const listItem = listItems[i];
                    this.modules.push({
                        unique: listItem.name,
                        icon: 'mdi-table',
                        value: listItem.fields[0] ? listItem.fields[0].name : null,
                        module_link: listItem.fields[1] ? listItem.fields[1].name : null
                    });
                }
            }
        }

        if (this.settings && this.settings.modules) {
            this.modules.push({ header: 'Modules' });
            for (const moduleName in this.settings.modules) {
                const moduleInfo = this.settings.modules[moduleName];

                if (moduleInfo.model && moduleInfo.model.searchConfig && moduleInfo.model.exportFields) {
                    this.modules.push({
                        unique: moduleName,
                        ...moduleInfo
                    });
                }
            }
        }

        if (this.filters) {
            this.selectedFilters = this.filters;
        }

        if (this.timeFilters) {
            this.selectedTimeFilter = this.timeFilters;
        }

        if (this.endpoint) {
            for (let i = 0; i < this.modules.length; i++) {
                const mod = this.modules[i];
                if (mod.unique == this.endpoint) {
                    this.selectedModule = mod;
                    this.renderPreview();
                }
            }
        }
    },
    methods: {
        moduleChanged(item) {
            this.selectedFilters = [];
            this.customSearchConfig = null;
            this.timeFilterField = 'createdAt';
            
            if (item.model) {
                this.renderPreview();
            } else if (item.value) {
                this.getCustomSearchConfig().then(() => {
                    this.renderCustomPreview();
                });
                
            }
        },
        getHeadersOfField(field) {
            let allFields = [];
            if (field.includes('{')) {
                field = field.replaceAll('}', '');
                field = field.replaceAll('{', ' ');
                const fields = field.split(/[\s,]+/);
                for (let i = 1; i < fields.length; i++) {
                    const splitField = fields[i];
                    allFields.push(fields[0] + '.' + splitField);
                }
            } else {
                allFields.push(field);
            }

            return allFields;
        },
        getVariables() {
            let variables = this.$helper.filtersToVariables(this.selectedFilters);
            if (this.selectedModule.model && this.selectedModule.model.exportFilters) {
                const moduleFilters = _.clone(this.selectedModule.model.exportFilters);
                const mergedFilters = _.merge(variables, moduleFilters);
                if (moduleFilters.where && variables.where) {
                    mergedFilters.where = [];
                    for (let i = 0; i < variables.where.length; i++) {
                        const whereFilter = variables.where[i];
                        mergedFilters.where.push(whereFilter);

                    }
                    for (let i = 0; i < moduleFilters.where.length; i++) {
                        const whereFilter = moduleFilters.where[i];
                        mergedFilters.where.push(whereFilter);
                    }
                }

                variables = mergedFilters;
            }

            if (this.selectedTimeFilter) {
                if (!variables.where) {
                    variables.where = [];
                }

                variables.where.push({
                    column: this.timeFilterField,
                    operator: '>=',
                    value: this.selectedTimeFilter.from.toISOString()
                });
                variables.where.push({
                    column: this.timeFilterField,
                    operator: '<=',
                    value: this.selectedTimeFilter.until.toISOString()
                });
            }

            return variables;
        },
        getCustomSearchConfig() {
            this.customSearchConfig = null;
            return this.$eod.exportCustomSearchConfig(this.selectedModule.value).then(response => {
                if (response.data) {
                    this.customSearchConfig = response.data.searchConfig;
                    if (response.data.timeSelector) {
                        this.timeFilterField = response.data.timeSelector.filter;
                    }
                }
            });
        },
        renderCustomPreview() {
            this.previewHeaders = [];
            this.previewLoading = true;
            let variables = this.getVariables();
            variables.limit = 10;

            this.$eod.exportCustomPreview(this.selectedModule.value, variables).then(response => {
                if (response && response.data && response.data[0]) {
                    let headers = Object.keys(response.data[0]);
                    for (let j = 0; j < headers.length; j++) {
                        const header = headers[j];
                        this.previewHeaders.push({
                            value: header,
                            text: header,
                            //width: '250px',
                            sortable: false,
                        });
                    }

                    this.previewItems = response.data;
                    this.previewItemsTotal = response.data.length;

                }
            }).finally(() => {
                this.previewLoading = false;
            });
        },
        renderPreview() {
            this.previewHeaders = [];
            this.previewLoading = true;

            for (let i = 0; i < this.selectedModule.model.exportFields.length; i++) {
                const field = this.selectedModule.model.exportFields[i];
                const headers = this.getHeadersOfField(field);

                for (let j = 0; j < headers.length; j++) {
                    const header = headers[j];
                    this.previewHeaders.push({
                        value: header,
                        text: header.replaceAll('.', '__'),
                        width: '250px',
                        sortable: false,
                    });
                }

            }

            let variables = this.getVariables();

            variables.limit = 10;
            variables.offset = 0;

            this.$eod.get(this.selectedModule.endpoint, this.selectedModule.model.exportFields, variables)
                .then(response => {
                    if (response.data.data && response.data.data[this.selectedModule.endpoint] && response.data.data[this.selectedModule.endpoint].edges) {
                        this.previewItems = response.data.data[this.selectedModule.endpoint].edges;
                        this.previewItemsTotal = response.data.data[this.selectedModule.endpoint].totalCount;
                        //this.step = 2;
                    } else {
                        console.error('error getting preview');
                    }
                }).catch(() => {
                    console.error('error getting preview');
                }).finally(() => {
                    this.previewLoading = false;
                });
        },
        exportItems() {
            this.exportLoading = true;
            this.step = 2;

            let exportAction = null;
            let variables = this.getVariables();
            if (this.selectedModule.model) {
                exportAction = this.$eod.export(this.selectedModule.endpoint, this.selectedModule.model.exportFields, variables);
            } else {
                exportAction = this.$eod.exportCustom(this.selectedModule.value, variables);
            }

            exportAction.then(response => {
                if (response.data.success) {
                    this.downloadUrl = response.data.path;
                } else {
                    this.exportError = response.data.error;
                }
            }).catch((e) => {
                this.exportError = e;
            }).finally(() => {
                this.exportLoading = false;
            });

        }
    }
}
</script>