<template>
    <div>
        <v-label v-if="label">{{ label }}</v-label>
        <v-chip-group :value="value" @change="val => $emit('input', val)" column active-class="primary white--text">
            <v-chip style="border: 1px solid #eeeeee;background-color: transparent;" v-for="item in items" :key="item.id" :value="item.id">{{ item.name }}</v-chip>
        </v-chip-group>
    </div>
</template>
<script>
export default {
    name: 'eod-list-label-selector',
    props:{
        value: String,
        listId: String,
        label: String,
    },
    data: () => ({
        items: []
    }),
    created(){
        this.$eod.get('listItems', ['id','name','description', 'threshold', 'isActive'], {
            orderBy:{
                column: 'threshold',
                type: 'ASC'
            },
            where:[
                {
                    column: 'listId',
                    operator: '=',
                    value: this.listId
                },
                {
                    column: 'isActive',
                    operator: '=',
                    value: '1'
                }
            ]
        }).then(result => {
            this.items = result.data.data.listItems.edges;
        });
    }
}
</script>