<template>
    <v-dialog v-on="$listeners" v-bind="$attrs" content-class="eod-actions-dialog" max-width="800">
        <template v-slot:activator="{on, attrs}">
            <slot name="activator" v-bind="attrs" :on="on"></slot>
        </template>
        <eod-card>
            <template v-if="title" v-slot:header>
                <v-card-title class="text-body-1 primary white--text">{{ title }}</v-card-title>
            </template>
            <v-list class="pt-0 pb-0">
                <template v-for="(item, itemIndex) in items">
                <v-list-item :key="itemIndex" @click="item.action">
                    <v-list-item-action v-if="item.icon"><v-icon>{{ item.icon }}</v-icon></v-list-item-action>
                    <v-list-item-title>
                        {{ item.name }}
                    </v-list-item-title>
                </v-list-item>
                <v-divider v-if="itemIndex < items.length-1"></v-divider>
            </template>
            </v-list>
        </eod-card>
    </v-dialog>
</template>
<style scoped>
  >>> .eod-actions-dialog {
    align-self: center;
  }
</style>
<script>
export default {
    name: 'eod-action-dialog',
    props:{
        title: String,
        items: Array
    }
}
</script>