<template>
    <td class="w-fixed-50 w-xxl-auto">
        <v-menu v-if="item.project" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="{ ...attrs, ...attrsMenu }" v-on="{ ...on, ...onMenu }" color="transparent">
                            <v-icon>{{ icon }}</v-icon><span v-if="!collapsed" class="ml-1 d-none d-xl-inline">{{
                                item.project.name
                            }}</span>
                        </v-chip>
                    </template>
                    <span>{{
                        item.project.name
                    }}</span>
                </v-tooltip>

            </template>
            <v-card flat width="400" max-width="100%">
                <v-card-title class="text-h6 d-flex justify-content-between"><span>{{ item.project.name }}</span><v-btn icon
                        :to="{ name: 'project', params: { id: item.project.id } }"><v-icon>mdi-pencil-outline</v-icon></v-btn></v-card-title>
                <v-divider></v-divider>
                <eod-project-info :value="item.project.id" @showUserDetails="showUserDetails"></eod-project-info>
            </v-card>
        </v-menu>
        <v-dialog v-if="userDetailDialog" v-model="userDetailDialog" :max-width="800" persistent scrollable>
            <v-btn style="position:absolute;right:15px;top:20px;" icon
                @click="userDetailDialog = false"><v-icon>mdi-close</v-icon></v-btn>
            <eod-add-contact v-model="userDetail" @onCancel="userDetailDialog = false"
                @onSaved="itemSaved"></eod-add-contact>
        </v-dialog>
    </td>
</template>
<script>
import eodProjectInfo from './../eod-project-info.vue';
import eodAddContact from './../eod-add-contact.vue';
import User from './../../models/user';

export default {
    name: 'eod-column-extra',
    components: {
        'eod-project-info': eodProjectInfo,
        'eod-add-contact': eodAddContact,
    },
    props: {
        'item': Object,
        'fieldname': String,
        'extra': Object,
    },
    data: () => ({
        userDetailDialog: false,
        userDetailId: false,
        userDetail: null,
    }),
    computed: {
        collapsed() {
            return this.extra && this.extra.collapsed;
        },
        icon() {
            return (this.extra && this.extra.icon) ? this.extra.icon : 'mdi-folder-open-outline';
        }
    },
    methods: {
        showUserDetails(userId) {
            this.userDetailId = userId;
            this.$eod.getById('user', userId, ['id', 'companies {id name}', 'projects {id name}', 'firstName', 'lastName', 'username', 'email', 'notificationSettings', 'assignedRealmRoles{id name}', 'attributes{phone_number, jobtitle nickname birthdate gender locality locale iban bic postal_code street_address region country notes extra_email extra_phone description}'])
                .then(response => {
                    this.userDetail = new User(response.data.data.user);
                    this.userDetailDialog = true;
                });
        },
        itemSaved(item) {
            let saveData = item.getSaveData();
            this.$eod.save('User', saveData).then(result => {
                this.userDetailDialog = false;
            });
        }
    }
}
</script>