<template>
    <div class="eod-measurement-scale">
        <div class="eod-measurement-scale__items">
            <v-radio-group :value="value" @change="value => $emit('input', value)" :readonly="readonly" :disabled="readonly" row>
                <v-radio v-for="scale_value in JSON.parse(measurementType.formConfig.values)" :key="scale_value" :label="''+scale_value" :value="scale_value" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-checkbox-blank-circle"></v-radio>
            </v-radio-group>
        </div>
    </div>
</template>
<style lang="scss">
    .eod-measurement-scale__items {
        .v-input--selection-controls{
            .v-radio {
                position: relative;

                &.v-item--active{
                    > .v-label{
                        color: #ffffff;
                        font-weight: bold;
                    }
                }

                > .v-label{
                    position: absolute !important;
                    width: 100%;
                    text-align: center;
                    display: block;
                    
                    
                }
                .v-input--selection-controls__input{
                    width: 50px;
                    height: 50px;
                    margin-right: 0;

                    .v-input--selection-controls__ripple{
                        left: 0;
                    }

                    .v-icon{
                        font-size: 50px;
                    }
                }
            }
        }
        
    }

</style>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'eod-measurement-scale',
    props: {
        readonly: Boolean,
        value: Number,
        measurementType: Object,
    },
    data: () => {
        return {
            id: uuidv4()
        }
    }
}
</script>