<template>
    <div class="eod-treeview-branch">
        <div v-for="item in items" :key="item.id">
            <div class="d-flex px-2 py-1 align-items-center" :class="{'eod-treeview-branch__item':selectable.includes(item.class), 'opacity-4':disabled, 'eod-treeview-branch__item-selected':selectedItemIds.includes(item.id)}">
                <v-btn v-if="item.childrenCount || item.children" @click="toggleChildren(item)" small icon><v-icon small>{{ openChildren.includes(item.id)?'mdi-chevron-down':'mdi-chevron-right' }}</v-icon></v-btn>
                <div v-else style="width:28px;height:24px;"></div>
                <v-checkbox :indeterminate="item.childIds && item.childIds.some(r => selectedItemIds.includes(r))" :disabled="disabled" v-if="multiple && selectable.includes(item.class)" @change="selectItem(item)" :input-value="isSelected(item)" dense class="mx-0 my-0"></v-checkbox>
                <v-icon small>{{ TreeObject.getIcon(item.class) }}</v-icon>
                <div v-if="!disabled" class="px-2 eod-treeview-branch__item__name" @contextmenu.prevent="item.module != 'companies'?showContextMenu($event, item):null" @click="selectable.includes(item.class)?selectItem(item):null">{{item.name}}</div>
                <div v-else class="px-2 eod-treeview-branch__item__name">{{item.name}}</div>
                <v-badge color="grey" inline small v-if="item.childrenCount" :content="item.childrenCount"></v-badge>

                <v-spacer></v-spacer>
                <slot name="item-extra" :item="item" v-if="!hideExtra">
                    <div class="px-4">{{ item.typeName }}</div>
                </slot>
                <template v-if="!readonly">
                    <eod-button-copy :value="item"></eod-button-copy>
                    <div v-if="!item.isReadonly">
                        <v-btn color="error" icon small @click="$emit('delete', item)"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                    </div>
                </template>
            </div>
            <div v-if="openChildren.includes(item.id)" class="pl-4 eod-treeview-branch__children">
                <eod-objects-treeview :hide-extra="hideExtra" :disabled="(multiple && isSelected(item)) || disabled" :depth="depth+1" :open="openChildren" :multiple="multiple" :children="item.children" @delete="val => $emit('delete', val)" @context-add-folder="val => $emit('context-add-folder', val)" @context-add="val => $emit('context-add', val)" @context-move="val => $emit('context-move', val)" :classes="classes" :readonly="readonly" :exclude="exclude" :selectable="selectable" :ref="'childrenTree_'+item.id" :selected="selectedItems" @selected="selectItem" :parentId="item.id">
                    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
                </eod-objects-treeview>
            </div>
        </div>
        <v-menu v-if="isContextMenuVisible && !readonly && !contextMenuItem.isReadonly" v-model="isContextMenuVisible" fixed :position-x="contextMenuEvent.pageX" :position-y="contextMenuEvent.pageY">
        <v-card>
            <v-list dense>
                <v-list-item @click="$emit('context-add', contextMenuItem)">
                    <v-list-item-content>
                        <v-list-item-title><v-icon small left>mdi-package-variant-plus</v-icon>Item toevoegen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="$emit('context-move', contextMenuItem)">
                    <v-list-item-content>
                        <v-list-item-title><v-icon small left>mdi-file-move-outline</v-icon>Item naar hier verplaatsen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="$emit('context-add-folder', contextMenuItem)">
                    <v-list-item-content>
                        <v-list-item-title><v-icon small left>mdi-folder-plus-outline</v-icon>Map toevoegen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="$emit('delete', contextMenuItem)">
                    <v-list-item-content>
                        <v-list-item-title><v-icon left color="error" small>mdi-trash-can-outline</v-icon>Verwijderen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
        </v-menu>
    </div>
</template>
<style lang="scss" scoped>
.eod-treeview-branch{
    .eod-treeview-branch__item{
        &:hover{
            background-color: #F8F8F8;
        }

        &.eod-treeview-branch__item-selected{
            background-color: #eeeeee;
            font-weight: bold;
        }

        .eod-treeview-branch__item__name{
            &:hover{
                cursor: pointer;
            }
        }
    }
}
</style>
<script>
import eodObjectsTreeview from './eod-objects-treeview.vue';
import eodButtonCopy from './eod-button-copy.vue';
import TreeObject from './../models/treeObject';

export default{
    name: 'eod-objects-treeview',
    components: {
        eodObjectsTreeview,
        eodButtonCopy
    },
    props:{
        parentId: String,
        search: Object,
        multiple: Boolean,
        readonly: Boolean,
        selected: Array,
        exclude: Array,
        open: Array,
        disabled: Boolean,
        hideExtra: Boolean,
        depth:{
            type: Number,
            default: 0
        },
        children: Object,
        classes: {
            type: Array,
            default: () => (['COMPANY','PROJECT','DEVICE', 'DEVICE_FOLDER'])
        },
        selectable: {
            type: Array,
            default: () => (['DEVICE', 'DEVICE_FOLDER'])
        }
    },
    data: () => ({
        items: [],
        openChildren: [],
        selectedItems: [],
        selectedItemIds: [],
        isContextMenuVisible: false,
        contextMenuEvent: null,
        contextMenuItem: null,
        searching: false,
        TreeObject: TreeObject,
        cancelRequest: null
    }),
    created(){
        if(this.selected){
            this.selectedItems = this.selected;
            this.setSelectedIds();
        }
        if(this.open){
            this.openChildren = this.open;
        }
        if(this.children){
            this.items = this.children;
        }else{
            this.fetchItems();
        }
    },
    watch:{
        selected(newVal){
            this.selectedItems = newVal;
            this.setSelectedIds();
        },
        search(newVal){
            this.openChildren = [];
            if(this.open){
                this.openChildren = this.open;
            }

            this.fetchItems()
        }
    },
    methods: {
        isSelected(item){
            if(item.parentIds && this.selectedItemIds.some(r=> item.parentIds.includes(r))){
                return true;
            }
            
            return this.selectedItemIds.includes(item.id);
        },
        showContextMenu(event, item){
            this.isContextMenuVisible = true;
            this.contextMenuEvent = event;
            this.contextMenuItem = item;
        },
        customMerge(objValue, srcValue) {
            if (_.isArray(objValue)) {
                return objValue.concat(srcValue);
            }
        },
        searchItems(options){

            if(this.cancelRequest){
                this.cancelRequest.cancel();
                this.cancelRequest = null;
            }

            this.searching = true;

            options = _.mergeWith(options, this.search, this.customMerge);

            this.items = [];

            this.$eod.get('searchTreeObjects', ['id', 'name', 'class', 'childId', 'childIds', 'parentIds', 'parentId', 'depth', 'typeId', 'typeName','module', 'productsCount', 'childrenCount', 'isReadonly'], options).then(response => {
                if(response && response.data.data && response.data.data.searchTreeObjects){
                    
                    

                    const tree = {};
                    let currentChildId = null;
                    let currentItem = null;
                    for (let i = 0; i < response.data.data.searchTreeObjects.edges.length; i++) {
                        const item = response.data.data.searchTreeObjects.edges[i];
                        if(currentChildId != item.childId){
                            if(!tree[item.id]){
                                tree[item.id] = item;
                            }

                            currentItem = tree[item.id];
                        }else{
                            if(!this.openChildren.includes(currentItem.id)){
                                this.openChildren.push(currentItem.id);
                            }

                            if(!currentItem.children){
                                currentItem.children = {};
                            }

                            if(!currentItem.children[item.id]){
                                currentItem.children[item.id] = item;
                            }

                            currentItem = currentItem.children[item.id];
                        }

                        currentChildId = item.childId;
                    }
                    this.items = tree;
                }
            });
        },
        fetchItems(){
            if(this.cancelRequest){
                this.cancelRequest.cancel();
                this.cancelRequest = null;
            }

            const options = {
                parentId: this.parentId,
                orderBy: {
                        column: "name",
                        type: "asc"
                    }
            }

            if(this.exclude && this.exclude[0]){
                options.whereNotIn = [
                    {
                        column: 'tree_objects_view.id',
                        array: this.exclude
                    }
                ];
            }

            options.whereIn = [
                {
                    column: 'tree_objects_view.class',
                    array: this.classes
                }
            ];

            if(this.search && Object.keys(this.search)[0]){
                return this.searchItems(options);
            }

            if(this.searching){
                this.searching = false;
                this.openChildren = [];
            }

            this.cancelRequest = this.$eod.getCancelRequest();

            let extra = {
                cancelToken: this.cancelRequest.token
            };

            this.$eod.get('treeObjects', ['id', 'name', 'class', 'childIds', 'typeId', 'childId','parentId', 'parentIds', 'typeName','module', 'childrenCount', 'productsCount', 'isReadonly'], options, extra).then(response => {
                if(response && response.data.data && response.data.data.treeObjects){
                    this.items = response.data.data.treeObjects.edges;
                }
            });

            for (let i = 0; i < this.openChildren.length; i++) {
                const childId = this.openChildren[i];
                if(this.$refs['childrenTree_'+childId] && this.$refs['childrenTree_'+childId][0]){
                    this.$refs['childrenTree_'+childId][0].fetchItems();
                }
            }
        },
        toggleChildren(item){
            if(this.openChildren.includes(item.id)){
                this.openChildren.splice(this.openChildren.indexOf(item.id), 1);
            }else{
                this.openChildren.push(item.id);
            }
        },
        setSelectedIds(){
            this.selectedItemIds = this.selectedItems.map(item => item.id);
        },
        selectItem(item){
            if(this.depth == 0){
                if(!this.multiple){
                    this.selectedItems = [];
                }

                if(this.selectedItemIds.includes(item.id)){
                    this.selectedItems = this.selectedItems.filter((selectedItem) => !(selectedItem.id == item.id || selectedItem.parentIds.includes(item.id)));
                }else{
                    this.selectedItems = this.selectedItems.filter((selectedItem) => !(selectedItem.parentIds.includes(item.id)));
                    this.selectedItems.push(item);
                }
            }

            this.setSelectedIds();

            this.$emit('selectionChanged', this.selectedItems);
            this.$emit('selected', item);

            this.$nextTick(() => {
                this.$forceUpdate();
            });
        }
    }
}
</script>