<template>
    <div class="eod-treeview-multiple">
      <template v-if="!loading">
          <v-treeview
          v-if="items.length > 0"
    dense
    :items="items"
    :selectable="selectable"
    selected-color="primary"
    :value="value?value:[]"
    @input="selectionChanged"
    :selection-type="selectionType"
    :return-object="returnObject"
    :open.sync="open"
  >
  <template v-slot:prepend="{item}">
    <slot name="prepend" :item="item">
      <v-chip small class="justify-content-center" style="width:40px;" label v-if="item.children && item.children.length > 0">{{ item.children.length }}</v-chip>
    </slot>
  </template>
  <template v-slot:label="{item}">
    <slot name="label" :item="item">
        <v-btn text @click="itemClicked(item)" style="text-transform: none;"><strong>{{ item.name }}</strong></v-btn>
    </slot>
    </template>
  <template v-slot:append="{item}">
    <slot name="append" :item="item">
    </slot>
    <template v-if="!selectable && !readonly" >
      <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on">mdi-alarm-plus</v-icon>
            </template>
            <div>Aangemaakt op</div>
            <span>{{ item.createdAt?$moment(item.createdAt).format(format): '/'}}</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on">mdi-alarm</v-icon>
            </template>
            <div>Gewijzigd op</div>
            <span>{{ item.updatedAt?$moment(item.updatedAt).format(format): '/'}}</span>
        </v-tooltip>
        <div class="ml-4">
        <eod-button-copy :value="item"></eod-button-copy>
        <v-btn small icon :to="{name:'product', params:{id:item.id, classname:(item.class?item.class.toLowerCase():null)}}"><v-icon>mdi-pencil-outline</v-icon></v-btn>
        <v-btn small icon color="error" class="ml-2" @click="deleteItem(item)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
      </div>
    </template>
  </template>
  </v-treeview>
  <div v-else>Geen assets gevonden.</div>
</template>
  <div v-else class="d-flex justify-content-center align-items-center py-4">
  <v-progress-circular indeterminate color="secondary"></v-progress-circular>
</div>
  <eod-delete-dialog v-if="deleteItemObject && !selectable" v-model="deleteDialog" :item="deleteItemObject" @delete="deleteItemAction"></eod-delete-dialog>
</div>
</template>
<style lang="scss">
.v-treeview {
  .v-treeview-node .v-treeview-node__append{
    display: flex;
    align-items: center;
  }
}
</style>
<script>
import eodDeleteDialog from './eod-delete-dialog.vue';
import eodButtonCopy from './eod-button-copy.vue';

export default {
    name: 'eod-treeview-multiple',
    components:{
        eodDeleteDialog,
        eodButtonCopy
    },
    props:{
        selectable: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        value:{
            type:Array,
            default: () => ([])
        },
        endpoint:{
            type:String,
        },
        selectionType:{
            type:String,
            default:'independent'
        },
        fields:{
            type: Array,
            default: () => (['id','name', 'children{id}'])
        },
        variables:{
            type: Object,
            default: () => ({})
        },
        ignore:{
          type: Array,
          default: () => ([])
        },
        childVariables:{
            type: Object,
            default: () => ({})
        }
    },
    data: () => {
        return {
          deleteItemObject:null,
          deleteDialog:false,
          loading:false,
          items: [],
          open: [],
          cancelRequest: null,
          format:'YYYY-MM-DD HH:mm'
        }
    },
    mounted(){
        this.reload();
    },
    methods:{
      selectionChanged(selection){
        this.$emit('input',selection);
      },
        itemClicked(item){
            if(this.selectable){
                let val = this.value;
                if(!val){
                    val = [];
                }

                if(!val.includes(item)){
                    val.push(item);
                }

                this.$emit('input', val);
            }else{
                this.$emit('click', item);
            }
            
        },
        deleteItem(item){
      this.deleteItemObject = item;
      this.deleteDialog = true;
    },
    deleteItemAction(){
      this.$emit('delete', this.deleteItemObject);
    },
    reload(){
      if (this.cancelRequest) {
          this.cancelRequest.cancel();
      }
      
      this.loading = true;
      this.items = [];

      const fields = this.fields;
      fields.push('parents{id}');

      const variables = this.variables;
      variables.limit = 5000;

      this.cancelRequest = this.$eod.getCancelRequest();

      const extra = {
          cancelToken: this.cancelRequest.token
      };

      this.$eod.get(this.endpoint, this.fields, variables, extra)
            .then(response => {
              if(response.data.data && response.data.data[this.endpoint]){
                const items = response.data.data[this.endpoint].edges;
                this.items = this.getChildren(items);
                this.open = this.value;
                this.loading = false;
                this.$emit('loaded');
              }
            });
    },
    getChildren(items, parent_id, parent_ids){ // parent_ids is to prevent loops
        const children = [];

        if(!parent_ids){
          parent_ids = [];
        }

        if(parent_id){
          parent_ids.push(parent_id);
        }
        

        for (let i = 0; i < items.length; i++) {
          const item = items[i];
            if(!(item.parents && item.parents.length > 0) && !parent_id && !this.ignore.includes(item.id)){
              item.children = this.getChildren(items, item.id, parent_ids);
              children.push(item);
            }else if(item.parents && item.parents.length > 0){
              for (let j = 0; j < item.parents.length; j++) {
                  const parent = item.parents[j];
                  if(parent.id == parent_id && !parent_ids.includes(item.id) && !this.ignore.includes(item.id)){
                    item.children = this.getChildren(items, item.id, parent_ids);
                    children.push(item);
                    break;
                  }
              }
            }
        }

        return children;
    },
    }
}
</script>