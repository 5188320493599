<template>
<v-card>
        <v-card-title>
          <v-avatar color="primary" size="40" class="elevation-3 mr-4">
            <v-icon dark v-if="value.id">
              mdi-pencil-outline
            </v-icon>
            <v-icon dark v-else>
              mdi-plus
            </v-icon>
          </v-avatar>
          <span class="headline" v-text="value.id?$t('Asset aanpassen'):$t('Asset toevoegen')"></span>
          <v-spacer></v-spacer>
          <v-btn small icon @click="onCancel"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
            <v-form class="mt-4" ref="form" lazy-validation v-if="value">
              <v-text-field label="Naam" v-model="value.name" outlined></v-text-field>
              <v-text-field label="Type" v-model="value.type" outlined></v-text-field>
              <eod-list-item-autocomplete v-if="$eod.getOrganizationSetting('products.unit.listId')" class="mb-4" v-model="value.unit" return-object label="Eenheid" :listId="$eod.getOrganizationSetting('products.unit.listId').value" outlined></eod-list-item-autocomplete>
              <v-text-field label="Leverancier" v-model="value.vendor" outlined></v-text-field>
              <v-text-field label="URL" v-model="value.url" outlined></v-text-field>
              <v-textarea label="Omschrijving" v-model="value.description" outlined></v-textarea>
            </v-form>
        </v-card-text>
        <v-card-actions class="grey lighten-3 py-3 px-3">
          <v-spacer></v-spacer>
          <v-btn
            @click="onCancel"
            rounded
            class="mr-2"
            text
          >
            Annuleren
          </v-btn>
          <v-btn
            color="secondary"
            @click="onCreate"
            :loading="saving"
            rounded
            depressed
          >
          <template v-if="value.id">
            <v-icon>mdi-content-save</v-icon>
            Opslaan
          </template>
          <template v-else>
              <v-icon>mdi-plus</v-icon>
            Toevoegen
          </template>
          </v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';

export default {
  name: 'eod-add-product',
  components:{
    eodListItemAutocomplete
  },
  props: {
      value: Object
  },
  data: () => {
      return {
          saving: false
      }
  },
  methods: {
      onCreate(){
          var valid = this.$refs.form.validate();
            if(valid){
                this.$emit('onSaved', this.value);
            }
      },
      onCancel(){
          this.$emit('onCancel');
      }
  }
}
</script>