const { default: Project } = require('./../models/project');
const { default: Company } = require('./../models/company');
const { default: Task } = require('./../models/task');
const { default: Workflow } = require('./../models/workflow');
const { default: Service } = require('../models/service');
const { default: TaskType } = require('../models/taskType');
const { default: MeasurementType } = require('../models/measurementType');
const { default: Product } = require('../models/product');
const { default: ProductType } = require('../models/productType');
const { default: User } = require('../models/user');
const { default: Contact } = require('../models/contact');
const { default: DataConnector } = require('../models/dataConnector');

module.exports = {
    roles: {
        exception: ['uma_authorization', 'offline_access'],
        eod: ['root', 'lead'],
        contacts: ['contributor', 'responsible'],
        collaborators: ['member', 'admin'],
        connectors: ['connector'],
        responsibles: ['responsible'],
        anonymous: ['informant']
    },
    getRoleGroupName(roleName) {
        for (const groupName in this.roles) {
            const roles = this.roles[groupName];
            if (roles.includes(roleName)) {
                return groupName;
            }
        }
        return null;
    },
    keycloakRoles: [
        {
            value: 'admin',
            text: 'Beheerder'
        },
        {
            value: 'lead',
            text: 'Manager'
        },
        {
            value: 'member',
            text: 'Medewerker'
        },
        {
            value: 'responsible',
            text: 'Verantwoordelijke'
        },
        {
            value: 'contributor',
            text: 'Gast'
        },
        {
            value: 'informant',
            text: 'Informant'
        },
    ],
    eventTableHeaders: [
        { text: 'Einde', value: 'duration', groupable: false, sortable: true },
        { text: 'Meetwaarde', value: 'measurementType.name', groupable: false, sortable: false },
        { text: 'Waarde', value: 'value', groupable: false },
        { text: 'Bericht', value: 'message', groupable: false, sortable: false },
        { text: 'Type', value: 'type', groupable: false },
        { text: 'Niveau', value: 'severity' },
        { text: 'Status', value: 'status' },
        { text: 'Logs', value: 'logs', groupable:false, sortable:false },
        { text: 'Databron', value: 'owner.name', sortable: false },
        { text: 'Onderdeel', value: 'facility', sortable: false },
        { text: 'Gebruiker', value: 'responsibleUser.name', sortable: false },
        { text: 'Commentaar', value: 'comments', groupable: false, sortable: false },
        //{ text: 'Afbeeldingen', value: 'images', groupable: false, sortable: false },
        { text: 'Toestel', value: 'product.name', sortable: false },
        { text: 'Project', value: 'project.name', sortable: false },
        { text: 'Bedrijf', value: 'company.name', sortable: false },
        
        //{ text: 'Categorie', value: 'category' },
        //{ text: 'Gebruikte materialen', value: 'usedProducts', groupable: false, sortable: false },
    ],
    permissions: {
        'edit-dashboard': ['eod', 'collaborators'],
        'edit-root-settings': ['eod'],
        'export-backup': ['eod'],
        'import-items': ['eod', 'collaborators'],
        'export-items': ['eod', 'collaborators'],
    },
    listItemTypes: [
        { id: 'RANGE', type: 'range', fields: ['low', 'high', 'stepSize'] },
        { id: 'RANGE_TO_TEXT', type: 'range', fields: ['name', 'low', 'high', 'stepSize'] },
        { id: 'VALUE_TO_TEXT', type: 'value', fields: ['name', 'threshold', 'color'] },
        { id: 'USER', type: 'user', fields: ['user'] },
        { id: 'COMPANY', type: 'object', endpoint: 'companies', fields: ['object'] },
        { id: 'PROJECT', type: 'object', endpoint: 'projects', fields: ['object'] },
        { id: 'SERVICE', type: 'object', endpoint: 'services', fields: ['object'] }
    ],
    getListItemTypeById(id) {
        for (let i = 0; i < this.listItemTypes.length; i++) {
            const listItemType = this.listItemTypes[i];
            if (listItemType.id == id) {
                return listItemType;
            }
        }

        return null;
    },
    fieldTypes: [
        { value: 'number', text: 'Getal', info: 'Een numerieke waarde' },
        { value: 'yesno', text: 'Ja/Nee', info: 'Keuze tussen Ja (1) en Nee (0)' },
        { value: 'scale', text: 'Keuzelijst', info:'Een bestaande of nieuwe lijst waar je eenvoudig opties kan toevoegen of verwijderen en waarvan 1 item kan gekozen worden' }
    ],
    objectTypes: {
        project: 'projects',
        task: 'tasks',
        service: 'services',
        response: 'responses',
        company: 'companies',
        tasktype: 'taskTypes',
        product: 'products',
        producttype: 'productTypes',
        storageobject: 'storageObjects'
    },
    modules: {
        companies: {
            key: 'companyId',
            item: 'company',
            endpoint: 'companies',
            icon: 'mdi-office-building-outline',
            children: ['projects', 'tasks'],
            model: Company
        },
        projects: {
            key: 'projectId',
            item: 'project',
            endpoint: 'projects',
            icon: 'mdi-folder-open-outline',
            parents: {
                companies: 'company'
            },
            children: ['products'],
            model: Project,
        },
        tasks: {
            key: 'taskId',
            item: 'task',
            endpoint: 'tasks',
            icon: 'mdi-clipboard-outline',
            parents: {
                projects: 'project',
                taskTypes: 'taskType'
            },
            model: Task
        },
        workflows: {
            key: 'taskId',
            item: 'task',
            endpoint: 'tasks',
            icon: 'mdi-clipboard-outline',
            parents: {
                projects: 'project',
                taskTypes: 'taskType'
            },
            model: Workflow
        },
        taskTypes: {
            key: 'taskTypeId',
            item: 'taskType',
            endpoint: 'taskTypes',
            icon: 'mdi-clipboard-outline',
            model: TaskType
        },
        services: {
            key: 'serviceId',
            item: 'service',
            endpoint: 'services',
            icon: 'mdi-file-sign',
            model: Service
        },
        measurementTypes: {
            key: 'measurementTypeId',
            item: 'measurementType',
            endpoint: 'measurementTypes',
            icon: 'mdi-waveform',
            model: MeasurementType
        },
        users: {
            key: 'userId',
            item: 'user',
            endpoint: 'searchUsers',
            icon: 'mdi-account-group-outline',
            model: User
        },
        contacts: {
            key: 'userId',
            item: 'user',
            endpoint: 'searchUsers',
            icon: 'mdi-account-group-outline',
            model: Contact
        },
        dataConnectors: {
            key: 'userId',
            item: 'user',
            endpoint: 'searchUsers',
            icon: 'mdi-account-group-outline',
            model: DataConnector
        },
        products: {
            key: 'productId',
            item: 'product',
            icon: 'mdi-package-variant',
            endpoint: 'products',
            parents: {
                productTypes: 'productType'
            },
            model: Product
        },
        productTypes: {
            key: 'productTypeId',
            item: 'productType',
            endpoint: 'productTypes',
            icon: 'mdi-package-variant',
            model: ProductType
        }
    },
    relativeTimeRanges: [
        {
            type: 'years',
            value: -1,
            colsize: 10,
            text: 'Voorbije jaar',
            singleDate: false,
            closest: 'day'
        },
        {
            type: 'months',
            value: -1,
            colsize: 10,
            text: 'Voorbije maand',
            singleDate: false,
            closest: 'day'
        },
        {
            type: 'weeks',
            value: -1,
            colsize: 20,
            text: 'Voorbije week',
            singleDate: false,
            closest: 'day'
        },
        {
            type: 'hours',
            value: -24,
            colsize: 40,
            text: 'Voorbije 24u',
            singleDate: true
        },
        {
            type: 'days',
            value: 0,
            colsize: 40,
            text: 'Vandaag',
            singleDate: true,
            closest: 'day'
        },
        {
            type: 'days',
            value: 1,
            colsize: 40,
            text: 'Morgen',
            singleDate: true,
            absolute: true,
        },
        {
            type: 'weeks',
            value: 0,
            colsize: 20,
            text: 'Deze week',
            singleDate: false,
            closest: 'week'
        },
        {
            type: 'months',
            value: 0,
            colsize: 10,
            text: 'Deze maand',
            singleDate: false,
            closest: 'month'
        },
    ],
    routeColors: [
        {
            hex: '#021F59'
        },
        {
            hex: '#1CA63F'
        },
        {
            hex: '#F27141'
        },
        {
            hex: '#4EBFD9'
        },
        {
            hex: '#B357A5'
        },
        {
            hex: '#7D522D'
        },
        {
            hex: '#03588C'
        },
        {
            hex: '#F25260'
        },
        {
            hex: '#B357A5'
        }
    ],
    frequencies: [
        {
            value: 'FREQ=DAILY;INTERVAL=1',
            text: 'Dagelijks'
        },
        {
            value: 'FREQ=WEEKLY;INTERVAL=1',
            text: 'Wekelijks'
        },
        {
            value: 'FREQ=MONTHLY;INTERVAL=1',
            text: 'Maandelijks'
        },
        {
            value: 'FREQ=YEARLY;INTERVAL=1',
            text: 'Jaarlijks'
        },
    ],
    resolveFrequency(value) {
        for (let i = 0; i < this.frequencies.length; i++) {
            const frequency = this.frequencies[i];
            if (value == frequency.value) {
                return frequency;
            }
        }

        return null;
    },
    getListItemTypeById(id) {
        for (let i = 0; i < this.listItemTypes.length; i++) {
            const listItemType = this.listItemTypes[i];
            if (listItemType.id == id) {
                return listItemType;
            }
        }

        return null;
    },
    workflowStatusCodes: [
        {
            icon: 'mdi-flag-variant-outline',
            color: '#2683ab',
            textColor: 'white',
            value: 'start',
            text: 'Start',
            workflow_enabled: false
        },
        {
            icon: 'mdi-clipboard-edit-outline',
            color: '#f55bbc',
            textColor: 'white',
            value: 'form',
            text: 'Formulier',
            workflow_enabled: true
        },
        {
            icon: 'mdi-clipboard-play-outline',
            color: '#3381ff',
            textColor: 'white',
            value: 'automation',
            text: 'Automatisering',
            workflow_enabled: true
        },
        {
            icon: 'mdi-clipboard-clock-outline',
            color: '#031859',
            textColor: 'white',
            value: 'plan',
            text: 'Inplannen',
            workflow_enabled: false
        },
        {
            icon: 'mdi-clipboard-search-outline',
            color: '#FFD500',
            textColor: 'white',
            value: 'verify',
            text: 'Goedkeuren',
            workflow_enabled: false
        },
        {
            icon: 'mdi-clipboard-check-outline',
            color: '#09bc8a',
            textColor: 'white',
            value: 'end',
            text: 'Afgewerkt',
            workflow_enabled: false
        }
    ],
    stepTypes: [
        {
            value: 'START',
            text: 'Start',
        },
        {
            value: 'FORM',
            text: 'Formulier',
        },
        {
            value: 'VERIFY',
            text: 'Goedkeuren',
        },
        {
            value: 'END',
            text: 'Afgewerkt',
        }
    ],
    statusCodes: [
        {
            icon: 'mdi-flag-variant-outline',
            color: '#2683ab',
            textColor: 'white',
            value: 'start',
            text: 'Start',
            task_enabled: false,
            workflow_enabled: false,
        }, {
            icon: 'mdi-check',
            color: '#09bc8a',
            textColor: 'white',
            value: 'done',
            text: 'Klaar',
            calendar_enabled: true,
            task_enabled: true,
            workflow_enabled: true,
        }, {
            icon: 'mdi-calendar-remove',
            color: '#fdfffc',
            textColor: 'dark',
            value: 'backlog',
            text: 'Niet gepland',
            workflow_enabled: false,
        }, {
            icon: 'mdi-calendar',
            color: '#ced5de',
            textColor: 'dark',
            value: 'to_do',
            text: 'Te doen',
            calendar_enabled: true,
            workflow_enabled: false,
            job_enabled: true,
        }, {
            icon: 'mdi-run',
            color: '#3381ff',
            textColor: 'white',
            value: 'in_progress',
            text: 'In uitvoering',
            calendar_enabled: true,
            workflow_enabled: true,
            job_enabled: true,
        }, {
            icon: 'mdi-eye',
            color: '#FFD500',
            textColor: 'dark',
            value: 'to_be_verified',
            text: 'Ter goedkeuring',
            workflow_enabled: false,
        },
        {
            icon: 'mdi-check-decagram-outline',
            color: '#f55bbc',
            textColor: 'white',
            value: 'confirmed',
            text: 'Bevestigd',
            task_enabled: false,
            workflow_enabled: false,
        }, {
            icon: 'mdi-close',
            color: '#ff9f1c',
            textColor: 'white',
            value: 'cancelled',
            text: 'Geannuleerd',
            task_enabled: false,
            workflow_enabled: false,
        }, {
            icon: 'mdi-close-octagon-outline',
            color: '#031859',
            textColor: 'white',
            value: 'no_action',
            text: 'Geen actie nodig',
            workflow_enabled: false,
        }, {
            icon: 'mdi-cancel',
            color: '#f23d3d',
            textColor: 'white',
            value: 'on_hold',
            text: 'Geblokkeerd',
            task_enabled: true,
            workflow_enabled: true,
            job_enabled: true,
        }],
    influxInterval: [{
        value: '1s',
        text: 'Seconde'
    },
    {
        value: '1m',
        text: 'Minuut'
    },
    {
        value: '5m',
        text: '5 minuten'
    },
    {
        value: '1h',
        text: 'Uur'
    },
    {
        value: '8h',
        text: '8 uur'
    },
    {
        value: '1mo',
        text: 'Maand'
    },
    {
        value: '1d',
        text: 'Dag'
    }
    ],
    periodicity: [
        {
            value: null,
            text: 'Geen'
        },
        {
            value: 'RRULE:FREQ=DAILY;INTERVAL=1;',
            text: 'Dagelijks'
        },
        {
            value: 'RRULE:FREQ=DAILY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR;',
            text: 'Werkdagen'
        },
        {
            value: 'RRULE:FREQ=WEEKLY;INTERVAL=1;',
            text: 'Wekelijks'
        },
        {
            value: 'RRULE:FREQ=DAILY;INTERVAL=1;BYDAY=SA,SU;',
            text: 'Weekends'
        },
        {
            value: 'RRULE:FREQ=WEEKLY;INTERVAL=2;',
            text: 'Tweewekelijks'
        },
        {
            value: 'RRULE:FREQ=WEEKLY;INTERVAL=3;',
            text: 'Driewekelijks'
        },
        {
            value: 'RRULE:FREQ=WEEKLY;INTERVAL=4;',
            text: 'Maandelijks'
        },
        {
            value: 'RRULE:FREQ=WEEKLY;INTERVAL=6;',
            text: 'Zeswekelijks'
        },
        {
            value: 'RRULE:FREQ=WEEKLY;INTERVAL=8;',
            text: 'Tweemaandelijks'
        },
        {
            value: 'RRULE:FREQ=WEEKLY;INTERVAL=13;',
            text: 'Driemaandelijks'
        },
        {
            value: 'RRULE:FREQ=WEEKLY;INTERVAL=17;',
            text: 'Viermaandelijks'
        },
        {
            value: 'RRULE:FREQ=WEEKLY;INTERVAL=26;',
            text: 'Halfjaarlijks'
        },
        {
            value: 'RRULE:FREQ=WEEKLY;INTERVAL=52;',
            text: 'Jaarlijks'
        },
    ],
    getStatusCode(code_id) {
        const codes = this.statusCodes;
        for (let i = 0; i < codes.length; i++) {
            if (codes[i].value == code_id) {
                return codes[i];
            }
        }
        return null;
    },
    getWorkflowStatusCode(code_id) {
        const codes = this.workflowStatusCodes;
        for (let i = 0; i < codes.length; i++) {
            if (codes[i].value == code_id) {
                return codes[i];
            }
        }
        return null;
    }
}