<template>
    <div>
        <div>
            <v-data-table :items="items" hide-default-footer :headers="[{value:'integrationName', text:'Integratie'},{value:'integrationId', text:'ID'},{value:'actions', text:'', width:20}]">
                <template v-slot:item.actions="{item}">
                    <v-btn small @click="deleteItem(item)" color="error" icon><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                </template>
            </v-data-table>
    </div>
        <eod-dialog v-model="isCreateDialogVisible" icon="mdi-plus" iconColor="primary" title="Integratie ID toevoegen" @okAction="addItem" okText="Opslaan">
            <template v-slot:activator="{attrs, on}">
                <v-btn class="mt-4" v-on="on" v-bind="attrs" small rounded color="secondary"><v-icon left>mdi-plus</v-icon>Toevoegen</v-btn>
            </template>
            <eod-autocomplete v-model="newIntegrationMapping.integrationName" class="mt-4" label="Integratie" :items="integrations.filter(item => !items.map(mapper => mapper.integrationName).includes(item.value))"></eod-autocomplete>
            <eod-text-field v-model="newIntegrationMapping.integrationId" class="mt-4" label="id"></eod-text-field>
        </eod-dialog>
    </div>
</template>
<script>
export default {
    name: 'eod-integration-ids',
    props:{
        endpoint: String,
        itemId: String
    },
    data: () => ({
        isCreateDialogVisible: false,
        items: [],
        integrations: [
            {
                value:'robaws',
                text:'Robaws'
            }, {
                value:'navision',
                text:'Navision'
            }
        ],
        newIntegrationMapping: {
            integrationName: null,
            integrationId: null,
        }
    }),
    mounted(){
        this.loadItems();
    },
    methods:{
        loadItems(){
            return this.$eod.get('integrationMappings', ['integrationName','integrationId', 'itemId','itemName'], {
                where:[
                    {
                        column: 'itemName',
                        value: this.endpoint
                    },
                    {
                        column: 'itemId',
                        value: this.itemId
                    }
                ]
            }).then(response => {
                if(response.data.data && response.data.data.integrationMappings){
                    this.items = response.data.data.integrationMappings.edges;
                }
            });
        },
        addItem(){
            this.newIntegrationMapping.itemId = this.itemId;
            this.newIntegrationMapping.itemName = this.endpoint;

            this.$eod.create('IntegrationMapping', this.newIntegrationMapping, {
                returnfields: ['integrationName', 'integrationId','itemId','itemName']
            }).then(response => {
                return this.loadItems();
            }).finally(() => {
                this.isCreateDialogVisible = false;
            })
        },
        deleteItem(item){
            this.$eod.delete('IntegrationMapping', item, {
                inputtype: 'IntegrationMappingInput!',
                inputname: 'input',
            }).then(() => {
                return this.loadItems();
            });
        }
    }
}
</script>