<template>
    <div>
    <v-row>
        <v-col>
<eod-text-field
                                :value="value.name"
                                :rules="[val => $validator.required(val, 'Naam')]"
                                @input="val => updateProperty('name', val)"
                                label="Naam*"
                                required
                                outlined
                                hide-details
                                :disabled="value.isReadonly"
                            ></eod-text-field></v-col>
                            <v-col>
                            <eod-text-field
                                :value="value.description"
                                label="Omschrijving"
                                @input="val => updateProperty('description', val)"
                                outlined
                                hide-details
                                :disabled="value.isReadonly"
                            ></eod-text-field>
                        </v-col>
                        </v-row>
                            <eod-custom-fields-item :value="value.customFields" @input="val => updateProperty('customFields', val)" endpoint="articleTypes" :id="value.id""></eod-custom-fields-item>
                    </div>
</template>
<script lang="js">
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';
import eodCustomFieldsItem from './eod-custom-fields-item.vue';

export default {
    name: 'eod-tool-type-detail',
    components: {
        eodListItemAutocomplete,
        eodCustomFieldsItem
    },
    props:{
        value: Object
    },
    methods:{
        updateProperty(propertyName, value){
            const val = this.value;
            val[propertyName] = value;

            this.$emit('input', val);
        }
    }
}
</script>