<template>
    <v-row>
        <v-col v-for="field in fields" :key="field.id" cols="12">
            <eod-text-field v-if="['number', 'text'].includes(field.type)" :value="getFieldValue(field.id)" @input="val => updateFieldValue(field.id, val)" :type="field.type" :label="field.name"></eod-text-field>
            <eod-list-item-autocomplete v-else-if="field.type == 'list'" :label="field.name" :listId="field.settings.listId" :value="getFieldValue(field.id)" @input="val => updateFieldValue(field.id, val)"></eod-list-item-autocomplete>
            <eod-list-label-selector v-else-if="field.type == 'labels'" :label="field.name" :listId="field.settings.listId" :value="getFieldValue(field.id)" @input="val => updateFieldValue(field.id, val)"></eod-list-label-selector>
        </v-col>
    </v-row>
</template>
<script>
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';
import eodListLabelSelector from './eod-list-label-selector.vue';

export default {
    name: 'eod-custom-fields-item',
    components:{
        eodListItemAutocomplete,
        eodListLabelSelector
    },
    props:{
        defaultValues: Array,
        endpoint: String,
        id: String,
        extraFields: Array,
        value: Array
    },
    data: () => ({
        fields: [],
        cancelRequest: null
    }),
    created(){
        this.loadFields();
    },
    methods:{
        getFieldValue(fieldId){
            if(this.value){
                for (let i = 0; i < this.value.length; i++) {
                    const itemField = this.value[i];
                    if(itemField.customFieldId == fieldId){
                        return itemField.value;
                    }
                }
            }

            if(this.defaultValues){
                for (let i = 0; i < this.defaultValues.length; i++) {
                    const itemField = this.defaultValues[i];
                    if(itemField.customFieldId == fieldId){
                        this.updateFieldValue(fieldId, itemField.value);
                        return itemField.value;
                    }
                }
            }

            return null;
        },
        updateFieldValue(fieldId, value){
            let val = this.value;
            if(!val){
                val = [];
            }

            let found = false;
            for (let i = 0; i < val.length; i++) {
                const existingField = val[i];
                if(existingField.customFieldId == fieldId){
                    val[i].value = value;
                    found = true;
                    break;
                }
            }

            if(!found){
                val.push({
                    customFieldId: fieldId,
                    module: this.endpoint,
                    itemId: this.id,
                    value: value,
                });
            }

            this.$emit('input', val);
            
        },
        async loadFields(){

            if (this.cancelRequest) {
                this.cancelRequest.cancel();
            }

            this.cancelRequest = this.$eod.getCancelRequest();

            let extra = {
                cancelToken: this.cancelRequest.token
            };

            this.fields = [];

            const fields = [
                {
                    module: this.endpoint,
                    id: this.id
                },
            ];

            if(this.extraFields){
                for (let i = 0; i < this.extraFields.length; i++) {
                    const extraField = this.extraFields[i];
                    fields.push({
                        ...extraField,
                        isItemField: true
                    });
                }
                
            }

            for (let i = 0; i < fields.length; i++) {
                const endpoint = fields[i];
                const filter = {
                    orderBy: {
                        column: 'place',
                        type: 'asc'
                    },
                    whereIn:[
                        {
                            column: 'module',
                            array: [endpoint.module]
                        }
                    ],
                    where: [
                        {
                            chain: 'or',
                            column: 'jsonb_array_length(jsonb_strip_nulls(items))',
                            value: '0'
                        },
                        {
                            chain: 'or',
                            column: 'items',
                            value: 'isnull'
                        }
                    ]
                };

                if(endpoint.isItemField){
                    filter.whereIn.push({
                        column: 'isChildField',
                        array: ['1']
                    })
                }

                if(endpoint.id){
                    

                    filter.where.push( {
                            chain: 'or',
                            column: 'items',
                            operator: '?',
                            value: endpoint.id
                        });                      
                }

                await this.$eod.get('customFields', ['id','type','name', 'settings'], filter, extra).then(response => {
                    if(response && response.data && response.data.data){
                        this.fields = [...this.fields, ...response.data.data.customFields.edges];
                    }
                    
                });
            }
            
        }
    }
}
</script>