<template>
<div>
    <v-simple-table>
        <thead>
            <tr>
                <th width="40"></th>
                <th>Label</th>
                <th width="200">Type</th>
                <th width="250">Overschrijven per onderliggend item?
                    <v-tooltip top>
                        <template v-slot:activator="{attrs, on}">
                            <v-icon right v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                        </template>
                        Dit veld kan per onderliggend item worden overschreven. De waarde bij het item zelf dient als standaardwaarde.
                    </v-tooltip>
                </th>
                <th width="300">Gekoppeld
                    <v-tooltip top>
                        <template v-slot:activator="{attrs, on}">
                            <v-icon right v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                        </template>
                        Dit veld enkel weergeven bij specifieke items
                    </v-tooltip>
                </th>
                <th width="60">
                </th>
            </tr>
        </thead>
            <draggable tag="tbody" v-model="fields" group="fields" handle=".handle" animation="200">
            <tr v-for="(field, fieldIndex) in fields" :key="field.id">
                <td>
                    <v-icon class="handle">mdi-drag</v-icon>
                </td>
                <td>
                    {{ field.name }}
                </td>
                <td>
                    {{ getFieldType(field.type).text }}
                </td>
                <td>
                    {{field.isChildField?'Ja':'Nee'}}
                </td>
                <td>
                    <v-menu open-on-hover offset-y>
                        <template v-slot:activator="{on, attrs}">
                            <div class="single-line" style="max-width:300px;" v-on="on" v-bind="attrs">
                                <v-chip outlined class="mr-1" small v-for="itemId in field.items" :key="itemId">
                                    {{ $helper.getArrayItemByProperty(items, 'id', itemId).name }}
                                </v-chip>
                            </div>
                        </template>
                        <eod-card>
                            <v-list dense>
                                <v-list-item v-for="itemId in field.items" :key="'list_'+itemId">
                                    <v-list-item-title>
                                        {{ $helper.getArrayItemByProperty(items, 'id', itemId).name }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </eod-card>
                    </v-menu>
                    
                </td>
                <td>
                    <v-tooltip top>
                        <template v-slot:activator="{attrs, on}">
                            <v-btn @click="editField(fieldIndex)" icon v-bind="attrs" v-on="on" small><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                        </template>
                        Aanpassen
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{attrs, on}">
                            <v-btn @click="deleteField(fieldIndex)" icon color="error" v-bind="attrs" v-on="on" small><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                        </template>
                        Verwijderen
                    </v-tooltip>
                </td>
            </tr>
        </draggable>
    </v-simple-table>
    <eod-dialog v-model="isEditDialogVisible" title="Veld" icon="mdi-pencil" iconColor="primary" v-if="activeField" @okAction="saveField">
        <v-alert class="mt-4" v-if="dialogErrors.length > 0" dense type="error">
            <ul>
                <li v-for="error in dialogErrors" :key="error">{{ error }}</li>
            </ul>
        </v-alert>
        <eod-text-field class="mt-4" label="Label" :rules="[val => $validator.required(val, 'Label')]" required v-model="activeField.name"></eod-text-field>
        <eod-autocomplete class="mt-4" :items="fieldTypes" label="Type" :rules="[val => $validator.required(val, 'Type')]" required v-model="activeField.type"></eod-autocomplete>
        <eod-object-autocomplete class="mt-4" label="Lijst*" :rules="[val => $validator.required(val, 'Lijst')]" required  v-if="['list','labels'].includes(activeField.type)" endpoint="lists" :value="activeField.settings?activeField.settings.listId:null" @input="val => updateSettingsValue('listId', val)"></eod-object-autocomplete>
        <v-switch v-model="activeField.isChildField" label="Overschrijven per onderliggend item"></v-switch>
        <eod-autocomplete class="mt-4" clearable chips :items="items" item-value="id" item-text="name" label="Gekoppeld aan" v-model="activeField.items" multiple></eod-autocomplete>
    </eod-dialog>
    <eod-delete-dialog v-if="activeFieldIndex != null" v-model="isDeleteDialogVisible" :item="fields[activeFieldIndex]" @delete="deleteFieldAction"></eod-delete-dialog>
    <div class="d-flex py-2">
        <v-btn @click="addField" class="ml-auto" depressed rounded small color="primary"><v-icon left>mdi-plus</v-icon>Toevoegen</v-btn>
    </div>
</div>
</template>
<script>
import eodObjectAutocomplete from './eod-object-autocomplete.vue'
import eodDeleteDialog from './eod-delete-dialog.vue';
import draggable from 'vuedraggable';

export default {
    name: 'eod-custom-fields',
    components:{
        eodObjectAutocomplete,
        eodDeleteDialog,
        draggable
    },
    props:{
        endpoint: {
            type: String,
            required: true,
        }
    },
    data: () => ({
        items:[],
        fields:[],
        isDeleteDialogVisible:false,
        isEditDialogVisible:false,
        dialogErrors: [],
        activeFieldIndex:null,
        activeField: null,
        deletedFields: [],
        fieldTypes: [
            {
                value: 'text',
                text: 'Tekst'
            },
            {
                value: 'number',
                text: 'Numeriek'
            },
            {
                value: 'list',
                text: 'Keuzelijst'
            },
            {
                value: 'labels',
                text: 'Labels'
            }
        ]
    }),
    created(){
        this.$eod.get(this.endpoint, ['id','name']).then(response => {
            if(response.data.data && response.data.data[this.endpoint]){
                this.items = response.data.data[this.endpoint].edges;
            }
        });

        this.loadFields();
    },
    methods:{
        saveField(){
            this.dialogErrors = [];

            const field = this.activeField;
            if(!field.name){
                this.dialogErrors.push('Label is verplicht!');
            }

            if(!field.type){
                this.dialogErrors.push('Type is verplicht!');
            }

            if(['list','labels'].includes(field.type) && (!field.settings || !field.settings.listId)){
                this.dialogErrors.push('Lijst selecteren is verplicht!');
            }

            if(this.dialogErrors.length > 0){
                return;
            }

            if(this.activeFieldIndex != null){
                this.fields[this.activeFieldIndex] = {...this.activeField};
            } else {
                this.fields.push(this.activeField);
            }
            


            this.isEditDialogVisible = false;
        },
        updateSettingsValue(propertyName, value){
            if(!this.activeField.settings){
                this.activeField.settings = {};
            }

            
            this.activeField.settings[propertyName] = value;
            
        },
        getFieldType(type){
            for (let i = 0; i < this.fieldTypes.length; i++) {
                const fieldType = this.fieldTypes[i];
                if(fieldType.value == type){
                    return fieldType;
                }
            }

            return null;
        },
        async loadFields(){
            return this.$eod.get('customFields', ['id','module', 'place', 'type','isChildField','items','name', 'settings'], {
                orderBy: {
                    column: "place",
                    type: "asc"
                },
                where:[{
                    column: 'module',
                    value: this.endpoint,
                }]
            }).then(response => {
                if(response.data.data && response.data.data.customFields){
                    this.fields = response.data.data.customFields.edges;
                }
            })
        },
        async save(){
            let success = true;

            for (let i = 0; i < this.deletedFields.length; i++) {
                const deletedFieldId = this.deletedFields[i];
                await this.$eod.delete('CustomField', deletedFieldId).catch(() => {success = false;});
            }
            for (let i = 0; i < this.fields.length; i++) {
                const field = this.fields[i];
                field.place = i;
                await this.$eod.save('CustomField', field).catch(() => {success = false;});
            }

            await this.loadFields();

            return success;
        },
        addField(){
            this.activeField = {
                name: null,
                module: this.endpoint,
                type: 'text',
                isChildField: false,
                items: null,
                settings: null,
            };

            this.activeFieldIndex = null;
            this.$nextTick(() => {
                this.isEditDialogVisible = true;
            });
        },
        deleteField(fieldIndex){
            this.activeFieldIndex = fieldIndex;

            this.$nextTick(() => {
                this.isDeleteDialogVisible = true;
            });
        },
        editField(fieldIndex){
            this.dialogErrors = [];
            this.activeFieldIndex = fieldIndex;
            this.activeField = {...this.fields[fieldIndex]};

            this.$nextTick(() => {
                this.isEditDialogVisible = true;
            });
        },
        deleteFieldAction(){
            if(this.fields[this.activeFieldIndex].id){
                this.deletedFields.push(this.fields[this.activeFieldIndex].id);
            }

            this.fields.splice(this.activeFieldIndex, 1);
            this.isDeleteDialogVisible = false;
            this.activeFieldIndex = null;
        }
    }
}
</script>